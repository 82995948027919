import axios from 'axios';
import {
    FEEDBACK_ADD_SUCCESS,
    FEEDBACK_ADD_FAIL,
    ALL_FEEDBACK_GET_SUCCESS,
    ALL_FEEDBACK_GET_ERROR,
    FEEDBACK_SUCCESS_MESSAGE_CLEAR,
    FEEDBACK_ERROR_MESSAGE_CLEAR,
    FEEDBACK_UPDATE_SUCCESS,
    FEEDBACK_UPDATE_FAIL,
    FEEDBACK_DELETE_SUCCESS,
    FEEDBACK_DELETE_FAIL
} from '../types/feedbackType';

import { SERVER_URI } from '../../config/keys';
import { DispatchBlocker } from '../DispatchBlocker/DispatchBlocker';

// Function to set authorization headers
export const userAuth = (token) => {
    axios.interceptors.request.use(
        config => {
            config.headers.authorization = `Bearer ${token}`;
            return config;
        },
        error => Promise.reject(error)
    );
};

// Action to create a new feedback
export const createFeedback = (data) => {
    return async (dispatch, getState) => {
        let proceed = DispatchBlocker(getState);
        if (!proceed) return;

        let token = localStorage.getItem('authToken');
        try {
            userAuth(token);
            const response = await axios.post(`${SERVER_URI}/feedback/create`, data);
            dispatch({
                type: FEEDBACK_ADD_SUCCESS,
                payload: {
                    feedbackSuccessMessage: response.data.message,
                    current: response.data.current,
                    feedbacks: response.data.feedbacks
                }
            });
        } catch (error) {
            console.log(error);
            dispatch({
                type: FEEDBACK_ADD_FAIL,
                payload: {
                    feedbackErrorMessage: 'Error adding feedback'
                }
            });
        }
    };
};

// Action to fetch all feedbacks
export const getFeedbacks = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'adminId': data.userId,
                'teamId': data.teamId
            }
        }

        try {
            const token = localStorage.getItem("authToken");
            userAuth(token);
            const response = await axios.get(`${SERVER_URI}/feedback/get`, config);

            dispatch({
                type: ALL_FEEDBACK_GET_SUCCESS,
                payload: {
                    feedbacks: response.data.feedbacks
                }
            });
        } catch (error) {
            console.log(error);
            dispatch({
                type: ALL_FEEDBACK_GET_ERROR,
                payload: {
                    feedbackErrorMessage: 'Error fetching feedbacks'
                }
            });
        }
    };
};

// Action to update feedback
export const updateFeedback = (data) => {
    return async (dispatch, getState) => {
        let proceed = DispatchBlocker(getState);
        if (!proceed) return;

        let token = localStorage.getItem('authToken');
        try {
            userAuth(token);
            const response = await axios.put(`${SERVER_URI}/feedback/update`, data);

            dispatch({
                type: FEEDBACK_UPDATE_SUCCESS,
                payload: {
                    feedbackSuccessMessage: response.data.message,
                    current: response.data.current,
                    feedbacks: response.data.feedbacks
                }
            });
        } catch (error) {
            console.log(error);
            dispatch({
                type: FEEDBACK_UPDATE_FAIL,
                payload: {
                    feedbackErrorMessage: 'Error updating feedback'
                }
            });
        }
    };
};

// Action to delete feedback
export const deleteFeedback = (data) => {
    return async (dispatch, getState) => {
        let proceed = DispatchBlocker(getState);
        if (!proceed) return;

        let token = localStorage.getItem('authToken');

        try {
            userAuth(token);
            const response = await axios.post(`${SERVER_URI}/feedback/delete`, data);

            dispatch({
                type: FEEDBACK_DELETE_SUCCESS,
                payload: {
                    feedbackSuccessMessage: response.data.message,
                    feedbacks: response.data.feedbacks
                }
            });
        } catch (error) {
            console.log(error);
            dispatch({
                type: FEEDBACK_DELETE_FAIL,
                payload: {
                    feedbackErrorMessage: 'Error deleting feedback'
                }
            });
        }
    };
};
