import React, { useState, useEffect } from "react";
import { historyGet } from "../store/actions/historyAction";
import { useDispatch, useSelector } from "react-redux";

export default function History({ historys, historyMessagee, historyError, historyMessage, allHistory, setAllHistory }) {
    // console.log({ historys, historyMessagee, historyError, historyMessage, allHistory, setAllHistory });

    useEffect(() => {
        if (historys && historys.length > 0) {
            let historyMessages = [];

            historys.reverse().forEach((item) => {
                const hcTime = new Date(item.createdAt).toLocaleString("en-US", {
                    timeZone: "UTC", 
                    month: "short",
                    day: "2-digit",
                    year: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true
                });

                let str = "";
                if (item.cardStatus === "created") {
                    str = `<i>${hcTime}</i> : Created`;
                } else if (item.cardStatus === "added") {
                    str = `<i>${hcTime}</i> : <span class="fieldname">${item.field}</span> added (<b>${item.next}</b>)`;
                } else if (item.cardStatus === "changed") {
                    str = `<i>${hcTime}</i> : <span class="fieldname">${item.field}</span> changed from <b>${item.prev}</b> to <b>${item.next}</b>`;
                } else if (item.cardStatus === "removed") {
                    str = `<i>${hcTime}</i> : <span class="fieldname">${item.field}</span> removed (previously: <b>${item.next}</b>)`;
                }
                historyMessages.push(str);
            });

            setAllHistory(historyMessages);
        }
    }, [historys]);

    return (
        <>
            {allHistory && allHistory.length > 0 && allHistory.map((entry, index) => (
                <p key={index} dangerouslySetInnerHTML={{ __html: entry }} />
            ))}
        </>
    );
}

/* { "field": "Tasks", "cardStatus":"changed", $expr: { $gt: [ { $strLenCP: "$prev" }, 200 ] }, $expr: { $gt: [ { $strLenCP: "$next" }, 200 ] } } */