import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  userUpdate,
  getLoginUserData,
  getuserMetaData,
} from "../../../store/actions/authAction";
import { SERVER_URI, CLIENT_URL } from "../../../config/keys";
import {
  SUCCESS_MESSAGE_CLEAR,
  ERROR_CLEAR,
} from "../../../store/types/authType";
import {
  SUB_DELETE_SUCCESS_CLEAR,
  SUB_DELETE_FAIL_CLEAR,
} from "../../../store/types/pricingType";
import { useNavigate } from "react-router-dom";
import {
  getInvoic,
  deleteSub,
  getSubs,
  generateStripeUpgradeUrl,
} from "../../../store/actions/pricingAction";
import Swal from "sweetalert2";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // optional

export default function ProfileModel({ closeProfile }) {
  const { loading, successMessage, error, authenticate, myInfo, curUserData , userMeta } =
    useSelector((state) => state.auth);
  const {
    pricings,
    Prices,
    cuPricingId,
    invoicData,
    subDatas,
    invoicMessage,
    invoicError,
    pricingMessagee,
    pricingMessage,
    pricingError,
    subSuccessMessage,
    stripeUrl,
  } = useSelector((state) => state.pricings);

  let adminId = myInfo.userId;
  let teamId = localStorage.getItem("teamId");
  // let url = `${SERVER_URI}/user/circle/create`
  let urls = `${SERVER_URI}/admin/update`;
  const dispatch = useDispatch();

  const [state, setState] = React.useState({
    fname: curUserData.fname,
    lname: curUserData.lname,
    companyName: curUserData.companyName,
    userUrl: curUserData.userUrl,
    plan: curUserData.plan,
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
    adminId: adminId,
    url: urls,
    teamId: teamId,
  });

  const handleChanges = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(userUpdate({ ...state, logout: false })).then(() => {
      dispatch(getLoginUserData({ userId: myInfo.userId }));
    });
    // console.log(state)
  };

  useEffect(() => {
    if (
      curUserData &&
      curUserData.session_id &&
      curUserData.session_id.length > 0
    ) {
      dispatch(getInvoic({ adminId: adminId }));
    }
  }, [curUserData]);

  useEffect(() => {
    if (
      curUserData &&
      curUserData.session_id &&
      curUserData.session_id.length > 0
    ) {
      dispatch(getSubs({ adminId: adminId }));
    }
  }, [curUserData]);

  const deleteSubmission = (data) => {
    if (data.status === "active") {
      Swal.fire({
        title: "Are you sure?",
        text: "You can still restore this from trash!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, I'm Sure!",
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(deleteSub(data));
        }
      });
    }
  };

  useEffect(() => {
    if (subSuccessMessage) {
      Swal.fire({
        // icon: "success",
        title: "Success",
        text: `${subSuccessMessage} ${endDate}`,
        showConfirmButton: true,
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        // timer: 3000,
      }).then((result) => {
        // if (result.isConfirmed) {
        dispatch({ type: SUB_DELETE_SUCCESS_CLEAR });
        // }
      });
    }
  }, [subSuccessMessage]);

  useEffect(() => {
    if (pricingError) {
      Swal.fire({
        // icon: 'error',
        title: "Error",
        text: pricingError,
      }).then(() => {
        dispatch({ type: SUB_DELETE_FAIL_CLEAR });
      });
    }
  }, [pricingError]);

  useEffect(() => {
    let obj = {};
    obj.teamId = localStorage.getItem("teamId");
    obj.userId = myInfo.userId;
    // if (authenticate) {
    //     // history.push('/')
    //     console.log('ok')
    // }
    if (successMessage) {
      // alert.success(peopleMessagee)
      Swal.fire({
        // position: 'top-end',
        // icon: "success",
        title: "Success",
        text: successMessage,
        showConfirmButton: true,
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        // timer: 1500,
      }).then((result) => {
        // if (result.isConfirmed) {
        dispatch({ type: SUCCESS_MESSAGE_CLEAR });
        // dispatch(getPeople(obj));
        closeProfile();
        // }
      });
    }
    if (error) {
      Swal.fire({
        // icon: "error",
        title: "Error",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        text: error,
      });
      // alert.error(peopleError)
      dispatch({ type: ERROR_CLEAR });
      // dispatch(getPeople(obj));
      closeProfile();
    }
  }, [successMessage, error]);

  function addLeadingZero(value) {
    return value < 10 ? "0" + value : value;
  }

  let getDates = (timestamp) => {
    // Create a new Date object using the timestamp
    const date = new Date(timestamp * 1000); // Multiply by 1000 as JS works with milliseconds

    // Get the individual components of the date
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // Months are zero-based, so we add 1
    const day = date.getDate();

    // Format the date string
    const formattedDate =
      year + "/" + addLeadingZero(month) + "/" + addLeadingZero(day);

    // Function to add a leading zero if the value is less than 10
    return formattedDate;
  };

  const getSubStatus = (status) => {
    if (status) {
      if (subDatas && subDatas.length > 0) {
        for (let i = 0; i < subDatas.length; i++) {
          if (subDatas[i].id === status) {
            return "active";
          }
        }
      } else {
        return "canceled";
      }
    }
  };

  let endDate = "";
  if (invoicData && invoicData.length > 0) {
    for (let i = 0; i < invoicData.length; i++) {
      let flag = getDates(invoicData[i].lines.data[0].period.end);
      endDate = flag;
    }
  }

  const handleUpgrade = () => {
    // console.log(userMeta)
    const {refBy} = userMeta
    // console.log(refBy)
    // return;
    dispatch(
      generateStripeUpgradeUrl({
        userId: curUserData._id,
        plan: "GO",
        interval: "month",
        email: curUserData.email,
        refBy
      })
    );
  };

  useEffect(() => {
    if (stripeUrl && Object.keys(stripeUrl).length > 0 && stripeUrl.url) {
      window.location.href = `${stripeUrl.url}`;
    }
  }, [stripeUrl]);

  useEffect(() => {
    if(curUserData && Object.keys(curUserData).length >0){
      dispatch(getuserMetaData(curUserData._id))
    }
  },[curUserData])
  // console.log(userMeta)
  return (
    <div className="modal-dialog modal-lg modal-dialog-scrollable">
      <form className="form" onSubmit={(e) => handleSubmit(e)}>
        <div className="modal-content">
          <div className="modal-header">
            <div className="current-plan">
              <div>
                <h4 className="modal-title">
                  UPDATE PROFILE DETAILS
                  {myInfo.role !== "Co-Admin" ? (
                    <span className="label label-primary plan-name">
                      {state.plan.replace("_", " ").toUpperCase()}
                    </span>
                  ) : (
                    <span className="label label-primary plan-name">
                      CO-ADMIN
                    </span>
                  )}
                  <a
                    href="https://teamdecoder.tawk.help/"
                    target="_blank"
                    className="support-link pull-right"
                    rel="noopener nofollow"
                  >
                    <span className="label">Support</span>
                  </a>
                  {/* <a href={`${CLIENT_URL}/pricing`} className="upgrade-link pull-right"><span className="label">Upgrade</span></a> */}
                  {(state.plan.toLowerCase() === "free" || state.plan.toLowerCase() === "free_forever" || state.plan.toLowerCase() === "consulting") &&
                    myInfo.role !== "Co-Admin" && (
                      <a
                        href="#"
                        onClick={() => handleUpgrade()}
                        className="upgrade-link pull-right"
                      >
                        <span className="label">Upgrade</span>
                      </a>
                    )}
                </h4>
              </div>
            </div>
          </div>
          <div style={{ overflowY: "scroll" }} className="modal-body">
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label>
                    Email: <b>{myInfo.email}</b>
                  </label>
                  <input type="hidden" name="user_id" id="user_id" value="2" />
                  <input
                    type="hidden"
                    name="user_email"
                    id="user_email"
                    value="intolap@gmail.com"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label className="" htmlFor="user_first_name">
                    First name (required)
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="fname"
                    id="user_first_name"
                    value={state.fname}
                    onChange={(e) => handleChanges(e)}
                    placeholder="First name (required)"
                    autoComplete="off"
                    required
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="" htmlFor="user_last_name">
                    Last name (required)
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="lname"
                    id="user_last_name"
                    value={state.lname}
                    onChange={(e) => handleChanges(e)}
                    placeholder="Last name (required)"
                    autoComplete="off"
                    required
                  />
                </div>
              </div>
            </div>
            {myInfo.role !== "Co-Admin" && (
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="" htmlFor="company_name">
                      Company name (required)
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="companyName"
                      id="company_name"
                      value={state.companyName}
                      onChange={(e) => handleChanges(e)}
                      autoComplete="off"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="" htmlFor="company_name">
                      Website (required)
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="userUrl"
                      id="userUrl_name"
                      value={state.userUrl}
                      onChange={(e) => handleChanges(e)}
                      autoComplete="off"
                    />
                  </div>
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label className="" htmlFor="current_password">
                    Current password
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    name="currentPassword"
                    id="current_password"
                    onChange={(e) => handleChanges(e)}
                    value={state.currentPassword}
                    autoComplete="off"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="" htmlFor="new_password">
                    New password
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    name="newPassword"
                    id="new_password"
                    value={state.newPassword}
                    onChange={(e) => handleChanges(e)}
                    autoComplete="off"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="" htmlFor="confirm_password">
                    Confirm new password
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    name="confirmPassword"
                    id="confirm_password"
                    onChange={(e) => handleChanges(e)}
                    value={state.confirmPassword}
                    autoComplete="off"
                  />
                </div>
              </div>
              <div className="col-md-12">
                <label>
                  Leave password fields empty if you do not want to change
                  password.
                </label>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <div className="row">
              <div className="col-md-12 text-right">
                <Tippy content={<span>Close Tool</span>}>
                  <button
                    type="button"
                    onClick={closeProfile}
                    className="btn btn-mytpt btn-mytpt-red"
                    data-dismiss="modal"
                  >
                    <i className="fa fa-times"></i>
                  </button>
                </Tippy>
                <Tippy content={<span>Save And Close</span>}>
                  <button type="submit" className="btn btn-mytpt save-close">
                    <i className="fa fa-check"></i>
                  </button>
                </Tippy>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
