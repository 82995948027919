import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import Tippy from "@tippyjs/react";

import { deleteFeedback, getFeedbacks } from '../../store/actions/feedbackAction';
import { updatePeople } from '../../store/actions/peopleAction';
import { updateSkills } from '../../store/actions/skillAction';
import { updateRole } from '../../store/actions/roleAction';
import { updateDomain } from '../../store/actions/domainAction';
import { updateLink } from '../../store/actions/linkAction';
import { updateProject } from '../../store/actions/projectAction';
import { updateCircle } from '../../store/actions/circleAction';
import { GetAliasesName } from "../../HelperFunctions/GetAliasesName";

import "tippy.js/dist/tippy.css"; // optional
import './Campfire.css';

import useCustomEffects from './useCustomEffects';
import { getState } from '../../store/actions/stateAction';

const panelStyles = {
  heading: {
    fontFamily: "Roboto, sans-serif",
    fontSize: "18px",
    fontWeight: 800,
    lineHeight: "21.09px",
    textAlign: "left",
  },
};

export default function Campfire({ indexSet, setPepole, setSkill, setRole, setDomain, setLink, setCircle, setProject, setPepoles, setSkills, setRoles, setDomains, setLinks, setCircles, setProjects, setCampfireComponent, setFocused }) {
  const dispatch = useDispatch();

  const { myInfo } = useSelector((state) => state.auth);
  const { teams } = useSelector((state) => state.teams);
  const { peoples } = useSelector((state) => state.peoples);
  const { skills } = useSelector((state) => state.skills);
  const { roles, dRoles } = useSelector((state) => state.roles);
  const { domains } = useSelector((state) => state.domains);
  const { links } = useSelector((state) => state.links);
  const { circles, dCircles } = useSelector((state) => state.circles);
  const { projects } = useSelector((state) => state.projects);
  const { feedbackData } = useSelector((state) => state.feedbacks);

  // console.log({myInfo, teams, peoples, skills, roles, dRoles, domains, links, feedbackData});

  const [formattedFeedback, setFormattedFeedback] = useState([]);
  const [allCampfireItems, setAllCampfireItems] = useState([]);
  const [NOItems, setNoItems] = useState([]);
  const [EXTItems, setEXTItems] = useState([]);
  const [noPurposeItems, setNoPurposeItems] = useState([]);
  const [noTaskItems, setNoTaskItems] = useState([]);
  const [noObjectiveItems, setNoObjectiveItems] = useState([]);
  const [fadeInOutItems, setFadeInOutItems] = useState([]);
  const [workloadItems, setWorkloadItems] = useState([]);
  const [staffedItems, setStaffedItems] = useState([]);
  const [defaultCircle, setDefaultCircle] = useState([]);

  const totalItems = 13;
  const [isCollapsed, setIsCollapsed] = useState(
    Object.fromEntries(Array.from({ length: totalItems }, (_, i) => [i + 1, false]))
  );

  const handleToggle = (index) => {
    if (index === 0) {
      // Expand all
      setIsCollapsed(Object.fromEntries(Array.from({ length: totalItems }, (_, i) => [i + 1, false])));
    } else if (index === -1) {
      // Collapse all
      setIsCollapsed(Object.fromEntries(Array.from({ length: totalItems }, (_, i) => [i + 1, true])));
    } else {
      // Toggle only the selected index
      setIsCollapsed((prevState) => ({
        ...prevState,
        [index]: !prevState[index],
      }));
    }
  };

  useCustomEffects();

  useEffect(() => {
    document.body.classList.remove('home-page');
    document.body.classList.remove('workflow-page');
    document.body.classList.remove('purpose-tree');
    document.body.classList.remove('survey-page');
    document.body.classList.add('campfire-page');
  });

  const formatOptions = { year: "numeric", month: "short", day: "2-digit" };

  const cardCategories = {
    people: peoples,
    skill: skills,
    // role: [...roles, ...dRoles],
    role: roles,
    domain: domains,
    link: links,
    circle: circles,
    project: projects,
  };

  const getBadgeColor = (cardType) => {
    // Determine badge color
    const badgeColor =
      cardType === "people" ? "yellow" :
        ["skill", "role", "domain", "link"].includes(cardType) ? "blue" :
          ["circle", "project"].includes(cardType) ? "list-green" : "";
    return badgeColor;
  }

  const IsOwnerExternal = (e, cardType) => {
    if (!e) return false;

    let allExternalPeopleIds = peoples.filter((ps) => ps.workRole === "External").map((ps) => ps._id);

    switch (cardType) {
      case "Skill":
      case "Role":
        if (e.owners) {
          let exists = e.owners.filter((value) =>
            allExternalPeopleIds.includes(value)
          );
          return exists.length > 0 ? true : false;
        } else {
          return false;
        }
        break;

      case "Domain":
        if (e.owners && e.owners.owner && e.owners.owner.length > 0) {
          let exists = allExternalPeopleIds.includes(e.owners.owner);
          return exists ? true : false;
        }
        break;

      case "Link":
        if (e.owner && e.owner.length > 0) {
          let exists = allExternalPeopleIds.includes(e.owner);
          return exists ? true : false;
        }
        break;

      default:
        return false;
    }
  };

  const OwnerExist = (e, cardType) => {
    if (cardType === "People" || cardType === "Circle" || cardType === "Project") {
      return true; // fake true
    } else if (cardType === "Domain") {
      return e.owners && e.owners.owner && e.owners.owner.length > 0 && peoples.some(ps => ps._id.toString() === e.owners.owner.toString());
    } else if (cardType === "Link") {
      return e.owner && e.owner.length > 0 && peoples.some(ps => ps._id.toString() === e.owner.toString());
    } else {
      return e.owners && e.owners.some(owner =>
        peoples.some(ps => ps._id.toString() === owner.toString())
      );
    }
  };

  const getFormattedFeedback = () => {
    const feedbackCards = [];
    if (feedbackData && feedbackData.length > 0) {
      feedbackData.map((feedbackObject, index) => {
        const { _id, cardId, teamId, peopleId, feedback, createdAt } = feedbackObject;

        // Find the card name
        const findName = (id, list, type) => {
          const item = list.find((item) => item._id === id);
          return item ? (type === 'people' ? item['fname'] + ' ' + item['lname'] : item[type + 'Name']) : "Unknown";
        };

        let cardFound = false;
        let cardType = null;
        let cardName = "Unknown";

        for (const [type, list] of Object.entries(cardCategories)) {
          cardFound = list.find((item) => item._id === cardId);
          if (cardFound) {
            cardType = type;
            cardName = findName(cardId, list, type);
            break;
          }
        }

        // Format date
        const formattedDate = new Date(createdAt).toLocaleDateString("en-US", formatOptions);

        feedbackCards.push({
          _id,
          cardId,
          teamId,
          peopleId,
          feedback,
          date: formattedDate,
          name: cardName,
          list: (
            <span className={`custom-badge ${getBadgeColor(cardType)}`}>
              <img src={`/images/icon-${cardType}.png`} alt={cardType} />
              {/* {cardType ? cardType.charAt(0).toUpperCase() + cardType.slice(1) : "Unknown"} */}
              {cardType ? GetAliasesName(teams, cardType.charAt(0).toUpperCase() + cardType.slice(1)) : "Unknown"}
            </span>
          ),
          member: findName(peopleId, peoples, "people"),
          type: cardType,
          card: cardFound
        });
      });
    }
    return feedbackCards;
  };

  const getFormattedTagged = () => {
    const campfireItems = [];

    for (const [type, list] of Object.entries(cardCategories)) {
      const filteredItems = list.filter(item =>
        item.tags && item.tags.some(tag => tag.toLowerCase() === "campfire")
      );

      if (filteredItems.length > 0) {
        campfireItems.push(...filteredItems.map(item => ({
          ...item,
          type,
          cardName: type === 'people'
            ? item['fname'] + ' ' + item['lname']
            : item[type + 'Name'] || "Unknown",
          list: (
            <span className={`custom-badge ${getBadgeColor(type)}`}>
              <img src={`/images/icon-${type}.png`} alt={type} />
              {/* {type ? type.charAt(0).toUpperCase() + type.slice(1) : "Unknown"} */}
              {type ? GetAliasesName(teams, type.charAt(0).toUpperCase() + type.slice(1)) : "Unknown"}
            </span>
          ),
        })));
      }
    }

    // return campfireItems;

    // Sort the campfireItems array by cardName (A-Z)
    return campfireItems.sort((a, b) => a.cardName.localeCompare(b.cardName));
  };

  const getWorkLoadPeople = () => {
    const overloadGroups = {
      overloaded: [],  // Workload > 1
      underloaded: [], // Workload < 1
      unassigned: []   // Workload = 0
    };

    // Function to calculate total workload
    const calculateWorkload = (personId) => {
      let totalWorkload = 0;

      for (const [type, list] of Object.entries(cardCategories)) {
        if (!["skill", "role", "domain", "link"].includes(type)) continue;

        for (const item of list) {
          if (item.workload) {
            if (type === "skill" || type === "role") {
              // Skills & Roles: Match owners.ownerId
              for (const wl of item.workload) {
                if (wl.plan > 0 && wl.ownerId.toString() === personId.toString() && item.owners.includes(wl.ownerId)) {
                  totalWorkload += wl.plan || 0;
                }
              }
            } else if (type === "domain") {
              // Domains: Match owners.owner
              for (const wl of item.workload) {
                if (wl.plan > 0 && wl.ownerId.toString() === personId.toString() && item.owners.owner.toString() === wl.ownerId.toString()) {
                  totalWorkload += wl.plan || 0;
                }
              }
            } else if (type === "link") {
              // Links: Match item.owner directly
              for (const wl of item.workload) {
                if (wl.plan > 0 && wl.ownerId.toString() === personId.toString() && item.owner.toString() === wl.ownerId.toString()) {
                  totalWorkload += wl.plan || 0;
                }
              }
            }
          }
        }
      }

      return totalWorkload;
    };

    // Function to get styled badge
    const getStyledBadge = (workload, availability) => {
      let badgeClass = "custom-badge outline";
      let badgeColor = "#000";

      if (workload > 0 && workload > availability) {
        // badgeClass = "custom-badge red";
        badgeColor = "#FF4B4B";
      } else if (workload > 0 && workload < availability) {
        // badgeClass = "custom-badge yellow";
        badgeColor = "#F3D168";
      }

      return (
        <span className={badgeClass} style={{ color: badgeColor }}>
          {workload.toFixed(2)} / {availability.toFixed(2)}
        </span>
      );
    };

    // Iterate over people and categorize them
    for (const person of cardCategories.people) {
      if (!person.availability || person.availability == 0) continue;

      const totalWorkload = calculateWorkload(person._id);
      const workloadBadge = getStyledBadge(totalWorkload, person.availability);

      // Limit display to 3 tags and show "+X more" if there are extra tags
      const MAX_DISPLAY_TAGS = 2;
      const displayedTags = (person.tags || []).slice(0, MAX_DISPLAY_TAGS).map((tag, index) => (
        <span key={index} className="custom-badge green">
          {tag}
        </span>
      ));
      const remainingCount = (person.tags.length || 0) - MAX_DISPLAY_TAGS;

      const personEntry = {
        ...person,
        loweredName: (person.fname + " " + person.lname).toLowerCase(),
        totalWorkload,
        availability: person.availability,
        workloadBadge,
        styledTags: (
          <div className="badges-container">
            {displayedTags}
            {remainingCount > 0 && (
              <span className="custom-badge" style={{ color: "#000" }}>
                +{remainingCount} more
              </span>
            )}
          </div>
        )
      };

      if (totalWorkload > personEntry.availability) {
        overloadGroups.overloaded.push(personEntry);
      } else if (totalWorkload >= 0 && totalWorkload < personEntry.availability) {
        overloadGroups.underloaded.push(personEntry);
      } else {
        overloadGroups.unassigned.push(personEntry);
      }
    }

    // return overloadGroups;

    // console.log(overloadGroups);

    /* return {
      overloaded: overloadGroups.overloaded.sort((a, b) => a.loweredName.localeCompare(b.loweredName)),
      underloaded: overloadGroups.underloaded.sort((a, b) => a.loweredName.localeCompare(b.loweredName)),
      unassigned: overloadGroups.unassigned.sort((a, b) => a.loweredName.localeCompare(b.loweredName)),
    }; */

    return {
      overloaded: overloadGroups.overloaded
        .filter(person => person && person.loweredName) // Ensure valid objects
        .sort((a, b) => a.loweredName.localeCompare(b.loweredName)),

      underloaded: overloadGroups.underloaded
        .filter(person => person && person.loweredName)
        .sort((a, b) => a.loweredName.localeCompare(b.loweredName)),

      unassigned: overloadGroups.unassigned
        .filter(person => person && person.loweredName)
        .sort((a, b) => a.loweredName.localeCompare(b.loweredName)),
    };

  };

  const getStaffedCards = () => {
    const staffedGroups = {
      overstaffed: [],
      understaffed: []
    };

    for (const [type, list] of Object.entries(cardCategories)) {
      if (!["skill", "role", "domain", "link"].includes(type)) continue;

      for (const item of list) {
        // console.log(type, item.workload);
        if (!item.workload || !item.planTotal) continue;

        // Sum up total workload plan
        // const totalWorkload = item.workload.reduce((sum, wl) => sum + (wl.plan || 0), 0); // This assumes wl.plan to be 0 even if empty

        const totalWorkload = item.workload
          .map(wl => wl.plan)
          .filter(plan => plan != null && plan !== "") // Remove null, undefined, and empty strings
          .reduce((sum, plan) => sum + plan, 0);

        const planTotal = item.planTotal || 0;

        // console.log(type, totalWorkload, planTotal);

        const displayedTags = item.tags ? item.tags.slice(0, 2) : [];
        const remainingCount = item.tags && item.tags.length > 2 ? item.tags.length - 2 : 0;

        if (totalWorkload === planTotal) continue;

        // Function to get styled badge
        const getStyledBadge = (totalWorkload, planTotal) => {
          let badgeClass = "custom-badge outline";
          let badgeColor = "#000";

          if (totalWorkload > planTotal) {
            // badgeClass = "custom-badge red";
            badgeColor = "#FF4B4B";
          } else if (totalWorkload < planTotal) {
            // badgeClass = "custom-badge yellow";
            badgeColor = "#F3D168";
          }

          return (
            <span className={badgeClass} style={{ color: badgeColor }}>
              {planTotal.toFixed(2)} / {totalWorkload.toFixed(2)}
            </span>
          );
        };

        // Categorize into Overstaffed or Understaffed
        const itemEntry = {
          ...item,
          loweredName: item[type + 'Name'].toLowerCase(),
          totalWorkload,
          styledBadge: getStyledBadge(totalWorkload, planTotal),
          type,
          list: (
            <div className="badges-container">
              <span className={`custom-badge ${getBadgeColor(type)}`}>
                <img src={`/images/icon-${type}.png`} alt={type} />
                {/* {type ? type.charAt(0).toUpperCase() + type.slice(1) : "Unknown"} */}
                {type ? GetAliasesName(teams, type) : "Unknown"}
              </span>
            </div>
          ),
          styledTags: (
            <div className="badges-container">
              {displayedTags.map((tag, index) => (
                <span key={index} className="custom-badge green">
                  {tag}
                </span>
              ))}
              {remainingCount > 0 && (
                <span className="custom-badge" style={{ color: "#000" }}>
                  +{remainingCount} more
                </span>
              )}
            </div>
          ),
        };

        if (totalWorkload > planTotal) {
          staffedGroups.overstaffed.push(itemEntry);
        } else {
          staffedGroups.understaffed.push(itemEntry);
        }
      }
    }

    // return staffedGroups;

    return {
      overstaffed: staffedGroups.overstaffed.filter(item => item && item.loweredName).sort((a, b) => a.loweredName.localeCompare(b.loweredName)),
      understaffed: staffedGroups.understaffed.filter(item => item && item.loweredName).sort((a, b) => a.loweredName.localeCompare(b.loweredName))
    };

  };

  const getCardsWithoutOwner = () => {
    const unownedCards = [];

    for (const [type, list] of Object.entries(cardCategories)) {
      if (["people", "circle", "project"].includes(type)) {
        continue;
      }

      let filteredItems = [];

      if (type === "domain") {
        filteredItems = list.filter(item => !item.owners || !item.owners.owner || item.owners.owner.length === 0 || !peoples.some(ps => ps._id.toString() === item.owners.owner.toString()));
      } else if (type === "link") {
        filteredItems = list.filter(item => !item.owner || !peoples.some(ps => ps._id.toString() === item.owner.toString()));
      } else {
        filteredItems = list.filter(item =>
          !item.owners ||
          item.owners.length === 0 ||
          item.owners.filter(io => peoples.some(ps => io.toString() === ps._id.toString())).length === 0
        );
      }

      if (filteredItems.length > 0) {
        unownedCards.push(...filteredItems.map(item => {
          const displayedTags = item.tags ? item.tags.slice(0, 2) : [];
          const remainingCount = item.tags && item.tags.length > 2 ? item.tags.length - 2 : 0;

          return {
            ...item,
            type,
            cardName: type === 'people'
              ? item['fname'] + ' ' + item['lname']
              : item[type + 'Name'] || "Unknown",
            list: (
              <div className="badges-container">
                <span className={`custom-badge ${getBadgeColor(type)}`}>
                  <img src={`/images/icon-${type}.png`} alt={type} />
                  {/* {type ? type.charAt(0).toUpperCase() + type.slice(1) : "Unknown"} */}
                  {type ? GetAliasesName(teams, type) : "Unknown"}
                </span>
              </div>
            ),
            styledTags: (
              <div className="badges-container">
                {displayedTags.map((tag, index) => (
                  <span key={index} className="custom-badge green">
                    {tag}
                  </span>
                ))}
                {remainingCount > 0 && (
                  <span className="custom-badge" style={{ color: "#000" }}>
                    +{remainingCount} more
                  </span>
                )}
              </div>
            ),
          };
        }));
      }
    }
    // return unownedCards;
    return unownedCards.sort((a, b) => a.cardName.localeCompare(b.cardName))
  };

  const getCardsWithEXTOwner = () => {
    const extOwnedCards = [];

    for (const [type, list] of Object.entries(cardCategories)) {
      if (["people", "circle", "project"].includes(type)) {
        continue;
      }

      let filteredItems = [];

      if (type === "domain") {
        filteredItems = list.filter(item => item.owners && item.owners.owner && item.owners.owner.length > 0 && peoples.some(ps => ps.workRole === "External" && ps._id.toString() === item.owners.owner.toString()));
      } else if (type === "link") {
        filteredItems = list.filter(item => item.owner && peoples.some(ps => ps.workRole === "External" && ps._id.toString() === item.owner.toString()));
      } else {
        filteredItems = list.filter(item =>
          item.owners &&
          item.owners.length > 0 &&
          item.owners.filter(io => peoples.some(ps => ps.workRole === "External" && io.toString() === ps._id.toString())).length > 0
        );
      }

      if (filteredItems.length > 0) {
        extOwnedCards.push(...filteredItems.map(item => {
          // console.log(item)
          const displayedTags = item.tags ? item.tags.slice(0, 2) : [];
          const remainingCount = item.tags && item.tags.length > 2 ? item.tags.length - 2 : 0;

          return {
            ...item,
            type,
            cardName: type === 'people'
              ? item['fname'] + ' ' + item['lname']
              : item[type + 'Name'] || "Unknown",
            list: (
              <div className="badges-container">
                <span className={`custom-badge ${getBadgeColor(type)}`}>
                  <img src={`/images/icon-${type}.png`} alt={type} />
                  {/* {type ? type.charAt(0).toUpperCase() + type.slice(1) : "Unknown"} */}
                  {type ? GetAliasesName(teams, type) : "Unknown"}
                </span>
              </div>
            ),
            styledTags: (
              <div className="badges-container">
                {displayedTags.map((tag, index) => (
                  <span key={index} className="custom-badge green">
                    {tag}
                  </span>
                ))}
                {remainingCount > 0 && (
                  <span className="custom-badge" style={{ color: "#000" }}>
                    +{remainingCount} more
                  </span>
                )}
              </div>
            ),
          };
        }));
      }
    }
    // return extOwnedCards;
    return extOwnedCards.sort((a, b) => a.cardName.localeCompare(b.cardName));
  };

  const getCardsFadeInOut = (grouped = false) => {
    const fadeInOutCards = {
      fadeIn: [],
      fadeOut: []
    };

    for (const [type, list] of Object.entries(cardCategories)) {
      if (type !== "people") continue; // Only process "people" category

      list.forEach(item => {
        const displayedTags = item.tags ? item.tags.slice(0, 2) : [];
        const remainingCount = item.tags && item.tags.length > 2 ? item.tags.length - 2 : 0;

        // Helper function to find matched items (Skills, Roles, Domains, Links)
        const findEntries = (idArray) => {
          if (!idArray || idArray.length === 0) return;

          let matchedEntries = [];

          idArray.forEach(id => {
            const foundSkill = skills.find(skill => skill._id.toString() === id.toString());
            if (foundSkill) matchedEntries.push({ id: foundSkill._id, name: foundSkill.skillName, type: "Skill", list: "Skill", card: foundSkill });

            const foundRole = roles.find(role => role._id.toString() === id.toString());
            if (foundRole) matchedEntries.push({ id: foundRole._id, name: foundRole.roleName, type: "Role", list: "Role", card: foundRole });

            const foundDomain = domains.find(domain => domain._id.toString() === id.toString());
            if (foundDomain) matchedEntries.push({ id: foundDomain._id, name: foundDomain.domainName, type: "Domain", list: "Domain", card: foundDomain });

            const foundLink = links.find(link => link._id.toString() === id.toString());
            if (foundLink) matchedEntries.push({ id: foundLink._id, name: foundLink.linkName, type: "Link", list: "Link", card: foundLink });
          });

          return matchedEntries;
        };

        // Find associated entries for fadeInIds and fadeOutIds
        const fadeInEntries = findEntries(item.fadeInIds) || [];
        const fadeOutEntries = findEntries(item.fadeOutIds) || [];

        // Function to create card objects
        const createCard = (fadeType, associations) => ({
          ...item,
          type,
          cardName: `${item.fname} ${item.lname}`,
          list: (
            <div className="badges-container">
              {associations.map((a, index) => (
                <span key={index} className={`custom-badge blue`}>
                  <img src={`/images/icon-${a.type.toLowerCase()}.png`} alt={a.type} />
                  {/* {a.list} */}
                  {a.type ? GetAliasesName(teams, a.list) : "Unknown"}
                </span>
              ))}
            </div>
          ),
          styledTags: (
            <div className="badges-container">
              {displayedTags.map((tag, index) => (
                <span key={index} className="custom-badge green">
                  {tag}
                </span>
              ))}
              {remainingCount > 0 && (
                <span className="custom-badge" style={{ color: "#000" }}>
                  +{remainingCount} more
                </span>
              )}
            </div>
          ),
          fadeType,
          associatedWith: grouped
            ? associations.map(a => `${a.type}: ${a.name}`).join(", ")
            : associations.map(a => ({ type: a.type, name: a.name, list: a.list, card: a.card })) // Keeps array for ungrouped
        });

        // Push cards based on grouping preference
        if (grouped) {
          if (fadeInEntries.length > 0) fadeInOutCards.fadeIn.push(createCard("fadeIn", fadeInEntries));
          if (fadeOutEntries.length > 0) fadeInOutCards.fadeOut.push(createCard("fadeOut", fadeOutEntries));
        } else {
          fadeInEntries.forEach(entry => fadeInOutCards.fadeIn.push(createCard("fadeIn", [entry])));
          fadeOutEntries.forEach(entry => fadeInOutCards.fadeOut.push(createCard("fadeOut", [entry])));
        }
      });
    }

    // return fadeInOutCards;

    return {
      fadeIn: fadeInOutCards.fadeIn.filter(item => item && item.loweredName).sort((a, b) => a.loweredName.localeCompare(b.loweredName)),
      fadeOut: fadeInOutCards.fadeOut.filter(item => item && item.loweredName).sort((a, b) => a.loweredName.localeCompare(b.loweredName))
    };

  };

  const getCardsWithoutPurpose = () => {
    const noPurposeCards = [];

    for (const [type, list] of Object.entries(cardCategories)) {
      if (["people"].includes(type)) {
        continue;
      }

      let filteredItems = list.filter(item => !item.purpose || item.purpose.trim() === "");

      if (filteredItems.length > 0) {
        noPurposeCards.push(...filteredItems.map(item => {
          const displayedTags = item.tags ? item.tags.slice(0, 2) : [];
          const remainingCount = item.tags && item.tags.length > 2 ? item.tags.length - 2 : 0;

          return {
            ...item,
            type,
            cardName: item[type + 'Name'] || "Unknown",
            list: (
              <div className="badges-container">
                <span className={`custom-badge ${getBadgeColor(type)}`}>
                  <img src={`/images/icon-${type}.png`} alt={type} />
                  {/* {type ? type.charAt(0).toUpperCase() + type.slice(1) : "Unknown"} */}
                  {type ? GetAliasesName(teams, type) : "Unknown"}
                </span>
              </div>
            ),
            styledTags: (
              <div className="badges-container">
                {displayedTags.map((tag, index) => (
                  <span key={index} className="custom-badge green">
                    {tag}
                  </span>
                ))}
                {remainingCount > 0 && (
                  <span className="custom-badge" style={{ color: "#000" }}>
                    +{remainingCount} more
                  </span>
                )}
              </div>
            ),
          };
        }));
      }
    }
    // return noPurposeCards
    return noPurposeCards.sort((a, b) => a.cardName.localeCompare(b.cardName));
  };

  const getCardsWithoutTask = () => {
    const noTaskCards = [];

    for (const [type, list] of Object.entries(cardCategories)) {
      if (["people"].includes(type)) {
        continue;
      }

      let filteredItems = list.filter(item => !item.tasks || item.tasks.length === 0);

      if (filteredItems.length > 0) {
        noTaskCards.push(...filteredItems.map(item => {
          const displayedTags = item.tags ? item.tags.slice(0, 2) : [];
          const remainingCount = item.tags && item.tags.length > 2 ? item.tags.length - 2 : 0;

          return {
            ...item,
            type,
            cardName: item[type + 'Name'] || "Unknown",
            list: (
              <div className="badges-container">
                <span className={`custom-badge ${getBadgeColor(type)}`}>
                  <img src={`/images/icon-${type}.png`} alt={type} />
                  {/* {type ? type.charAt(0).toUpperCase() + type.slice(1) : "Unknown"} */}
                  {type ? GetAliasesName(teams, type) : "Unknown"}
                </span>
              </div>
            ),
            styledTags: (
              <div className="badges-container">
                {displayedTags.map((tag, index) => (
                  <span key={index} className="custom-badge green">
                    {tag}
                  </span>
                ))}
                {remainingCount > 0 && (
                  <span className="custom-badge" style={{ color: "#000" }}>
                    +{remainingCount} more
                  </span>
                )}
              </div>
            ),
          };
        }));
      }
    }
    // return noTaskCards;
    return noTaskCards.sort((a, b) => a.cardName.localeCompare(b.cardName));
  };

  const getCardsWithoutObjective = () => {
    const noObjectiveCards = [];

    for (const [type, list] of Object.entries(cardCategories)) {
      if (["people"].includes(type)) {
        continue;
      }

      let filteredItems = list.filter(item => !item.objectives || (item.objectives && item.objectives.length === 1 && item.objectives[0].objective === ""));

      if (filteredItems.length > 0) {
        noObjectiveCards.push(...filteredItems.map(item => {
          const displayedTags = item.tags ? item.tags.slice(0, 2) : [];
          const remainingCount = item.tags && item.tags.length > 2 ? item.tags.length - 2 : 0;

          return {
            ...item,
            type,
            cardName: item[type + 'Name'] || "Unknown",
            list: (
              <div className="badges-container">
                <span className={`custom-badge ${getBadgeColor(type)}`}>
                  <img src={`/images/icon-${type}.png`} alt={type} />
                  {/* {type ? type.charAt(0).toUpperCase() + type.slice(1) : "Unknown"} */}
                  {type ? GetAliasesName(teams, type) : "Unknown"}
                </span>
              </div>
            ),
            styledTags: (
              <div className="badges-container">
                {displayedTags.map((tag, index) => (
                  <span key={index} className="custom-badge green">
                    {tag}
                  </span>
                ))}
                {remainingCount > 0 && (
                  <span className="custom-badge" style={{ color: "#000" }}>
                    +{remainingCount} more
                  </span>
                )}
              </div>
            ),
          };
        }));
      }
    }
    // return noObjectiveCards;
    return noObjectiveCards.sort((a, b) => a.cardName.localeCompare(b.cardName))
  };

  useEffect(() => {
    const feedbackCards = getFormattedFeedback();
    setFormattedFeedback(feedbackCards);

    const campfireResults = getFormattedTagged();
    setAllCampfireItems(campfireResults);

    const workLoadCards = getWorkLoadPeople();
    setWorkloadItems(workLoadCards);

    const staffedCards = getStaffedCards();
    setStaffedItems(staffedCards);

    const unownedCards = getCardsWithoutOwner();
    setNoItems(unownedCards);

    const extOwnedCards = getCardsWithEXTOwner();
    setEXTItems(extOwnedCards);

    const fadeInOutCards = getCardsFadeInOut();
    setFadeInOutItems(fadeInOutCards);

    const noPurposeCards = getCardsWithoutPurpose();
    setNoPurposeItems(noPurposeCards);

    const noTaskCards = getCardsWithoutTask();
    setNoTaskItems(noTaskCards);

    const noObjectiveCards = getCardsWithoutObjective();
    setNoObjectiveItems(noObjectiveCards);

    const defaultCircleItem = dCircles && dCircles.length > 0 ? dCircles.find(circle => circle.defaultCircle === true) : null;
    setDefaultCircle(defaultCircleItem);

  }, [peoples, skills, roles, dRoles, domains, links, circles, projects, feedbackData]);

  const handleDeleteFeedback = (feedbackId) => {
    console.log(feedbackId)
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to restore!",
      // icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        let obj = {
          teamId: localStorage.getItem("teamId"),
          userId: myInfo.userId,
          feedbackId
        }
        dispatch(deleteFeedback(obj)).then(() => {
          // dispatch(getFeedbacks(obj));
          dispatch(getState(obj))
        });
      }
    });
  };

  const handleModalOpen = (data) => {
    indexSet(data);
    if (data.type.toLowerCase() === "people") {
      const indexWithinAllPeople = peoples.findIndex(person => person._id === data._id);
      setPepole(data, peoples, indexWithinAllPeople);
      // openPeople();
    }
    if (data.type.toLowerCase() === "skill") {
      const indexWithinAllSkill = skills.findIndex(s => s._id === data._id);
      setSkill(data, skills, indexWithinAllSkill);
      // openSkill();
    }
    if (data.type.toLowerCase() === "role") {
      const indexWithinAllRole = [...roles, ...dRoles].findIndex(r => r._id === data._id);
      setRole(data, roles, indexWithinAllRole);
      // openRole();
    }
    if (data.type.toLowerCase() === "domain") {
      const indexWithinAllDomain = domains.findIndex(r => r._id === data._id);
      setDomain(data, domains, indexWithinAllDomain);
      // openDomain();
    }
    if (data.type.toLowerCase() === "link") {
      const indexWithinAllLink = links.findIndex(l => l._id === data._id);
      setLink(data, links, indexWithinAllLink);
      // openLink();
    }
    if (data.type.toLowerCase() === "circle") {
      const indexWithinAllCircle = circles.findIndex(r => r._id === data._id);
      setCircle(data, circles, indexWithinAllCircle);
      // openCircle();
    }
    if (data.type.toLowerCase() === "project") {
      const indexWithinAllProject = projects.findIndex(l => l._id === data._id);
      setProject(data, projects, indexWithinAllProject);
      // openProject();
    }
  };

  const handleFocused = (data) => {
    setCampfireComponent(false);
    setFocused(data);
  };

  const handleDetag = (data) => {
    data.tags = data.tags.filter(tag => tag.toLowerCase() !== "campfire");
    data.adminId = myInfo.userId;

    switch (data.type) {
      case "people":
        dispatch(updatePeople(data)).then(() => {
          dispatch({ type: "PEOPLE_SUCCESS_MESSAGE_CLEAR" });
          dispatch({ type: "PEOPLE_ERROR_CLEAR" });
        });
        break;

      case "skill":
        dispatch(updateSkills(data)).then(() => {
          dispatch({ type: "SKILL_SUCCESS_MESSAGE_CLEAR" });
          dispatch({ type: "SKILL_ERROR_CLEAR" });
        });
        break;

      case "role":
        dispatch(updateRole(data)).then(() => {
          dispatch({ type: "ROLE_SUCCESS_MESSAGE_CLEAR" });
          dispatch({ type: "ROLE_ERROR_CLEAR" });
        });
        break;

      case "domain":
        dispatch(updateDomain(data)).then(() => {
          dispatch({ type: "DOMAIN_SUCCESS_MESSAGE_CLEAR" });
          dispatch({ type: "DOMAIN_ERROR_CLEAR" });
        });
        break;

      case "link":
        dispatch(updateLink(data)).then(() => {
          dispatch({ type: "LINK_SUCCESS_MESSAGE_CLEAR" });
          dispatch({ type: "LINK_ERROR_CLEAR" });
        });
        break;

      case "circle":
        dispatch(updateCircle(data)).then(() => {
          dispatch({ type: "CIRCLE_SUCCESS_MESSAGE_CLEAR" });
          dispatch({ type: "CIRCLE_ERROR_CLEAR" });
        });
        break;

      case "project":
        dispatch(updateProject(data)).then(() => {
          dispatch({ type: "PROJECT_SUCCESS_MESSAGE_CLEAR" });
          dispatch({ type: "PROJECT_ERROR_CLEAR" });
        });
        break;
    }
  };

  return (
    <div className="campfire-page container-fluid">
      <div className="row campfire-header">
        <div className="col-md-12">
          <div className="tracking-main">
            <div className={`tracking-part collapsed`}>
              <h2>🔥Campfire
                <Tippy content={<span>{Object.values(isCollapsed).every(i => i) ? "Expand All" : "Collapse All"}</span>} placement="right" theme="custom">
                  <div className="icon-wrapper" data-expanded="false"
                    onClick={() => handleToggle(Object.values(isCollapsed).every(i => i) ? 0 : -1)}
                  >
                    <img src={Object.values(isCollapsed).every(i => i) ? "/images/expand.svg" : "/images/collapse.svg"} alt="toggle icon" />
                  </div>
                </Tippy>
              </h2>
              <p>This is the place where you optimize the way your team works! This page shows you options to improve. Setup a regular campfire meeting (<span style={{ textDecoration: "underline", cursor: "pointer" }} onClick={() => handleModalOpen({ ...defaultCircle, type: "circle" })}>find meeting here</span>) with your team and go through these points:</p>
            </div>
          </div>
        </div>
      </div>

      <div className="row campfire-body">
        <div className="col-md-12">
          <div className="panel-group" id="accordion">

            <h3 className="section-heading">On the Radar <img className="icon" src="/images/icon-info.png" onClick={() => window.open("https://teamdecoder.tawk.help/article/feedback", "_blank")} /> <small>Fresh feedback and topics up for discussion.</small></h3>

            {/* Feedback */}
            <div className="panel panel-default">
              <div className="panel-heading">
                <h4 className="panel-title" style={panelStyles.heading}>
                  <a /* data-toggle="collapse" href="#collapse1" */ className={`${isCollapsed[1] ? "collapsed" : ""}`} onClick={() => handleToggle(1)}>
                    <i className={`chevron-icon fa fa-chevron-${isCollapsed[1] ? "right" : "down"}`}></i> Feedback {formattedFeedback && `(${formattedFeedback.length})`} - <small>This is feedback from the team, provided in reports.</small></a>
                </h4>
              </div>
              <div id="collapse1" className={`panel-collapse collapse ${!isCollapsed[1] ? "in" : ""}`}>
                <div className="panel-body">
                  <div className="table-responsive">
                    <table className="table" id="feedback">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Name</th>
                          <th>List</th>
                          <th>Team member</th>
                          <th>Feedback</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {formattedFeedback && formattedFeedback.length > 0 ?
                          formattedFeedback.map((feedbackObject, index) => {
                            const item = { ...feedbackObject.card, type: feedbackObject.type };
                            // console.log(feedbackObject.card);
                            return (
                              <tr key={index}>
                                <td className="td-date">{feedbackObject.date}</td>
                                <td className="td-name">{feedbackObject.name}</td>
                                <td className="td-list">{feedbackObject.list}</td>
                                <td className="td-pills">{feedbackObject.member}</td>
                                <td className="td-feedback">{feedbackObject.feedback}</td>
                                <td className="actions">
                                  <div>
                                    <a href="#" onClick={() => { handleModalOpen(item) }}><img src="/images/link.svg" /></a>
                                    <a href="#" onClick={() => { handleDeleteFeedback(feedbackObject._id) }}><img src="/images/trash.svg" /></a>
                                  </div>
                                </td>
                              </tr>
                            )
                          })
                          : (<tr><td colSpan="6">No feedback received</td></tr>)}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            {/* Tagged "Campfire" */}
            <div className="panel panel-default">
              <div className="panel-heading">
                <h4 className="panel-title" style={panelStyles.heading}>
                  <a /* data-toggle="collapse" href="#collapse2" */ className={`${isCollapsed[2] ? "collapsed" : ""}`} onClick={() => handleToggle(2)}>
                    <i className={`chevron-icon fa fa-chevron-${isCollapsed[2] ? "right" : "down"}`}></i> Tagged {allCampfireItems && `(${allCampfireItems.length})`} - <small>Tag all items you want to discuss “campfire” and they will show here.</small></a>
                </h4>
              </div>
              <div id="collapse2" className={`panel-collapse collapse ${!isCollapsed[2] ? "in" : ""}`}>
                <div className="panel-body">
                  <div className="table-responsive" id="tagged">
                    <table className="table">
                      <thead>
                        <tr>
                          <th className="th-name">Name</th>
                          <th className="th-list">List</th>
                          <th className="th-tags">Tags</th>
                          <th className="td-feedback">Notes</th>
                          <th className="th-actions"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {allCampfireItems && allCampfireItems.length > 0 ? allCampfireItems.map((citem, index) => {
                          const item = { ...citem, type: citem.type };
                          let isExternal = IsOwnerExternal(item, item.type.charAt(0).toUpperCase() + item.type.slice(1));
                          let hasOwner = OwnerExist(item, item.type.charAt(0).toUpperCase() + item.type.slice(1));
                          return (
                            <tr key={index}>
                              <td className="td-name">{item.cardName}</td>
                              <td className="td-list">{item.list}</td>
                              <td className="td-pills">
                                {!hasOwner && <span className="custom-badge red no-pill">NO</span>}
                                {isExternal && <span className="custom-badge blue ext-pill">EXT</span>}
                                <span className="custom-badge green">Campfire</span>
                              </td>
                              <td className="td-feedback">{item.notes}</td>
                              <td className="actions">
                                <div>
                                  <a href="#" onClick={() => { handleModalOpen(item) }}><img src="/images/link.svg" /></a>
                                  <a href="#" onClick={() => { handleDetag(item) }}><img src="/images/detag.svg" /></a>
                                </div>
                              </td>
                            </tr>
                          )
                        }) : (
                          <tr><td colSpan="5">No items found</td></tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <h3 className="section-heading">Workload <img className="icon" src="/images/icon-info.png" onClick={() => window.open("https://teamdecoder.tawk.help/article/workloadplanning", "_blank")} /></h3>

            {/* Overload */}
            <div className="panel panel-default">
              <div className="panel-heading">
                <h4 className="panel-title" style={panelStyles.heading}>
                  <a /* data-toggle="collapse" href="#collapse3" */ className={`${isCollapsed[3] ? "collapsed" : ""}`} onClick={() => handleToggle(3)}>
                    <i className={`chevron-icon fa fa-chevron-${isCollapsed[3] ? "right" : "down"}`}></i> Overload {workloadItems && workloadItems.overloaded && `(${workloadItems.overloaded.length})`} - <small>{`These are all people that have a workload > 1 (red)`}</small></a>
                </h4>
              </div>
              <div id="collapse3" className={`panel-collapse collapse ${!isCollapsed[3] ? "in" : ""}`}>
                <div className="panel-body">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>List</th>
                          <th>Tags</th>
                          <th>FTE (asg/pln)</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {workloadItems && workloadItems.overloaded && workloadItems.overloaded.length > 0
                          ? workloadItems.overloaded.map((citem, index) => {

                            const item = { ...citem, type: "people" };
                            const peopleIndex = peoples.findIndex(p => p._id === citem._id);

                            return (
                              <tr key={index}>
                                <td className="td-name">{`${item.fname} ${item.lname}`}</td>
                                <td className="td-list"><span className="custom-badge yellow"><img src="/images/icon-people.png" alt="People Icon" />{GetAliasesName(teams, "People")}</span></td>
                                <td className="td-pills">
                                  {citem.workRole && citem.workRole === "External" && <span className="custom-badge blue ext-pill">EXT</span>}
                                  {item.styledTags}
                                </td>
                                <td className="td-feedback">{item.workloadBadge}</td>
                                <td className="actions">
                                  <div>
                                    <a href="#dashboard" onClick={() => { handleFocused({ card: "People", index: peopleIndex, id: citem._id }) }}><img src="/images/focus.svg" alt="Focus" /></a>
                                  </div>
                                </td>
                              </tr>
                            )
                          }) : <tr><td colSpan="5">No items found</td></tr>}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            {/* Underload */}
            <div className="panel panel-default">
              <div className="panel-heading">
                <h4 className="panel-title" style={panelStyles.heading}>
                  <a /* data-toggle="collapse" href="#collapse4" */ className={`${isCollapsed[4] ? "collapsed" : ""}`} onClick={() => handleToggle(4)}>
                    <i className={`chevron-icon fa fa-chevron-${isCollapsed[4] ? "right" : "down"}`}></i> Underload {workloadItems && workloadItems.underloaded && `(${workloadItems.underloaded.length})`} - <small>{`These are all people that have a workload < 1 (yellow)`}</small></a>
                </h4>
              </div>
              <div id="collapse4" className={`panel-collapse collapse ${!isCollapsed[4] ? "in" : ""}`}>
                <div className="panel-body">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>List</th>
                          <th>Tags</th>
                          <th>FTE (asg/pln)</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {workloadItems && workloadItems.underloaded && workloadItems.underloaded.length > 0
                          ? workloadItems.underloaded.map((citem, index) => {

                            const item = { ...citem, type: "people" };
                            const peopleIndex = peoples.findIndex(p => p._id === citem._id);

                            return (
                              <tr key={index}>
                                <td className="td-name">{`${item.fname} ${item.lname}`}</td>
                                <td className="td-list"><span className="custom-badge yellow"><img src="/images/icon-people.png" alt="People Icon" />{GetAliasesName(teams, "People")}</span></td>
                                <td className="td-pills">
                                  {citem.workRole && citem.workRole === "External" && <span className="custom-badge blue ext-pill">EXT</span>}
                                  {item.styledTags}
                                </td>
                                <td className="td-feedback">{item.workloadBadge}</td>
                                <td className="actions">
                                  <div>
                                    <a href="#dashboard" onClick={() => { handleFocused({ card: "People", index: peopleIndex, id: citem._id }) }}><img src="/images/focus.svg" alt="Focus" /></a>
                                  </div>
                                </td>
                              </tr>
                            )
                          }) : <tr><td colSpan="5">No items found</td></tr>}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            {/* Overstaffed */}
            <div className="panel panel-default">
              <div className="panel-heading">
                <h4 className="panel-title" style={panelStyles.heading}>
                  <a /* data-toggle="collapse" href="#collapse5" */ className={`${isCollapsed[5] ? "collapsed" : ""}`} onClick={() => handleToggle(5)}>
                    <i className={`chevron-icon fa fa-chevron-${isCollapsed[5] ? "right" : "down"}`}></i> Overstaffed {staffedItems && staffedItems.overstaffed && `(${staffedItems.overstaffed.length})`} - <small>These are all blue items (roles, etc.) with more people assigned than planned (red)</small></a>
                </h4>
              </div>
              <div id="collapse5" className={`panel-collapse collapse ${!isCollapsed[5] ? "in" : ""}`}>
                <div className="panel-body">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>List</th>
                          <th>Tags</th>
                          <th>FTE (plan/status)</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {staffedItems && staffedItems.overstaffed && staffedItems.overstaffed.length > 0
                          ? staffedItems.overstaffed.map((citem, index) => {
                            let isExternal = IsOwnerExternal(citem, citem.type.charAt(0).toUpperCase() + citem.type.slice(1));
                            let hasOwner = OwnerExist(citem, citem.type.charAt(0).toUpperCase() + citem.type.slice(1));

                            return (
                              <tr key={index}>
                                <td className="td-name">{citem[citem.type + 'Name']}</td>
                                <td className="td-list">{citem.list}</td>
                                <td className="td-pills">
                                  {!hasOwner && <span className="custom-badge red no-pill">NO</span>}
                                  {isExternal && <span className="custom-badge blue ext-pill">EXT</span>}
                                  {citem.styledTags}
                                </td>
                                <td className="td-feedback">{citem.styledBadge}</td>
                                <td className="actions">
                                  <div>
                                    <a href="#" onClick={() => { handleModalOpen(citem) }}><img src="/images/link.svg" /></a>
                                  </div>
                                </td>
                              </tr>
                            )
                          }) : <tr><td colSpan="5">No items found</td></tr>}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            {/* Understaffed */}
            <div className="panel panel-default">
              <div className="panel-heading">
                <h4 className="panel-title" style={panelStyles.heading}>
                  <a /* data-toggle="collapse" href="#collapse6" */ className={`${isCollapsed[6] ? "collapsed" : ""}`} onClick={() => handleToggle(6)}>
                    <i className={`chevron-icon fa fa-chevron-${isCollapsed[6] ? "right" : "down"}`}></i> Understaffed {staffedItems && staffedItems.understaffed && `(${staffedItems.understaffed.length})`} - <small>These are all blue items (roles, etc.) with fewer people assigned as planned (yellow)</small></a>
                </h4>
              </div>
              <div id="collapse6" className={`panel-collapse collapse ${!isCollapsed[6] ? "in" : ""}`}>
                <div className="panel-body">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>List</th>
                          <th>Tags</th>
                          <th>FTE (plan/status)</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {staffedItems && staffedItems.understaffed && staffedItems.understaffed.length > 0
                          ? staffedItems.understaffed.map((citem, index) => {
                            let isExternal = IsOwnerExternal(citem, citem.type.charAt(0).toUpperCase() + citem.type.slice(1));
                            let hasOwner = OwnerExist(citem, citem.type.charAt(0).toUpperCase() + citem.type.slice(1));

                            return (
                              <tr key={index}>
                                <td className="td-name">{citem[citem.type + 'Name']}</td>
                                <td className="td-list">{citem.list}</td>
                                <td className="td-pills">
                                  {!hasOwner && <span className="custom-badge red no-pill">NO</span>}
                                  {isExternal && <span className="custom-badge blue ext-pill">EXT</span>}
                                  {citem.styledTags}
                                </td>
                                <td className="td-feedback">{citem.styledBadge}</td>
                                <td className="actions">
                                  <div>
                                    <a href="#" onClick={() => { handleModalOpen(citem) }}><img src="/images/link.svg" /></a>
                                  </div>
                                </td>
                              </tr>
                            )
                          }) : <tr><td colSpan="5">No items found</td></tr>}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <h3 className="section-heading">Prioritization <img className="icon" src="/images/icon-info.png" onClick={() => window.open("https://teamdecoder.tawk.help/article/pills", "_blank")} /></h3>

            {/* Tagged "NO" */}
            <div className="panel panel-default">
              <div className="panel-heading">
                <h4 className="panel-title" style={panelStyles.heading}>
                  <a /* data-toggle="collapse" href="#collapse7" */ className={`${isCollapsed[7] ? "collapsed" : ""}`} onClick={() => handleToggle(7)}>
                    <i className={`chevron-icon fa fa-chevron-${isCollapsed[7] ? "right" : "down"}`}></i> Tagged "NO" {NOItems && `(${NOItems.length})`} - <small>All items that currently do not have an owner.</small></a>
                </h4>
              </div>
              <div id="collapse7" className={`panel-collapse collapse ${!isCollapsed[7] ? "in" : ""}`}>
                <div className="panel-body">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>List</th>
                          <th>Tags</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {NOItems && NOItems.length > 0 && NOItems.map((citem, index) => {
                          const item = { ...citem, type: citem.type };
                          let hasOwner = OwnerExist(item, item.type.charAt(0).toUpperCase() + item.type.slice(1));
                          return (
                            <tr key={index}>
                              <td className="td-name">{item.cardName}</td>
                              <td className="td-list">{item.list}</td>
                              <td className="td-pills">
                                {!hasOwner && <span className="custom-badge red no-pill">NO</span>}
                                {item.styledTags}
                              </td>
                              <td className="actions">
                                <div>
                                  <a href="#" onClick={() => { handleModalOpen(item) }}><img src="/images/link.svg" /></a>
                                </div>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            {/* Tagged "EXT" */}
            <div className="panel panel-default">
              <div className="panel-heading">
                <h4 className="panel-title" style={panelStyles.heading}>
                  <a /* data-toggle="collapse" href="#collapse8" */ className={`${isCollapsed[8] ? "collapsed" : ""}`} onClick={() => handleToggle(8)}>
                    <i className={`chevron-icon fa fa-chevron-${isCollapsed[8] ? "right" : "down"}`}></i> Tagged "EXT" {EXTItems && `(${EXTItems.length})`} - <small>All items that are currently done by external people.</small></a>
                </h4>
              </div>
              <div id="collapse8" className={`panel-collapse collapse ${!isCollapsed[8] ? "in" : ""}`}>
                <div className="panel-body">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th className="th-name">Name</th>
                          <th className="th-list">List</th>
                          <th className="th-tags">Tags</th>
                          <th className="th-actions"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {EXTItems && EXTItems.length > 0 && EXTItems.map((citem, index) => {
                          const item = { ...citem, type: citem.type };
                          let isExternal = IsOwnerExternal(item, item.type.charAt(0).toUpperCase() + item.type.slice(1));
                          return (
                            <tr key={index}>
                              <td className="td-name">{item.cardName}</td>
                              <td className="td-list">{item.list}</td>
                              <td className="td-pills">
                                {isExternal && <span className="custom-badge blue external-pill">EXT</span>}
                                {item.styledTags}
                              </td>
                              <td className="actions">
                                <div>
                                  <a href="#" onClick={() => { handleModalOpen(item) }}><img src="/images/link.svg" /></a>
                                </div>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <h3 className="section-heading">Development <img className="icon" src="/images/icon-info.png" onClick={() => window.open("https://teamdecoder.tawk.help/article/fading-in-out", "_blank")} /></h3>

            {/* Fading In */}
            <div className="panel panel-default">
              <div className="panel-heading">
                <h4 className="panel-title" style={panelStyles.heading}>
                  <a /* data-toggle="collapse" href="#collapse9" */ className={`${isCollapsed[9] ? "collapsed" : ""}`} onClick={() => handleToggle(9)}>
                    <i className={`chevron-icon fa fa-chevron-${isCollapsed[9] ? "right" : "down"}`}></i> Fading In {fadeInOutItems && fadeInOutItems.fadeIn && `(${fadeInOutItems.fadeIn.length})`} - <small>All people that are currently marked “Fading In” for a role.</small></a>
                </h4>
              </div>
              <div id="collapse9" className={`panel-collapse collapse ${!isCollapsed[9] ? "in" : ""}`}>
                <div className="panel-body">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Person</th>
                          <th>List</th>
                          <th>Item</th>
                          <th>Tags</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {fadeInOutItems && fadeInOutItems.fadeIn && fadeInOutItems.fadeIn.length > 0
                          ? fadeInOutItems.fadeIn.map((citem, index) => {
                            // console.log(citem);
                            const item = { ...citem.associatedWith[0].card, type: citem.associatedWith[0].type };
                            const peopleIndex = peoples.findIndex(p => p._id === citem._id);

                            return (
                              <tr key={index}>
                                <td className="td-name">{citem.cardName}</td>
                                <td className="td-list">{citem.list}</td>
                                <td className="td-name">{citem.associatedWith && citem.associatedWith.length > 0 && citem.associatedWith[0].name}</td>
                                <td className="td-feedback">{citem.styledTags}</td>
                                <td className="actions">
                                  <div>
                                    {/* <a href="#" onClick={() => { handleModalOpen(item) }}><img src="/images/link.svg" /></a> */}
                                    <a href="#dashboard" onClick={() => { handleFocused({ card: "People", index: peopleIndex, id: citem._id }) }}><img src="/images/focus.svg" /></a>
                                  </div>
                                </td>
                              </tr>
                            );
                          }) : (<tr><td colSpan="5">No items found</td></tr>)}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            {/* Fading Out */}
            <div className="panel panel-default">
              <div className="panel-heading">
                <h4 className="panel-title" style={panelStyles.heading}>
                  <a /* data-toggle="collapse" href="#collapse10" */ className={`${isCollapsed[10] ? "collapsed" : ""}`} onClick={() => handleToggle(10)}>
                    <i className={`chevron-icon fa fa-chevron-${isCollapsed[10] ? "right" : "down"}`}></i> Fading Out {fadeInOutItems && fadeInOutItems.fadeOut && `(${fadeInOutItems.fadeOut.length})`} - <small>All people that are currently marked “Fading Out” for a role.</small></a>
                </h4>
              </div>
              <div id="collapse10" className={`panel-collapse collapse ${!isCollapsed[10] ? "in" : ""}`}>
                <div className="panel-body">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Person</th>
                          <th>List</th>
                          <th>Item</th>
                          <th>Tags</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {fadeInOutItems && fadeInOutItems.fadeOut && fadeInOutItems.fadeOut.length > 0
                          ? fadeInOutItems.fadeOut.map((citem, index) => {
                            const item = { ...citem.associatedWith[0].card, type: citem.associatedWith[0].type };
                            const peopleIndex = peoples.findIndex(p => p._id === citem._id);
                            return (
                              <tr key={index}>
                                <td className="td-name">{citem.cardName}</td>
                                <td className="td-list">{citem.list}</td>
                                <td className="td-name">{citem.associatedWith && citem.associatedWith.length > 0 && citem.associatedWith[0].name}</td>
                                <td className="td-feedback">{citem.styledTags}</td>
                                <td className="actions">
                                  <div>
                                    {/* <a href="#" onClick={() => { handleModalOpen(item) }}><img src="/images/link.svg" /></a> */}
                                    <a href="#dashboard" onClick={() => { handleFocused({ card: "People", index: peopleIndex, id: citem._id }) }}><img src="/images/focus.svg" /></a>
                                  </div>
                                </td>
                              </tr>
                            );
                          }) : (<tr><td colSpan="5">No items found</td></tr>)}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <h3 className="section-heading">Clarity</h3>

            {/* Purpose */}
            <div className="panel panel-default">
              <div className="panel-heading">
                <h4 className="panel-title" style={panelStyles.heading}>
                  <a /* data-toggle="collapse" href="#collapse11" */ className={`${isCollapsed[11] ? "collapsed" : ""}`} onClick={() => handleToggle(11)}>
                    <i className={`chevron-icon fa fa-chevron-${isCollapsed[11] ? "right" : "down"}`}></i> Purpose {noPurposeItems && `(${noPurposeItems.length})`} - <small>All items that currently do not have a purpose.</small></a>
                </h4>
              </div>
              <div id="collapse11" className={`panel-collapse collapse ${!isCollapsed[11] ? "in" : ""}`}>
                <div className="panel-body">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th className="th-name">Name</th>
                          <th className="th-list">List</th>
                          <th className="th-tags">Tags</th>
                          <th className="th-actions"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {noPurposeItems && noPurposeItems.length > 0 ? noPurposeItems.map((citem, index) => {
                          const item = { ...citem, type: citem.type };
                          let hasOwner = OwnerExist(item, item.type.charAt(0).toUpperCase() + item.type.slice(1));
                          let isExternal = IsOwnerExternal(item, item.type.charAt(0).toUpperCase() + item.type.slice(1));
                          return (
                            <tr>
                              <td className="td-name">{item.cardName}</td>
                              <td className="td-list">{item.list}</td>
                              <td className="td-pills">{item.styledTags}</td>
                              <td className="actions">
                                <div>
                                  <a href="#" onClick={() => { handleModalOpen(item) }}><img src="/images/link.svg" /></a>
                                </div>
                              </td>
                            </tr>
                          );
                        }) : <tr><td colSpan="4">No items found</td></tr>}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            {/* Tasks */}
            <div className="panel panel-default">
              <div className="panel-heading">
                <h4 className="panel-title" style={panelStyles.heading}>
                  <a /* data-toggle="collapse" href="#collapse12" */ className={`${isCollapsed[12] ? "collapsed" : ""}`} onClick={() => handleToggle(12)}>
                    <i className={`chevron-icon fa fa-chevron-${isCollapsed[12] ? "right" : "down"}`}></i> Tasks {noTaskItems && `(${noTaskItems.length})`} - <small>All items that do currently not have any tasks.</small></a>
                </h4>
              </div>
              <div id="collapse12" className={`panel-collapse collapse ${!isCollapsed[12] ? "in" : ""}`}>
                <div className="panel-body">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th className="th-name">Name</th>
                          <th className="th-list">List</th>
                          <th className="th-tags">Tags</th>
                          <th className="th-actions"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {noTaskItems && noTaskItems.length > 0 ? noTaskItems.map((citem, index) => {
                          const item = { ...citem, type: citem.type };
                          let hasOwner = OwnerExist(item, item.type.charAt(0).toUpperCase() + item.type.slice(1));
                          let isExternal = IsOwnerExternal(item, item.type.charAt(0).toUpperCase() + item.type.slice(1));
                          return (
                            <tr>
                              <td className="td-name">{item.cardName}</td>
                              <td className="td-list">{item.list}</td>
                              <td className="td-pills">{item.styledTags}</td>
                              <td className="actions">
                                <div>
                                  <a href="#" onClick={() => { handleModalOpen(item) }}><img src="/images/link.svg" /></a>
                                </div>
                              </td>
                            </tr>
                          );
                        }) : <tr><td colSpan="4">No items found</td></tr>}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            {/* Objectives */}
            <div className="panel panel-default">
              <div className="panel-heading">
                <h4 className="panel-title" style={panelStyles.heading}>
                  <a /* data-toggle="collapse" href="#collapse13" */ className={`${isCollapsed[13] ? "collapsed" : ""}`} onClick={() => handleToggle(13)}>
                    <i className={`chevron-icon fa fa-chevron-${isCollapsed[13] ? "right" : "down"}`}></i> Objectives {noObjectiveItems && `(${noObjectiveItems.length})`} - <small>All items that do currently not have any objectives.</small></a>
                </h4>
              </div>
              <div id="collapse13" className={`panel-collapse collapse ${!isCollapsed[13] ? "in" : ""}`}>
                <div className="panel-body">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th className="th-name">Name</th>
                          <th className="th-list">List</th>
                          <th className="th-tags">Tags</th>
                          <th className="th-actions"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {noObjectiveItems && noObjectiveItems.length > 0 ? noObjectiveItems.map((citem, index) => {
                          const item = { ...citem, type: citem.type };
                          let hasOwner = OwnerExist(item, item.type.charAt(0).toUpperCase() + item.type.slice(1));
                          let isExternal = IsOwnerExternal(item, item.type.charAt(0).toUpperCase() + item.type.slice(1));
                          return (
                            <tr>
                              <td className="td-name">{item.cardName}</td>
                              <td className="td-list">{item.list}</td>
                              <td className="td-pills">{item.styledTags}</td>
                              <td className="actions">
                                <div>
                                  <a href="#" onClick={() => { handleModalOpen(item) }}><img src="/images/link.svg" /></a>
                                </div>
                              </td>
                            </tr>
                          );
                        }) : <tr><td colSpan="4">No items found</td></tr>}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div className="row campfire-footer">
        <div className="col-md-12">
          <div className="tracking-main">
            <div className="tracking-part">
              <h2>More work</h2>
              <p>Add anything that is not defined, yet, but needed looking forward.</p>

              <div className="action-buttons">
                <button type="button" className="primary yellow" onClick={() => { setPepoles() }}><img src="/images/listicons/person.svg" />Person +</button>
                <button type="button" className="primary blue" onClick={() => { setSkills() }}><img src="/images/listicons/skill.svg" />Skill +</button>
                <button type="button" className="primary blue" onClick={() => { setRoles() }}><img src="/images/listicons/role.svg" />Role +</button>
                <button type="button" className="primary blue" onClick={() => { setDomains() }}><img src="/images/listicons/domain.svg" />Domain +</button>
                <button type="button" className="primary blue" onClick={() => { setLinks() }}><img src="/images/listicons/link.svg" />Link +</button>
                <button type="button" className="primary green" onClick={() => { setCircles() }}><img src="/images/listicons/circle.svg" />Circle +</button>
                <button type="button" className="primary green" onClick={() => { setProjects() }}><img src="/images/listicons/project.svg" />Project +</button>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}