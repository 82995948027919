import React, { useEffect, useRef } from 'react'
import TextareaAutosize from "react-textarea-autosize";
import useRestoreScroll from '../../../HelperFunctions/useRestoreScroll';
export default function FCLinksList({ tagFilteredMegedData, handleAliesChange, GetAliasesName, teams, CcorfoLinks, CextfoLinks, clickedOnList, toggleEXTnNOCards, getSortIcon, isInfos, pCardHideOnchange, note, handleSave, noteSaveData, cFLonDrop, cFLonDragOver, isTag, isEXT, isNO, TagWiseSorting, tagFilteredObj, extNoFilteredObj, IsOwnerExternal, isPFCircleAddL, cFLonDrag, setLink, OwnerExist, toggleTagCards, cFlinkSub, fcLref, cfLinksSearch, setCfLinksSearch, filteredCFLData, OverlayTrigger, list, popover, cFPeopleHandel, lFromState, linkSubmit, lref, handleLinkChange, setLinks, setLinkName, linksFrom, link }) {
    
    const FCLinksListRef = useRef(null);
    useRestoreScroll(FCLinksListRef, "Link", filteredCFLData);

    return (
        <div
            className={
                !clickedOnList.listName.includes("link") && Object.keys(tagFilteredMegedData).length > 0 &&
                    tagFilteredMegedData.link &&
                    tagFilteredMegedData.link.length === 0
                    ? "board-col hide"
                    : "board-col"
            }
            data-board_id="links"
        >
            <div className="list list-bg-blue" data-list_id="links">
                <div className="dropdown">
                    <h4 className="list-title">
                        <img alt="" src="images/icon-link.png" /> {/* {GetAliasesName(teams, "Links")} (
            <span>{}</span>){" "} */}
                        <span contentEditable={true}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault(); // Prevent new line
                                }
                            }} data-name="links" onBlur={(e) => handleAliesChange(e)} suppressContentEditableWarning={true}>{GetAliasesName(teams, "Links")}</span>
                        <span>{` (${CcorfoLinks.length + CextfoLinks.length})`}</span>
                        <a
                            className={
                                clickedOnList.isClicked &&
                                    clickedOnList.listName.includes("link")
                                    ? "list-menu refresh-list"
                                    : "list-menu refresh-list hide"
                            }
                            style={{cursor: "pointer"}}
                            onClick={() => {
                                toggleEXTnNOCards("links", "", true, "list");
                            }}
                        >
                            <i className="fas fa-sync-alt"></i>
                        </a>{" "}
                        <a
                            className={
                                clickedOnList.isClicked &&
                                    clickedOnList.listName.includes("link")
                                    ? "list-menu dropdown-toggle hide"
                                    : "list-menu dropdown-toggle"
                            }
                            data-toggle="dropdown"
                            style={{cursor: "pointer"}}
                        >
                            {getSortIcon("Links")}
                            <i className="fas fa-ellipsis-v"></i>
                        </a>
                        <ul className="dropdown-menu dropdown-menu-right" role="menu">
                            <li role="presentation">
                                <a
                                    onClick={() => isInfos("Links")}
                                    role="menuitem"
                                    className="mytpt-list-info"
                                >
                                    Info
                                </a>
                            </li>
                            <li role="presentation">
                                <a
                                    role="menuitem"
                                    data-toggle="modal"
                                    data-target="#linksModal"
                                >
                                    Add new {GetAliasesName(teams, "Links").toLowerCase()}
                                </a>
                            </li>
                            <li role="presentation">
                                <a
                                    role="menuitem"
                                    onClick={() => pCardHideOnchange("Links")}
                                    className="hide_list"
                                >
                                    Hide list
                                </a>
                            </li>
                            <li role="presentation" className="divider"></li>
                            <li
                                role="presentation"
                                onClick={() => {
                                    toggleEXTnNOCards("links", "EXT", false);
                                }}
                            >
                                <a role="menuitem" className="show-external">
                                    Show items owned by external
                                </a>
                            </li>
                            <li
                                role="presentation"
                                onClick={() => {
                                    toggleEXTnNOCards("links", "NO", false);
                                }}
                            >
                                <a role="menuitem" className="show-noowners">
                                    Show items without owners
                                </a>
                            </li>
                        </ul>
                    </h4>
                </div>

                <div className="card-list scrollbar"
                    ref={FCLinksListRef}
                    onScroll={(e) => localStorage.setItem('scrollTopLink', e.target.scrollTop)}>
                    <div
                        className="form-group notes"
                        style={note.Links ? { display: "block" } : { display: "none" }}
                    >
                        <TextareaAutosize
                            className="notes"
                            name="Links"
                            // defaultValue=''
                            // value={noteSaveData.Links}
                            //   onChange={(e) => handleSave(e)}
                            onBlur={(e) => handleSave(e)}
                            placeholder="Notes"
                            // maxLength="100"
                            defaultValue={noteSaveData.Links}
                            minRows={3} // Set the minimum number of rows
                        />
                    </div>

                    <h6 style={{ borderBottom: "1px solid #000" }}>Core members</h6>
                    <div className="panel panel-default">
                        <div
                            onDrop={() => cFLonDrop("cFlCore")}
                            onDragOver={(event) => cFLonDragOver(event)}
                            id="core"
                            className="panel-body box-container"
                        >
                            {/* Core members listed here*/}
                            {CcorfoLinks && CcorfoLinks.length > 0
                                ? CcorfoLinks.map((e, index) => {
                                    if (isTag || isEXT || isNO) {
                                        let Proceed = TagWiseSorting(
                                            e,
                                            isTag,
                                            isEXT,
                                            isNO,
                                            tagFilteredObj,
                                            extNoFilteredObj,
                                            "link"
                                        );
                                        if (!Proceed) return;
                                    }
                                    let isExternal = IsOwnerExternal(e, "Link");
                                    let isPFCircle = isPFCircleAddL(e._id, "core");
                                    return (
                                        <div
                                            // onDragStart={(e) => linksDragStart(e, index)}
                                            // onDragEnter={(e) => linksDragEnter(e, index)}
                                            // onDragEnd={linksDrop}
                                            onDrag={() => cFLonDrag(e, "cFlCore", isPFCircle)}
                                            draggable
                                            key={index}
                                            className="card"
                                            data-list_id="links"
                                            data-card_id={e._id}
                                            onClick={(event) => {
                                                if (
                                                    event.target.classList.contains("card") ||
                                                    event.target.classList.contains("card-edit")
                                                ) {
                                                    setLink(e, CcorfoLinks, index);
                                                }
                                            }}
                                        >
                                            <div className="static-relations">
                                                {isExternal ? (
                                                    <span
                                                        onClick={() => {
                                                            toggleEXTnNOCards(e._id, "EXT", false, "pill");
                                                        }}
                                                        className="custom-badge blue ext-owner-pill"
                                                    >
                                                        EXT
                                                    </span>
                                                ) : null}
                                                {e.owner &&
                                                    e.owner.length > 0 &&
                                                    OwnerExist([e.owner]) ? null : (
                                                    <span
                                                        onClick={() => {
                                                            toggleEXTnNOCards(e._id, "NO", false, "pill");
                                                        }}
                                                        className="custom-badge no-owner-pill red"
                                                    >
                                                        NO
                                                    </span>
                                                )}
                                                {e.tags && e.tags.length > 0
                                                    ? e.tags.map((r, index) => (
                                                        <span
                                                            onClick={() => toggleTagCards(r)}
                                                            key={index}
                                                            className="custom-badge green tags"
                                                        >
                                                            {r}
                                                        </span>
                                                    ))
                                                    : null}
                                            </div>
                                            <a
                                                // onClick={() => setLink(e, CcorfoLinks, index)}
                                                className="card-edit"
                                            >
                                                {e.linkName}
                                            </a>
                                            <a
                                                className="card-unlink"
                                                style={{ display: "block", color: "red" }}
                                            >
                                                <i
                                                    onClick={() => cFlinkSub(e, "core")}
                                                    className="icon far fa-times-circle"
                                                ></i>
                                            </a>
                                        </div>
                                    );
                                })
                                : null}
                        </div>
                    </div>

                    <h6 style={{ borderBottom: "1px solid #000" }}>Extended members</h6>
                    <div className="panel panel-default">
                        <div
                            onDrop={() => cFLonDrop("cFlExtend")}
                            onDragOver={(event) => cFLonDragOver(event)}
                            id="extended"
                            className="panel-body box-container"
                        >
                            {/* Extended members listed here*/}
                            {CextfoLinks && CextfoLinks.length > 0
                                ? CextfoLinks.map((e, index) => {
                                    if (isTag || isEXT || isNO) {
                                        let Proceed = TagWiseSorting(
                                            e,
                                            isTag,
                                            isEXT,
                                            isNO,
                                            tagFilteredObj,
                                            extNoFilteredObj,
                                            "link"
                                        );
                                        if (!Proceed) return;
                                    }
                                    let isExternal = IsOwnerExternal(e, "Link");
                                    let isPFCircle = isPFCircleAddL(e._id, "extended");
                                    return (
                                        <div
                                            // onDragStart={(e) => linksDragStart(e, index)}
                                            // onDragEnter={(e) => linksDragEnter(e, index)}
                                            // onDragEnd={linksDrop}
                                            onDrag={() => cFLonDrag(e, "cFlExtend", isPFCircle)}
                                            draggable
                                            key={index}
                                            className="card "
                                            data-list_id="links"
                                            data-card_id={e._id}
                                            onClick={(event) => {
                                                if (
                                                    event.target.classList.contains("card") ||
                                                    event.target.classList.contains("card-edit")
                                                ) {
                                                    setLink(e, CextfoLinks, index);
                                                }
                                            }}
                                        >
                                            <div className="static-relations">
                                                {isExternal ? (
                                                    <span
                                                        onClick={() => {
                                                            toggleEXTnNOCards(e._id, "EXT", false, "pill");
                                                        }}
                                                        className="custom-badge blue ext-owner-pill"
                                                    >
                                                        EXT
                                                    </span>
                                                ) : null}
                                                {e.owner &&
                                                    e.owner.length > 0 &&
                                                    OwnerExist([e.owner]) ? null : (
                                                    <span
                                                        onClick={() => {
                                                            toggleEXTnNOCards(e._id, "NO", false, "pill");
                                                        }}
                                                        className="custom-badge no-owner-pill red"
                                                    >
                                                        NO
                                                    </span>
                                                )}
                                                {e.tags && e.tags.length > 0
                                                    ? e.tags.map((r, index) => (
                                                        <span
                                                            onClick={() => toggleTagCards(r)}
                                                            key={index}
                                                            className="custom-badge green tags"
                                                        >
                                                            {r}
                                                        </span>
                                                    ))
                                                    : null}
                                            </div>
                                            <a
                                                // onClick={() => setLink(e, CextfoLinks, index)}
                                                className="card-edit"
                                            >
                                                {e.linkName}
                                            </a>
                                            <a
                                                className="card-unlink"
                                                style={{ display: "block", color: "red" }}
                                            >
                                                <i
                                                    onClick={() => cFlinkSub(e, "extend")}
                                                    className="icon far fa-times-circle"
                                                ></i>
                                            </a>
                                        </div>
                                    );
                                })
                                : null}
                        </div>
                    </div>

                    <span className="clearable2">
                        <input
                            type="text"
                            ref={fcLref}
                            style={{ color: "black" }}
                            value={cfLinksSearch}
                            onChange={(e) => setCfLinksSearch(e.target.value)}
                            className="form-control search_list2 transparent"
                            name="search_list"
                            placeholder="Search to select more"
                            autoComplete="off"
                        />
                        {cfLinksSearch.length > 0 ? (
                            <i
                                className="clearable__clear"
                                onClick={() => setCfLinksSearch("")}
                                style={{ display: "inline", color: "black" }}
                            >
                                ×
                            </i>
                        ) : null}
                    </span>

                    <div className="panel panel-default">
                        <div
                            onDrop={() => cFLonDrop("cFlDefult")}
                            onDragOver={(event) => cFLonDragOver(event)}
                            id="notmatched"
                            className="panel-body box-container"
                        >
                            {filteredCFLData && filteredCFLData.length > 0
                                ? filteredCFLData.map((e, index) => {
                                    if (isTag || isEXT || isNO) {
                                        let Proceed = TagWiseSorting(
                                            e,
                                            isTag,
                                            isEXT,
                                            isNO,
                                            tagFilteredObj,
                                            extNoFilteredObj,
                                            "link"
                                        );
                                        if (!Proceed) return;
                                    }
                                    let isExternal = IsOwnerExternal(e, "Link");
                                    return (
                                        <div
                                            // onDragStart={(e) => linksDragStart(e, index)}
                                            // onDragEnter={(e) => linksDragEnter(e, index)}
                                            // onDragEnd={linksDrop}
                                            onDrag={() => cFLonDrag(e, "cFlDefult")}
                                            draggable
                                            key={index}
                                            className={`card notmatched ${localStorage.getItem("latest-created") === e._id
                                                ? " latest-created "
                                                : ""
                                                }`}
                                            data-list_id="links"
                                            onClick={(event) => {
                                                if (
                                                    event.target.classList.contains("card") ||
                                                    event.target.classList.contains("card-edit")
                                                ) {
                                                    setLink(e, filteredCFLData, index);
                                                }
                                            }}
                                        >
                                            <div className="static-relations">
                                                {isExternal ? (
                                                    <span
                                                        onClick={() => {
                                                            toggleEXTnNOCards(e._id, "EXT", false, "pill");
                                                        }}
                                                        className="custom-badge blue ext-owner-pill"
                                                    >
                                                        EXT
                                                    </span>
                                                ) : null}
                                                {e.owner &&
                                                    e.owner.length > 0 &&
                                                    OwnerExist([e.owner]) ? null : (
                                                    <span
                                                        onClick={() => {
                                                            toggleEXTnNOCards(e._id, "NO", false, "pill");
                                                        }}
                                                        className="custom-badge no-owner-pill red"
                                                    >
                                                        NO
                                                    </span>
                                                )}
                                                {e.tags && e.tags.length > 0
                                                    ? e.tags.map((r, index) => (
                                                        <span
                                                            onClick={() => toggleTagCards(r)}
                                                            key={index}
                                                            className="custom-badge green tags"
                                                        >
                                                            {r}
                                                        </span>
                                                    ))
                                                    : null}
                                            </div>
                                            <a
                                                className="card-edit"
                                            // onClick={() => setLink(e, filteredCFLData, index)}
                                            >
                                                {e.linkName}
                                            </a>

                                            <OverlayTrigger
                                                trigger="click"
                                                placement={
                                                    list[list.length - 1] === "Links" ? "left" : "right"
                                                }
                                                overlay={popover}
                                            >
                                                <a
                                                    className="card-link"
                                                    variant="success"
                                                    style={{ display: "block" }}
                                                >
                                                    <i
                                                        onClick={() => cFPeopleHandel(e, "Links")}
                                                        className="icon fa fa-plus-circle"
                                                    ></i>
                                                </a>
                                            </OverlayTrigger>
                                        </div>
                                    );
                                })
                                : null}
                        </div>
                    </div>

                    <div
                        className="card2"
                        style={
                            lFromState.Links ? { display: "block" } : { display: "none" }
                        }
                    >
                        <form className="form-new-card" role="form" onSubmit={linkSubmit}>
                            <div className="form-group">
                                <input
                                    type="text"
                                    ref={lref}
                                    name="card_name"
                                    required
                                    placeholder={`Add ${GetAliasesName(teams, "Links").toLowerCase()} name`}
                                    className="form-control"
                                    value={link}
                                    onChange={handleLinkChange}
                                    autoComplete="off"
                                />
                            </div>
                            <div className="form-group" style={{ marginTop: "10px" }}>
                                <button type="submit" className="btn btn-mytpt add-quick">
                                    Save
                                </button>
                                <a
                                    style={{cursor: "pointer"}}
                                    type="button"
                                    onClick={() => {
                                        setLinks({ linkName: link });
                                        setLinkName("");
                                        lFromState.Links = false;
                                    }}
                                    className="btn btn-link underline open-tool"
                                >
                                    Edit details
                                </a>
                                <a
                                    style={{cursor: "pointer"}}
                                    onClick={() => linksFrom()}
                                    className="btn btn-link underline close-add-card"
                                >
                                    Close
                                </a>
                            </div>
                        </form>
                    </div>
                </div>
                <a
                    className="btn-list add-card"
                    onClick={() => linksFrom()}
                    data-list_id="links"
                    style={!lFromState.Links ? { display: "block" } : { display: "none" }}
                >
                    + Add new {/*link */} {GetAliasesName(teams, "Links").toLowerCase()}
                </a>
            </div>
        </div>
    )
}
