import React, { useEffect, useState } from 'react'
import TextareaAutosize from "react-textarea-autosize";
import { useDispatch, } from "react-redux";
import { createFeedback } from '../store/actions/feedbackAction';

const centeredPlaceholderStyle = {
    lineHeight: "3rem",
    height: "2rem",
    padding: "5px",
    resize: "none"
};

export default function Feedback({ teamId, cardId, peopleId, feedbackData }) {
    // console.log({ teamId, cardId, peopleId, feedbackData });

    const dispatch = useDispatch();
    const [feedback, setFeedback] = useState("");
    const [showButton, setShowButton] = useState(false);

    const handleChange = (e) => {
        setFeedback(e.target.value);
    };

    const handleBlur = () => {
        if (feedback.trim() === "") return; // Prevent empty feedback submissions

        const feedbackObject = { teamId: localStorage.getItem('teamId'), cardId, peopleId, feedback };

        dispatch(createFeedback(feedbackObject));
        setFeedback(""); // Clear textarea after submission
        setShowButton(false)
    };

    const formatOptions = { year: 'numeric', month: 'short', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: true };

    const filteredFeedback = feedbackData ? feedbackData.filter(fb => fb.cardId === cardId) : [];

    return (
        <div>
            {peopleId &&
                <div style={{ marginBottom: "15px" }}>
                    <TextareaAutosize
                        name="feedback"
                        value={feedback}
                        // onBlur={handleBlur}
                        onChange={handleChange}
                        className="form-control"
                        placeholder="Please leave your feedback here..."
                        style={centeredPlaceholderStyle}
                        minRows={2}
                        onMouseDown={() => setShowButton(true)}
                    />
                    {showButton &&
                        <div style={{ marginTop: "15px" }}>
                            <button
                                type="button"
                                className="btn btn-mytpt save-close"
                                onClick={handleBlur}
                            >
                                <i className="fa fa-check"></i>
                            </button>

                            <button
                                type="button"
                                className="btn save-close"
                                onClick={() => setShowButton(false)}
                            >
                                <i className="fa fa-times"></i>
                            </button>
                        </div>
                    }
                </div>
            }
            <div style={{ marginTop: "15px", borderTop: "1px solid #eee" }}>
                <h5>All open feedback for this item:</h5>
                {filteredFeedback.length > 0 ? filteredFeedback.map((e, index) => {
                    const createdAt = new Date(e.createdAt);
                    const formattedDate = createdAt.toLocaleString('en-US', formatOptions);
                    return <p key={index}><i>{formattedDate}</i> - {e.feedback}</p>;
                }) : (
                    <p>{peopleId ? "Be the first to leave feedback." : "No feedback left"}</p>
                )}
            </div>
        </div>
    );
}
