import React, { useEffect, useRef } from 'react'
import TextareaAutosize from "react-textarea-autosize";
import useRestoreScroll from '../../../HelperFunctions/useRestoreScroll';
export default function FCDomainsList({ tagFilteredMegedData, handleAliesChange, GetAliasesName, teams, CcorfoDomains, CextfoDomains, clickedOnList, toggleEXTnNOCards, getSortIcon, isInfos, pCardHideOnchange, note, handleSave, noteSaveData, cFDonDrop, cFDonDragOver, isTag, isEXT, isNO, TagWiseSorting, IsOwnerExternal, isPFCircleAddD, OwnerExist, toggleTagCards, lFromState, OverlayTrigger, popover, tagFilteredObj, extNoFilteredObj, dWhoIsOwnerType, cfRAnfdSDomain, curCoreMembers, setDomain, filteredCFDData, domainSubmit, domain, handleDomainChange, setDomains, setDomainName, domainsFrom, fcDref, cfDomainsSearch, setCfDomainsSearch, cFPeopleHandel, cardOpensCfDomain, cFdomainSub, cfRAnfdSExDomain, curExtendedMembers, list, dref, cFDonDrag }) {
    
    const FCDomainsListRef = useRef(null);
    useRestoreScroll(FCDomainsListRef, "Domain", filteredCFDData);

    return (
        <div
            className={
                !clickedOnList.listName.includes("domain") && Object.keys(tagFilteredMegedData).length > 0 &&
                    tagFilteredMegedData.domain &&
                    tagFilteredMegedData.domain.length === 0
                    ? "board-col hide"
                    : "board-col"
            }
            data-board_id="domains"
        >
            <div className="list list-bg-blue" data-list_id="domains">
                <div className="dropdown">
                    <h4 className="list-title">
                        <img alt="" src="images/icon-domain.png" /> {/* {GetAliasesName(teams, "Domains")} (
            <span>{}</span>){" "} */}
                        <span contentEditable={true}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault(); // Prevent new line
                                }
                            }} data-name="domains" onBlur={(e) => handleAliesChange(e)} suppressContentEditableWarning={true}>{GetAliasesName(teams, "Domains")}</span>
                        <span>{` (${CcorfoDomains.length + CextfoDomains.length})`}</span>{" "}
                        <a
                            className={
                                clickedOnList.isClicked &&
                                    clickedOnList.listName.includes("domain")
                                    ? "list-menu refresh-list"
                                    : "list-menu refresh-list hide"
                            }
                            style={{cursor: "pointer"}}
                            onClick={() => {
                                toggleEXTnNOCards("domains", "", true, "list");
                            }}
                        >
                            <i className="fas fa-sync-alt"></i>
                        </a>{" "}
                        <a
                            className={
                                clickedOnList.isClicked &&
                                    clickedOnList.listName.includes("domain")
                                    ? "list-menu dropdown-toggle hide"
                                    : "list-menu dropdown-toggle"
                            }
                            data-toggle="dropdown"
                            style={{cursor: "pointer"}}
                        >
                            {getSortIcon("Domains")}
                            <i className="fas fa-ellipsis-v"></i>
                        </a>
                        <ul className="dropdown-menu dropdown-menu-right" role="menu">
                            <li role="presentation">
                                <a
                                    onClick={() => isInfos("Domains")}
                                    role="menuitem"
                                    className="mytpt-list-info"
                                >
                                    Info
                                </a>
                            </li>
                            <li role="presentation">
                                <a
                                    role="menuitem"
                                    data-toggle="modal"
                                    data-target="#domainModal"
                                >
                                    Add new {GetAliasesName(teams, "Domains").toLowerCase()}
                                </a>
                            </li>

                            <li role="presentation">
                                <a
                                    role="menuitem"
                                    onClick={() => pCardHideOnchange("Domains")}
                                    className="hide_list"
                                >
                                    Hide list
                                </a>
                            </li>
                            <li role="presentation" className="divider"></li>
                            <li
                                role="presentation"
                                onClick={() => {
                                    toggleEXTnNOCards("domains", "EXT", false);
                                }}
                            >
                                <a role="menuitem" className="show-external">
                                    Show items owned by external
                                </a>
                            </li>
                            <li
                                role="presentation"
                                onClick={() => {
                                    toggleEXTnNOCards("domains", "NO", false);
                                }}
                            >
                                <a role="menuitem" className="show-noowners">
                                    Show items without owners
                                </a>
                            </li>
                        </ul>
                    </h4>
                </div>

                <div className="card-list scrollbar"
                    ref={FCDomainsListRef}
                    onScroll={(e) => localStorage.setItem('scrollTopDomain', e.target.scrollTop)}
                >
                    <div
                        className="form-group notes"
                        style={note.Domains ? { display: "block" } : { display: "none" }}
                    >
                        <TextareaAutosize
                            className="notes"
                            name="Domains"
                            // defaultValue=''
                            // value={noteSaveData.Domains}
                            //   onChange={(e) => handleSave(e)}
                            onBlur={(e) => handleSave(e)}
                            placeholder="Notes"
                            // maxLength="100"
                            defaultValue={noteSaveData.Domains}
                            minRows={3} // Set the minimum number of rows
                        />
                    </div>

                    <h6 style={{ borderBottom: "1px solid #000" }}>Core members</h6>
                    <div className="panel panel-default">
                        <div
                            onDrop={() => cFDonDrop("cFdCore")}
                            onDragOver={(event) => cFDonDragOver(event)}
                            id="core"
                            className="panel-body box-container"
                        >
                            {/* Core members listed here*/}
                            {CcorfoDomains && CcorfoDomains.length > 0
                                ? CcorfoDomains.map((e, index) => {
                                    if (isTag || isEXT || isNO) {
                                        let Proceed = TagWiseSorting(
                                            e,
                                            isTag,
                                            isEXT,
                                            isNO,
                                            tagFilteredObj,
                                            extNoFilteredObj,
                                            "domain"
                                        );
                                        if (!Proceed) return;
                                    }
                                    let isExternal = IsOwnerExternal(e, "Domain");
                                    let whoIsOwnerType = dWhoIsOwnerType(e);
                                    // console.log('my ', e.domainName, whoIsOwnerType, Object.keys(whoIsOwnerType).length)
                                    let isPFCircle = isPFCircleAddD(e._id, "core");
                                    let curPills = [];
                                    if (cfRAnfdSDomain && cfRAnfdSDomain.length > 0) {
                                        for (let i = 0; i < cfRAnfdSDomain.length; i++) {
                                            // console.log('km ', cfRAnfdSDomain[i], e.domainName)
                                            if (
                                                cfRAnfdSDomain[i].domainId === e._id &&
                                                Object.keys(whoIsOwnerType).length &&
                                                e._id !== whoIsOwnerType.cardId
                                            ) {
                                                curPills.push(cfRAnfdSDomain[i]);
                                            }
                                        }
                                    }

                                    let inc = curCoreMembers.Domains.includes(e._id);
                                    if (!inc) return;

                                    return (
                                        <div
                                            // onDragStart={(e) => domainsDragStart(e, index)}
                                            // onDragEnter={(e) => domainsDragEnter(e, index)}
                                            // onDragEnd={domainsDrop}
                                            onDrag={() => cFDonDrag(e, "cFdCore", isPFCircle)}
                                            /* draggable={
                                        curPills && curPills.length > 0 ? false : true
                                      } */
                                            draggable
                                            key={index}
                                            className="card "
                                            data-list_id="domain"
                                            data-card_id={e._id}
                                            onClick={(event) => {
                                                if (
                                                    event.target.classList.contains("card") ||
                                                    event.target.classList.contains("card-edit")
                                                ) {
                                                    setDomain(e, CcorfoDomains, index);
                                                }
                                            }}
                                        >
                                            <div className="static-relations">
                                                {isExternal ? (
                                                    <span
                                                        onClick={() => {
                                                            toggleEXTnNOCards(e._id, "EXT", false, "pill");
                                                        }}
                                                        className="custom-badge blue ext-owner-pill"
                                                    >
                                                        EXT
                                                    </span>
                                                ) : null}
                                                {e.owners &&
                                                    e.owners.owner &&
                                                    e.owners.owner.length > 0 &&
                                                    OwnerExist([e.owners.owner]) ? null : (
                                                    <span
                                                        onClick={() => {
                                                            toggleEXTnNOCards(e._id, "NO", false, "pill");
                                                        }}
                                                        className="custom-badge no-owner-pill red"
                                                    >
                                                        NO
                                                    </span>
                                                )}
                                                {e.tags && e.tags.length > 0
                                                    ? e.tags.map((r, index) => (
                                                        <span
                                                            onClick={() => toggleTagCards(r)}
                                                            key={index}
                                                            className="custom-badge green tags"
                                                        >
                                                            {r}
                                                        </span>
                                                    ))
                                                    : null}

                                                {curPills && curPills.length > 0 ? (
                                                    curPills.map((r, index) => (
                                                        <span
                                                            onClick={() => cardOpensCfDomain(r)}
                                                            key={index}
                                                            className="custom-badge lightgray"
                                                        >
                                                            {`${r.cardName} (${GetAliasesName(teams, `${r.type}`)})`}
                                                        </span>
                                                    ))
                                                ) : whoIsOwnerType &&
                                                    Object.keys(whoIsOwnerType).length > 0 ? (
                                                    <span
                                                        onClick={() => cardOpensCfDomain(whoIsOwnerType)}
                                                        className="custom-badge lightgray"
                                                    >
                                                        {whoIsOwnerType.name}
                                                    </span>
                                                ) : null}
                                            </div>
                                            <a
                                                // onClick={() => setDomain(e, CcorfoDomains, index)}
                                                className="card-edit"
                                            >
                                                {e.domainName}
                                            </a>
                                            {/* {curPills.length === 0 ? ( */}
                                            <a
                                                className="card-unlink"
                                                style={{ display: "block", color: "red" }}
                                            >
                                                <i
                                                    onClick={() => cFdomainSub(e, "core")}
                                                    className="icon far fa-times-circle"
                                                ></i>
                                            </a>
                                            {/* ) : null} */}
                                        </div>
                                    );
                                })
                                : null}
                        </div>
                    </div>

                    <h6 style={{ borderBottom: "1px solid #000" }}>Extended members</h6>
                    <div className="panel panel-default">
                        <div
                            onDrop={() => cFDonDrop("cFdExtend")}
                            onDragOver={(event) => cFDonDragOver(event)}
                            id="extended"
                            className="panel-body box-container"
                        >
                            {/* Extended members listed here*/}
                            {CextfoDomains && CextfoDomains.length > 0
                                ? CextfoDomains.map((e, index) => {
                                    if (isTag || isEXT || isNO) {
                                        let Proceed = TagWiseSorting(
                                            e,
                                            isTag,
                                            isEXT,
                                            isNO,
                                            tagFilteredObj,
                                            extNoFilteredObj,
                                            "domain"
                                        );
                                        if (!Proceed) return;
                                    }
                                    let isExternal = IsOwnerExternal(e, "Domain");
                                    let whoIsOwnerType = dWhoIsOwnerType(e);
                                    let isPFCircle = isPFCircleAddD(e._id, "extended");
                                    let curPills = [];
                                    if (cfRAnfdSExDomain && cfRAnfdSExDomain.length > 0) {
                                        for (let i = 0; i < cfRAnfdSExDomain.length; i++) {
                                            if (
                                                cfRAnfdSExDomain[i].domainId === e._id &&
                                                e._id !== whoIsOwnerType.cardId
                                            ) {
                                                curPills.push(cfRAnfdSExDomain[i]);
                                            }
                                        }
                                    }
                                    let inc = curExtendedMembers.Domains.includes(e._id);
                                    if (!inc) return;

                                    return (
                                        <div
                                            // onDragStart={(e) => domainsDragStart(e, index)}
                                            // onDragEnter={(e) => domainsDragEnter(e, index)}
                                            // onDragEnd={domainsDrop}
                                            onDrag={() => cFDonDrag(e, "cFdExtend", isPFCircle)}
                                            draggable
                                            key={index}
                                            className="card"
                                            data-list_id="domain"
                                            data-card_id={e._id}
                                            onClick={(event) => {
                                                if (
                                                    event.target.classList.contains("card") ||
                                                    event.target.classList.contains("card-edit")
                                                ) {
                                                    setDomain(e, CextfoDomains, index);
                                                }
                                            }}
                                        >
                                            <div className="static-relations">
                                                {isExternal ? (
                                                    <span
                                                        onClick={() => {
                                                            toggleEXTnNOCards(e._id, "EXT", false, "pill");
                                                        }}
                                                        className="custom-badge blue ext-owner-pill"
                                                    >
                                                        EXT
                                                    </span>
                                                ) : null}
                                                {e.owners &&
                                                    e.owners.owner &&
                                                    e.owners.owner.length > 0 &&
                                                    OwnerExist([e.owners.owner]) ? null : (
                                                    <span
                                                        onClick={() => {
                                                            toggleEXTnNOCards(e._id, "NO", false, "pill");
                                                        }}
                                                        className="custom-badge no-owner-pill red"
                                                    >
                                                        NO
                                                    </span>
                                                )}
                                                {e.tags && e.tags.length > 0
                                                    ? e.tags.map((r, index) => (
                                                        <span
                                                            onClick={() => toggleTagCards(r)}
                                                            key={index}
                                                            className="custom-badge green tags"
                                                        >
                                                            {r}
                                                        </span>
                                                    ))
                                                    : null}
                                                {curPills && curPills.length > 0 ? (
                                                    curPills.map((r, index) => (
                                                        <span
                                                            onClick={() => cardOpensCfDomain(r)}
                                                            key={index}
                                                            className="custom-badge lightgray"
                                                        >
                                                            {`${r.cardName} (${GetAliasesName(teams, `${r.type}`)})`}
                                                        </span>
                                                    ))
                                                ) : whoIsOwnerType &&
                                                    Object.keys(whoIsOwnerType).length > 0 ? (
                                                    <span
                                                        onClick={() => cardOpensCfDomain(whoIsOwnerType)}
                                                        className="custom-badge lightgray"
                                                    >
                                                        {whoIsOwnerType.name}
                                                    </span>
                                                ) : null}
                                            </div>
                                            <a
                          /* onClick={() => setDomain(e, CextfoDomains, index)} */ className="card-edit"
                                            >
                                                {e.domainName}
                                            </a>
                                            {curPills.length === 0 ? (
                                                <a
                                                    className="card-unlink"
                                                    style={{ display: "block", color: "red" }}
                                                >
                                                    <i
                                                        onClick={() => cFdomainSub(e, "extend")}
                                                        className="icon far fa-times-circle"
                                                    ></i>
                                                </a>
                                            ) : null}
                                        </div>
                                    );
                                })
                                : null}
                        </div>
                    </div>

                    <span className="clearable2">
                        <input
                            type="text"
                            ref={fcDref}
                            style={{ color: "black" }}
                            value={cfDomainsSearch}
                            onChange={(e) => setCfDomainsSearch(e.target.value)}
                            className="form-control search_list2 transparent"
                            name="search_list"
                            placeholder="Search to select more"
                            autoComplete="off"
                        />
                        {cfDomainsSearch.length > 0 ? (
                            <i
                                className="clearable__clear"
                                onClick={() => setCfDomainsSearch("")}
                                style={{ display: "inline", color: "black" }}
                            >
                                ×
                            </i>
                        ) : null}
                    </span>

                    <div className="panel panel-default">
                        <div
                            onDrop={() => cFDonDrop("cFdDefult")}
                            onDragOver={(event) => cFDonDragOver(event)}
                            id="notmatched"
                            className="panel-body box-container"
                        >
                            {filteredCFDData && filteredCFDData.length > 0
                                ? filteredCFDData.map((e, index) => {
                                    if (isTag || isEXT || isNO) {
                                        let Proceed = TagWiseSorting(
                                            e,
                                            isTag,
                                            isEXT,
                                            isNO,
                                            tagFilteredObj,
                                            extNoFilteredObj,
                                            "domain"
                                        );
                                        if (!Proceed) return;
                                    }
                                    let isExternal = IsOwnerExternal(e, "Domain");
                                    let whoIsOwnerType = dWhoIsOwnerType(e);
                                    return (
                                        <div
                                            // onDragStart={(e) => domainsDragStart(e, index)}
                                            // onDragEnter={(e) => domainsDragEnter(e, index)}
                                            // onDragEnd={domainsDrop}
                                            onDrag={() => cFDonDrag(e, "cFdDefult")}
                                            // draggable={e.owners.type ? false : true}
                                            draggable
                                            key={index}
                                            className={`card notmatched ${localStorage.getItem("latest-created") === e._id
                                                ? " latest-created "
                                                : ""
                                                }`}
                                            data-list_id="domains"
                                            onClick={(event) => {
                                                if (
                                                    event.target.classList.contains("card") ||
                                                    event.target.classList.contains("card-edit")
                                                ) {
                                                    setDomain(e, filteredCFDData, index);
                                                }
                                            }}
                                        >
                                            <div className="static-relations">
                                                {isExternal ? (
                                                    <span
                                                        onClick={() => {
                                                            toggleEXTnNOCards(e._id, "EXT", false, "pill");
                                                        }}
                                                        className="custom-badge blue ext-owner-pill"
                                                    >
                                                        EXT
                                                    </span>
                                                ) : null}
                                                {e.owners &&
                                                    e.owners.owner &&
                                                    e.owners.owner.length > 0 &&
                                                    OwnerExist([e.owners.owner]) ? null : (
                                                    <span
                                                        onClick={() => {
                                                            toggleEXTnNOCards(e._id, "NO", false, "pill");
                                                        }}
                                                        className="custom-badge no-owner-pill red"
                                                    >
                                                        NO
                                                    </span>
                                                )}
                                                {e.tags && e.tags.length > 0
                                                    ? e.tags.map((r, index) => (
                                                        <span
                                                            onClick={() => toggleTagCards(r)}
                                                            key={index}
                                                            className="custom-badge green tags"
                                                        >
                                                            {r}
                                                        </span>
                                                    ))
                                                    : null}

                                                {whoIsOwnerType &&
                                                    Object.keys(whoIsOwnerType).length > 0 ? (
                                                    <span
                                                        // onClick={() => openSkillAndRoleByDPill(whoIsOwnerType)}
                                                        onClick={() => cardOpensCfDomain(whoIsOwnerType)}
                                                        className="custom-badge lightgray"
                                                    >
                                                        {whoIsOwnerType.name}
                                                    </span>
                                                ) : null}
                                            </div>

                                            <a
                                                className="card-edit" /* onClick={() => setDomain(e, filteredCFDData, index)} */
                                            >
                                                {e.domainName}
                                            </a>

                                            {/* {!e.owners.type ? */}
                                            <OverlayTrigger
                                                trigger="click"
                                                placement={
                                                    list[list.length - 1] === "Domains"
                                                        ? "left"
                                                        : "right"
                                                }
                                                overlay={popover}
                                            >
                                                <a
                                                    className="card-link"
                                                    variant="success"
                                                    style={{ display: "block" }}
                                                >
                                                    <i
                                                        onClick={() => cFPeopleHandel(e, "Domains")}
                                                        className="icon fa fa-plus-circle"
                                                    ></i>
                                                </a>
                                            </OverlayTrigger>
                                            {/* : null} */}
                                        </div>
                                    );
                                })
                                : null}
                        </div>
                    </div>

                    <div
                        className="card2"
                        style={
                            lFromState.Domains ? { display: "block" } : { display: "none" }
                        }
                    >
                        <form className="form-new-card" role="form" onSubmit={domainSubmit}>
                            <div className="form-group">
                                <input
                                    type="text"
                                    ref={dref}
                                    name="card_name"
                                    required
                                    placeholder={`Add ${GetAliasesName(teams, "Domains").toLowerCase()} name`}
                                    className="form-control"
                                    value={domain}
                                    onChange={handleDomainChange}
                                    autoComplete="off"
                                />
                            </div>
                            <div className="form-group" style={{ marginTop: "10px" }}>
                                <button type="submit" className="btn btn-mytpt add-quick">
                                    Save
                                </button>
                                <a
                                    style={{cursor: "pointer"}}
                                    type="button"
                                    onClick={() => {
                                        setDomains({ domainName: domain });
                                        setDomainName("");
                                        lFromState.Domains = false;
                                    }}
                                    className="btn btn-link underline open-tool"
                                >
                                    Edit details
                                </a>
                                <a
                                    style={{cursor: "pointer"}}
                                    onClick={() => domainsFrom()}
                                    className="btn btn-link underline close-add-card"
                                >
                                    Close
                                </a>
                            </div>
                        </form>
                    </div>
                </div>
                <a
                    className="btn-list add-card"
                    onClick={() => domainsFrom()}
                    data-list_id="domain"
                    style={
                        !lFromState.Domains ? { display: "block" } : { display: "none" }
                    }
                >
                    + Add new {/*domain */} {GetAliasesName(teams, "Domains").toLowerCase()}
                </a>
            </div>
        </div>
    )
}
