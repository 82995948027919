import React from 'react'

export default function CircleAsPeopleModeList({ peopleAsCDatas, cAndpADatas, cmLeadVerify, Get_ShowHideColumn_Project_Circle_Mode_AddedAs_people, tagFilteredMegedData, toggleEXTnNOCards, getSortIcon, setCircles, GetAliasesName, teams, cmHandelFocused, isTag, isEXT, isNO, TagWiseSorting, tagFilteredObj, extNoFilteredObj, toggleTagCards, cardOpens, setPepole, specialModeDataObj }) {
    const isInOwner = (f, p) => {
        let isOwner = false;
        if (Array.isArray(f.obj.owners)) {
            // Case 1: Array of ObjectIDs
            isOwner = f.obj.owners.some(o => o.toString() === p._id.toString());
        } else if (typeof f.obj.owners === "object" && f.obj.owners !== null) {
            // Case 2: Object with `type` and `owner` (owner is ObjectId)
            isOwner = f.obj.owners.owner.toString() === p._id.toString();
        } else if (f.obj.owner) {
            // Case 3: Single `owner` field (ObjectId)
            isOwner = f.obj.owner.toString() === p._id.toString();
        }

        // console.log("Is Owner:", isOwner);
        return isOwner;
    }

    return (
        <div className="wrap-lists" id="circle-mode">
            {peopleAsCDatas && peopleAsCDatas.length > 0
                ? peopleAsCDatas.map((e, index) => {
                    var meetingsData = [];
                    if (e.meetings && e.meetings.length > 0) {
                        meetingsData = cAndpADatas(e.meetings);
                    }

                    let cardData = {};
                    if (e.lead && e.lead.length > 0) {
                        cardData = cmLeadVerify(e);
                    }

                    let hideColumn =
                        Get_ShowHideColumn_Project_Circle_Mode_AddedAs_people(
                            e,
                            tagFilteredMegedData
                        );

                    const specificData = specialModeDataObj.find(
                        (item) => item.id === e._id
                    );

                    if (
                        tagFilteredMegedData &&
                        Object.keys(tagFilteredMegedData).length > 0 &&
                        hideColumn
                    )
                        return null;

                    return (
                        <div key={index} className="board-col board-col-circles">
                            <div className={e.defaultCircle ? "list default-circle list-bg-red" : "list list-bg-red"} data-list_id="circles">
                                <div className="dropdown">
                                    <h4 className="list-title">
                                        <img alt="" src="images/icon-circle.png" />
                                        {e.defaultCircle && e.circleName === "Team Circle" ? "Entire team" : e.circleName}

                                        <a
                                            className={
                                                specialModeDataObj.some((item) => item.id === e._id)
                                                    ? "list-menu refresh-list"
                                                    : "list-menu refresh-list hide"
                                            }
                                            // className="list-menu refresh-list hide"
                                            href="#"
                                            onClick={() => {
                                                toggleEXTnNOCards(
                                                    "",
                                                    "EXT",
                                                    true,
                                                    "list",
                                                    "CircleMode",
                                                    e._id
                                                );
                                            }}
                                        >
                                            <i className="fas fa-sync-alt"></i>
                                        </a>

                                        <a
                                            className={
                                                specialModeDataObj.some((item) => item.id === e._id)
                                                    ? "list-menu dropdown-toggle hide"
                                                    : "list-menu dropdown-toggle"
                                            }
                                            // className="list-menu dropdown-toggle"
                                            data-toggle="dropdown"
                                            href="#"
                                        >
                                            {getSortIcon("Circles")}
                                            <i className="fas fa-ellipsis-v"></i>
                                        </a>

                                        <ul
                                            className="dropdown-menu dropdown-menu-right"
                                            role="menu"
                                        >
                                            <li role="presentation" onClick={() => setCircles(e)}>
                                                <a
                                                    role="menuitem"
                                                    className="tool-from-mode"
                                                    data-list_id="circles"
                                                    data-card_name="Circle two"
                                                >
                                                    Edit this {/* circle */GetAliasesName(teams, "Circles")}
                                                </a>
                                            </li>
                                            {e.defaultCircle &&
                                                e.defaultCircle === true ? null : (
                                                <li
                                                    onClick={() => cmHandelFocused(index)}
                                                    role="presentation"
                                                >
                                                    <a
                                                        role="menuitem"
                                                        className="focus-from-mode"
                                                        data-list_id="circles"
                                                    >
                                                        Enter focus mode
                                                    </a>
                                                </li>
                                            )}
                                            <li role="presentation" className="divider"></li>
                                            <li
                                                role="presentation"
                                                onClick={() => {
                                                    // toggleEXTnNOCards(e._id, "EXT", false);
                                                    toggleEXTnNOCards(
                                                        "",
                                                        "EXT",
                                                        false,
                                                        "list",
                                                        "CircleAsPeopleMode",
                                                        e._id
                                                    );
                                                }}
                                            >
                                                <a role="menuitem" className="show-external">
                                                    Show items owned by external
                                                </a>
                                            </li>
                                            {/* <li
                                                role="presentation"
                                                onClick={() => {
                                                    // toggleEXTnNOCards(e._id, "NO", false);
                                                    toggleEXTnNOCards(
                                                        "",
                                                        "NO",
                                                        false,
                                                        "list",
                                                        "CircleAsPeopleMode",
                                                        e._id
                                                    );
                                                }}
                                            >
                                                <a role="menuitem" className="show-noowners">
                                                    Show items without owners
                                                </a>
                                            </li> */}
                                        </ul>
                                    </h4>
                                </div>

                                <div className="card-list only scrollbar">
                                    <div className="circle-tags">
                                        {e.tags && e.tags.length > 0
                                            ? e.tags.map((tag, tindex) => {
                                                return (
                                                    <span key={tindex} className="custom-badge green tags">
                                                        {tag}
                                                    </span>
                                                );
                                            })
                                            : null}
                                    </div>
                                    {e.pepoleData && e.pepoleData.length > 0
                                        ? e.pepoleData.map((p, indexs) => {
                                            if (specificData && (isEXT || isNO)) {
                                                if (
                                                    specificData.data.people &&
                                                    !specificData.data.people.includes(p._id)
                                                ) {
                                                    return;
                                                }
                                            } else {
                                                if (isTag || isEXT || isNO) {
                                                    let Proceed = TagWiseSorting(
                                                        p,
                                                        isTag,
                                                        isEXT,
                                                        isNO,
                                                        tagFilteredObj,
                                                        extNoFilteredObj,
                                                        "people"
                                                    );
                                                    if (!Proceed) return;
                                                }
                                            }
                                            let dLead = false;
                                            let udLead = false;
                                            if (
                                                cardData &&
                                                Object.keys(cardData).length > 0
                                            ) {
                                                if (
                                                    cardData.people &&
                                                    cardData.people.length > 0 &&
                                                    cardData.people === p._id
                                                ) {
                                                    if (
                                                        cardData.curType === "People" &&
                                                        cardData.rAndS === null
                                                    ) {
                                                        dLead = true;
                                                    } else if (
                                                        (cardData.curType === "Roles" ||
                                                            cardData.curType === "Skills") &&
                                                        cardData.rAndS
                                                    ) {
                                                        udLead = true;
                                                    }
                                                }
                                            }
                                            return (
                                                <div key={indexs} className="card">
                                                    {p.workRole === "External" ? (
                                                        <span
                                                            onClick={() => {
                                                                toggleEXTnNOCards(
                                                                    e._id,
                                                                    "EXT",
                                                                    false,
                                                                    "pill"
                                                                );
                                                            }}
                                                            className="custom-badge blue ext-owner-pill"
                                                        >
                                                            EXT
                                                        </span>
                                                    ) : null}

                                                    {dLead ? (
                                                        <span span className="custom-badge red-o direct-lead">
                                                            LEAD
                                                        </span>
                                                    ) : null}
                                                    {udLead ? (
                                                        <span className="custom-badge gray-o">
                                                            LEAD
                                                        </span>
                                                    ) : null}

                                                    {p.tags && p.tags.length > 0
                                                        ? p.tags.map((tag, tindex) => {
                                                            return (
                                                                <span
                                                                    key={tindex}
                                                                    onClick={() => toggleTagCards(tag)}
                                                                    className="custom-badge green tags"
                                                                >
                                                                    {/* {console.log(p.tags)} */}
                                                                    {tag}
                                                                </span>
                                                            );
                                                        })
                                                        : null}

                                                    {/* {p.FildName && p.FildName.length > 0
                                                        ? p.FildName.map((f, indexf) => {
                                                            if (f.obj.owners) {
                                                                let isOwner = isInOwner(f, p);
                                                                if(!isOwner)return;

                                                                return (
                                                                    <span
                                                                        key={indexf}
                                                                        className="custom-badge lightgray"
                                                                        onClick={() => cardOpens(f)}
                                                                    >
                                                                        {f.name}
                                                                    </span>
                                                                );
                                                            }
                                                        })
                                                        : null} */}


                                                    {p.FildName && p.FildName.length > 0
                                                        ? (() => {
                                                            const seenNames = new Set();
                                                            return p.FildName.map((f, indexf) => {
                                                                if (f.obj.owners) {
                                                                    let isOwner = isInOwner(f, p);
                                                                    if (!isOwner) return null;

                                                                    if (seenNames.has(f.name)) return null; // Skip duplicates

                                                                    seenNames.add(f.name);
                                                                    return (
                                                                        <span
                                                                            key={indexf}
                                                                            className="custom-badge lightgray"
                                                                            onClick={() => cardOpens(f)}
                                                                        >
                                                                            {f.name}
                                                                        </span>
                                                                    );
                                                                }
                                                                return null;
                                                            });
                                                        })()
                                                        : null}


                                                    <a
                                                        className="card-edit"
                                                        onClick={() => setPepole(p)}
                                                    >{`${p.fname} ${p.lname}`}</a>
                                                </div>
                                            );
                                        })
                                        : null}

                                    <h6
                                        style={{ borderBottom: "1px solid #000" }}
                                        className="meetings-heading"
                                    >
                                        Meetings
                                    </h6>
                                    <div className="panel panel-default">
                                        <div
                                            id="meetings"
                                            className="panel-body single box-container"
                                        >
                                            {meetingsData && meetingsData.length > 0
                                                ? meetingsData.map((m, indexm) => {
                                                    return (
                                                        <div className="card meeting-card ">
                                                            <div className="meeting-content">
                                                                <p>
                                                                    <span className="fas fa-users"></span>
                                                                    <b style={{ marginLeft: "5px" }}>
                                                                        {m.meetingsName}
                                                                    </b>
                                                                </p>
                                                                <p>{m.meetingsPurpose}</p>
                                                                {m.recurrenceType === "As needed" ||
                                                                    m.durationType === "As needed" ? (
                                                                    <>
                                                                        <p>
                                                                            {m.recurrenceType} {m.durationType}
                                                                        </p>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        {" "}
                                                                        {m.recurrence && (
                                                                            <p>
                                                                                Every {m.recurrence}{" "}
                                                                                {m.recurrenceType}{" "}
                                                                                {m.duration && (
                                                                                    <span>
                                                                                        for {m.duration}{" "}
                                                                                        {m.durationType}
                                                                                    </span>
                                                                                )}
                                                                            </p>
                                                                        )}
                                                                    </>
                                                                )}
                                                                <p>{m.XTD ? "incl. XTD" : null}</p>
                                                            </div>
                                                        </div>
                                                    );
                                                })
                                                : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })
                : null}
        </div >
    )
}
