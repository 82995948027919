import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { PEOPLE_SUCCESS_MESSAGE_CLEAR, PEOPLE_ERROR_CLEAR, PEOPLE_ID_CLEAR } from "../../store/types/peopleType";
import { SKILL_SUCCESS_MESSAGE_CLEAR, SKILL_ERROR_CLEAR, SKILL_ID_CLEAR, SKILLS_MODIFICATIONS } from "../../store/types/skillType";
import { ROLE_SUCCESS_MESSAGE_CLEAR, ROLE_ERROR_CLEAR, ROLE_ID_CLEAR, ROLES_MODIFICATIONS } from "../../store/types/roleType";
import { DOMAIN_SUCCESS_MESSAGE_CLEAR, DOMAIN_ERROR_CLEAR, DOMAIN_ID_CLEAR, DOMAINS_MODIFICATIONS } from "../../store/types/domainType";
import { LINK_SUCCESS_MESSAGE_CLEAR, LINK_ERROR_CLEAR, LINK_ID_CLEAR, LINKS_MODIFICATIONS } from "../../store/types/linkType";
import { CIRCLE_SUCCESS_MESSAGE_CLEAR, CIRCLE_ERROR_CLEAR, CIRCLE_ID_CLEAR } from "../../store/types/circleType";
import { PROJECT_SUCCESS_MESSAGE_CLEAR, PROJECT_ERROR_CLEAR, PROJECT_ID_CLEAR } from "../../store/types/projectType";

const useCustomEffects = () => {
    const { myInfo, loading, successMessage, paySuccessMessage, error, authenticate, curUserData, loginHistory, userMeta } = useSelector((state) => state.auth);
    const { teams, template, sMessage, errors } = useSelector((state) => state.teams);
    const { peoples, cuPeopleId, peopleMessagee, peopleError } = useSelector((state) => state.peoples);
    const { skills, cuSkillId, nDaoamins, skillsMessagee, skillError, skillsMessage } = useSelector((state) => state.skills);
    const { roles, cuRoleId, dRoles, roleMessagee, roleError } = useSelector((state) => state.roles);
    const { domains, cuDomainId, dDomains, domainMessage, domainMessagee, domainError } = useSelector((state) => state.domains);
    const { links, cuLinkId, linkMessagee, linkError } = useSelector((state) => state.links);
    const { circles, dCircles, cuCircleId, circleMessagee, circleError } = useSelector((state) => state.circles);
    const { projects, cuProjectId, projectMessagee, projectError } = useSelector((state) => state.projects);
    const { feedbackData, feedbackMessage, feedbackError } = useSelector((state) => state.feedbacks);

    const dispatch = useDispatch();

    useEffect(() => {
        if (peopleMessagee && peopleMessagee.length > 0) {
            dispatch({ type: PEOPLE_ID_CLEAR });
        }

        if (peopleError) {
            Swal.fire({
                title: "Error",
                showClass: { popup: "animate__animated animate__fadeInDown" },
                hideClass: { popup: "animate__animated animate__fadeOutUp" },
                text: peopleError,
            });
            dispatch({ type: PEOPLE_ERROR_CLEAR });
        }
    }, [peopleMessagee, peopleError, cuPeopleId, dispatch]);

    useEffect(() => {
        if (skillsMessagee && skillsMessagee.length > 0) {
            dispatch({ type: SKILL_ID_CLEAR });
            dispatch({ type: SKILL_SUCCESS_MESSAGE_CLEAR });
        }

        if (skillError) {
            Swal.fire({
                title: "Error",
                showClass: { popup: "animate__animated animate__fadeInDown" },
                hideClass: { popup: "animate__animated animate__fadeOutUp" },
                text: skillError,
            });
            dispatch({ type: SKILL_ERROR_CLEAR });
        }
    }, [skillsMessagee, skillError, cuSkillId, dispatch]);

    useEffect(() => {
        if (roleMessagee && roleMessagee.length > 0) {
            dispatch({ type: ROLE_ID_CLEAR });
            dispatch({ type: ROLE_SUCCESS_MESSAGE_CLEAR });
        }

        if (roleError) {
            Swal.fire({
                // icon: "error",
                title: "Error",
                showClass: {
                    popup: "animate_animated animate_fadeInDown",
                },
                hideClass: {
                    popup: "animate_animated animate_fadeOutUp",
                },
                text: roleError,
            });
            dispatch({ type: ROLE_ERROR_CLEAR });
        }
    }, [roleMessagee, roleError, cuRoleId]);

    useEffect(() => {
        if (domainMessagee) {
            dispatch({ type: DOMAIN_ID_CLEAR });
            dispatch({ type: DOMAIN_SUCCESS_MESSAGE_CLEAR });
        }

        if (domainError) {
            Swal.fire({
                // icon: "error",
                title: "Error",
                showClass: {
                    popup: "animate__animated animate__fadeInDown",
                },
                hideClass: {
                    popup: "animate__animated animate__fadeOutUp",
                },
                text: domainError,
            });
            dispatch({ type: DOMAIN_ERROR_CLEAR });
        }
    }, [domainMessagee, domainError, cuDomainId]);

    useEffect(() => {
        if (linkMessagee) {
            dispatch({ type: LINK_ID_CLEAR });
            dispatch({ type: LINK_SUCCESS_MESSAGE_CLEAR });
        }

        if (linkError) {
            Swal.fire({
                // icon: "error",
                title: "Error",
                showClass: {
                    popup: "animate__animated animate__fadeInDown",
                },
                hideClass: {
                    popup: "animate__animated animate__fadeOutUp",
                },
                text: linkError,
            });
            dispatch({ type: LINK_ERROR_CLEAR });
        }
    }, [linkMessagee, linkError, cuLinkId]);

    useEffect(() => {
        if (circleMessagee && circleMessagee.length > 0) {
            dispatch({ type: CIRCLE_ID_CLEAR });
            dispatch({ type: CIRCLE_SUCCESS_MESSAGE_CLEAR });
        }

        if (circleError) {
            Swal.fire({
                // icon: "error",
                title: "Error",
                showClass: {
                    popup: "animate__animated animate__fadeInDown",
                },
                hideClass: {
                    popup: "animate__animated animate__fadeOutUp",
                },
                text: circleError,
            });
            dispatch({ type: CIRCLE_ERROR_CLEAR });
        }
    }, [circleMessagee, circleError, cuCircleId]);

    useEffect(() => {
        if (projectMessagee && projectMessagee.length > 0) {
            dispatch({ type: PROJECT_ID_CLEAR });
            dispatch({ type: PROJECT_SUCCESS_MESSAGE_CLEAR });
        }

        if (projectError) {
            Swal.fire({
                // icon: "error",
                title: "Error",
                showClass: {
                    popup: "animate_animated animate_fadeInDown",
                },
                hideClass: {
                    popup: "animate_animated animate_fadeOutUp",
                },
                text: projectError,
            });
            dispatch({ type: PROJECT_ERROR_CLEAR });
        }
    }, [projectMessagee, projectError, cuProjectId]);
};

export default useCustomEffects;
