import React, { useEffect, useRef } from 'react'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import PillComponent from '../PillComponent';
import TextareaAutosize from "react-textarea-autosize";
import useRestoreScroll from '../../../HelperFunctions/useRestoreScroll';

export default function SkillList({ tagFilteredMegedData, handleAliesChange, GetAliasesName, teams, filteredSData, clickedOnList, toggleEXTnNOCards, getSortIcon, isInfos, setSkills, pCardHideOnchange, noteOnchange, note, handeSorts, setCombinedRefs, parentSkillRef, handleSave, noteSaveData, sSref, skillSearch, setSkillSearch, isTag, isEXT, isNO, TagWiseSorting, tagFilteredObj, extNoFilteredObj, IsOwnerExternal, statesLM, setSkill, setCardRef, workloadToggle, sumFTEBody, OwnerExist, toggleTagCards, compareMode, handleCheckboxSelection, IsChecked, lFromState, skillSubmit, sref, skill, handleSkillChange, setSkillName, skillsFrom
}) {

  const SkillListRef = useRef(null);
  useRestoreScroll(SkillListRef, "Skill", filteredSData);

  return (
    <div
      className={
        !clickedOnList.listName.includes("skill") && Object.keys(tagFilteredMegedData).length > 0 &&
          tagFilteredMegedData.skill &&
          tagFilteredMegedData.skill.length === 0
          ? "board-col hide"
          : "board-col"
      }
      data-board_id="skills"
    >
      <div className="list list-bg-blue" data-list_id="skills">
        <div className="dropdown" style={{ position: "static" }}>
          <h4 className="list-title">
            <img alt="" src="images/icon-skill.png" />
            <span
              contentEditable={true}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault(); // Prevent new line
                }
              }}
              data-name="skills"
              onBlur={(e) => handleAliesChange(e)}
              suppressContentEditableWarning={true}
            >
              {GetAliasesName(teams, "Skills")}
            </span>
            <span>{` (${filteredSData.length})`}</span>
            <a
              className={
                clickedOnList.isClicked &&
                  clickedOnList.listName.includes("skill")
                  ? "list-menu refresh-list"
                  : "list-menu refresh-list hide"
              }
              // className="list-menu refresh-list hide"
              style={{cursor: "pointer"}}
              onClick={() => {
                toggleEXTnNOCards("skills", "", true, "list");
              }}
            >
              <i className="fas fa-sync-alt"></i>
            </a>
            <a
              className={
                clickedOnList.isClicked &&
                  clickedOnList.listName.includes("skill")
                  ? "list-menu dropdown-toggle hide"
                  : "list-menu dropdown-toggle"
              }
              // className="list-menu dropdown-toggle"
              data-toggle="dropdown"
              style={{cursor: "pointer"}}
            >
              {getSortIcon("Skills")}
              <i className="fas fa-ellipsis-v"></i>
            </a>
            <ul className="dropdown-menu dropdown-menu-right" role="menu">
              <li role="presentation">
                <a
                  onClick={() => isInfos("Skills")}
                  role="menuitem"
                  className="mytpt-list-info"
                >
                  Info
                </a>
              </li>
              <li role="presentation">
                <a role="menuitem" onClick={() => setSkills()}>
                  Add new {/* skill */GetAliasesName(teams, "Skills").toLowerCase()}
                </a>
              </li>
              <li role="presentation">
                <a
                  role="menuitem"
                  onClick={() => pCardHideOnchange("Skills")}
                  className="hide_list"
                >
                  Hide list
                </a>
              </li>
              <li role="presentation">
                <a role="menuitem" style={{ paddingRight: "5px" }}>
                  Show Notes
                  <label className="switch">
                    <input
                      name="Skills"
                      onChange={(e) => noteOnchange(e)}
                      checked={note.Skills}
                      className="dd-switch"
                      type="checkbox"
                    />
                    <span className="slider"></span>
                  </label>
                </a>
              </li>
              <li role="presentation" className="divider"></li>
              <li className="dropdown-header">Sort by:</li>

              <li role="presentation">
                <a
                  role="menuitem"
                  className="sortcards"
                  data-sort_order="n"
                  onClick={() => handeSorts("NewToOld", "Skills")}
                >
                  New to Old
                </a>
              </li>
              <li role="presentation">
                <a
                  role="menuitem"
                  className="sortcards"
                  data-sort_order="nd"
                  onClick={() => handeSorts("OldToNew", "Skills")}
                >
                  Old to New
                </a>
              </li>
              <li role="presentation">
                <a
                  role="menuitem"
                  className="sortcards"
                  data-sort_order="asc"
                  onClick={() => handeSorts("AtoZ", "Skills")}
                >
                  A-Z
                </a>
              </li>
              <li role="presentation">
                <a
                  role="menuitem"
                  className="sortcards"
                  data-sort_order="desc"
                  onClick={() => handeSorts("ZtoA", "Skills")}
                >
                  Z-A
                </a>
              </li>
              <li role="presentation" className="divider"></li>

              <li role="presentation">
                <a
                  role="menuitem"
                  className="show-external"
                  onClick={() => {
                    toggleEXTnNOCards("skills", "EXT", false);
                  }}
                >
                  Show items owned by external
                </a>
              </li>
              <li role="presentation">
                <a
                  role="menuitem"
                  className="show-noowners"
                  onClick={() => {
                    toggleEXTnNOCards("skills", "NO", false);
                  }}
                >
                  Show items without owners
                </a>
              </li>
            </ul>
          </h4>
        </div>
        <Droppable droppableId="skills" direction="vertical">
          {(skillsProvided) => (
            <div
              // ref={skillsProvided.innerRef}
              // {...skillsProvided.droppableProps}
              // ref={setCombinedRefs(skillsProvided.innerRef, parentSkillRef)}
              ref={setCombinedRefs(skillsProvided.innerRef, SkillListRef)}
              {...skillsProvided.droppableProps}
              className="card-list sortable-card-list scrollbar"
              onScroll={(e) => localStorage.setItem('scrollTopSkill', e.target.scrollTop)}
            >
              <div
                className="form-group notes"
                style={
                  note.Skills ? { display: "block" } : { display: "none" }
                }
              >
                <TextareaAutosize
                  className="notes"
                  name="Skills"
                  // defaultValue=''
                  //  value={noteSaveData.Skills}
                  // onChange={(e) => handleSave(e)}
                  onBlur={(e) => handleSave(e)}
                  placeholder="Notes"
                  // maxLength="100"
                  defaultValue={noteSaveData.Skills}
                  minRows={3} // Set the minimum number of rows
                />
              </div>
              <span className="clearable">
                <input
                  type="text"
                  style={{ color: "black" }}
                  ref={sSref}
                  value={skillSearch}
                  onChange={(e) => setSkillSearch(e.target.value)}
                  data-list_name="skills"
                  className="form-control search_list transparent"
                  name="search_list"
                  placeholder="Start typing to search..."
                  autoComplete="off"
                />
                {skillSearch.length > 0 ? (
                  <i
                    className="clearable__clear"
                    onClick={() => setSkillSearch("")}
                    style={{ display: "inline", color: "black" }}
                  >
                    ×
                  </i>
                ) : null}
              </span>

              {filteredSData && filteredSData.length > 0
                ? filteredSData.map((e, index) => {
                  if (isTag || isEXT || isNO) {
                    let Proceed = TagWiseSorting(
                      e,
                      isTag,
                      isEXT,
                      isNO,
                      tagFilteredObj,
                      extNoFilteredObj,
                      "skill"
                    );
                    if (!Proceed) return;
                  }

                  let isExternal = IsOwnerExternal(e, "Skill");
                  return (
                    <Draggable
                      key={index}
                      draggableId={`${e._id}`}
                      index={index}
                      isDragDisabled={
                        statesLM.Skills !== "Parsonal" ? true : false
                      }
                    >
                      {(skillsProvided) => (
                        <div
                          ref={skillsProvided.innerRef}
                          {...skillsProvided.draggableProps}
                          {...skillsProvided.dragHandleProps}
                          // onDragStart={(e) => skillsDragStart(e, index)}
                          // onDragEnter={(e) => skillsDragEnter(e, index)}
                          // onDragEnd={skillsDrop}
                          key={index}
                          className={`card ${localStorage.getItem("latest-created") ===
                            e._id
                            ? "latest-created"
                            : ""
                            }`}
                          onClick={(event) => {
                            if (
                              event.target.classList.contains("card") ||
                              event.target.classList.contains("card-edit")
                            ) {
                              setSkill(e, filteredSData, index);
                            }
                            /* const scrollTop =
                              parentSkillRef.current.scrollTop;
                            setCardRef(prevCardRef =>
                              prevCardRef.map(item =>
                                item.type === "skill" ? { ...item, val: scrollTop } : item
                              )
                            ); */
                          }}
                        >
                          <div className="static-relations">
                            {workloadToggle === true && (
                              <PillComponent
                                occupied={sumFTEBody(
                                  e.workload,
                                  e.owners
                                )}
                                total={e.planTotal}
                              />
                            )}
                            {isExternal ? (
                              <span
                                onClick={() => {
                                  toggleEXTnNOCards(
                                    "skill",
                                    "EXT",
                                    false,
                                    "pill"
                                  );
                                }}
                                className="custom-badge blue ext-owner-pill"
                              >
                                EXT
                              </span>
                            ) : null}
                            {e.owners.length === 0 ||
                              !OwnerExist(e.owners) ? (
                              <span
                                onClick={() => {
                                  toggleEXTnNOCards(
                                    "skill",
                                    "NO",
                                    false,
                                    "pill"
                                  );
                                }}
                                className="custom-badge no-owner-pill red"
                              >
                                NO
                              </span>
                            ) : null}
                            {e.ownerType.length > 0 &&
                              e.ownerType === "Single owner" ? (
                              <span className="custom-badge blue-o single-owner-pill single-owner-pill">
                                1
                              </span>
                            ) : null}
                            {e.tags && e.tags.length > 0
                              ? e.tags.map((r, indexs) => (
                                <span
                                  onClick={() => toggleTagCards(r)}
                                  key={indexs}
                                  className="custom-badge green tags"
                                >
                                  {r}
                                </span>
                              ))
                              : null}
                          </div>
                          <a
                                    /* onClick={() => setSkill(e, filteredSData, index)} */ className="card-edit"
                          >
                            {e.skillName}
                          </a>
                          {compareMode.active &&
                            compareMode.compareTypes /*&&  showCheckBox("skill")*/ && (
                              <input
                                type="checkbox"
                                id={e._id}
                                name={e.skillName}
                                onChange={() =>
                                  handleCheckboxSelection(e._id, "skill")
                                }
                                checked={IsChecked(e._id)}
                              />
                            )}
                        </div>
                      )}
                    </Draggable>
                  );
                })
                : null}

              {skillsProvided.placeholder}

              <div
                className="card2"
                style={
                  lFromState.Skills
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                <form
                  className="form-new-card"
                  role="form"
                  onSubmit={skillSubmit}
                >
                  <div className="form-group">
                    <input
                      type="text"
                      ref={sref}
                      name="card_name"
                      required
                      placeholder={`Add ${GetAliasesName(teams, "Skills").toLowerCase()} name`}
                      className="form-control"
                      value={skill}
                      onChange={handleSkillChange}
                      autoComplete="off"
                    />
                  </div>
                  <div className="form-group" style={{ marginTop: "10px" }}>
                    <button
                      type="submit"
                      className="btn btn-mytpt add-quick close-add-card"
                    >
                      Save
                    </button>
                    <a
                      style={{cursor: "pointer"}}
                      type="button"
                      onClick={() => {
                        setSkills({ skillName: skill });
                        setSkillName("");
                        lFromState.Skills = false;
                      }}
                      className="btn btn-link underline open-tool"
                    >
                      Edit details
                    </a>
                    <a
                      style={{cursor: "pointer"}}
                      onClick={() => skillsFrom()}
                      className="btn btn-link underline close-add-card"
                    >
                      Close
                    </a>
                  </div>
                </form>
              </div>
            </div>
          )}
        </Droppable>
        <a
          className="btn-list add-card"
          onClick={() => skillsFrom()}
          data-list_id="skills"
          style={
            !lFromState.Skills ? { display: "block" } : { display: "none" }
          }
        >
          + Add new {/* skill */} {GetAliasesName(teams, "Skills").toLowerCase()}
        </a>
      </div>
    </div>
  );
}
