import React, { useEffect, useRef } from 'react'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import PillComponent from '../PillComponent';
import TextareaAutosize from "react-textarea-autosize";
import useRestoreScroll from '../../../HelperFunctions/useRestoreScroll';

export default function LinkList({ tagFilteredMegedData, handleAliesChange, GetAliasesName, clickedOnList, toggleEXTnNOCards, getSortIcon, isInfos, setLinks, pCardHideOnchange, noteOnchange, note, handeSorts, setCombinedRefs, handleSave, noteSaveData, setLinkSearch, linkSearch, isTag, isEXT, isNO, filteredDLata, TagWiseSorting, IsOwnerExternal, statesLM, setLink, parentLinkRef, setCardRef, workloadToggle, sumFTEBody, linkSubmit, handleLinkChange, setLinkName, linksFrom, teams, links, sLref, tagFilteredObj, toggleTagCards, lFromState, lref, compareMode, extNoFilteredObj, link, peopleSorted
}) {

    const LinkListRef = useRef(null);
    useRestoreScroll(LinkListRef, "Link", filteredDLata);

    if (!compareMode.active) {
        return (
            <div
                className={
                    !clickedOnList.listName.includes("link") && Object.keys(tagFilteredMegedData).length > 0 &&
                        tagFilteredMegedData.link &&
                        tagFilteredMegedData.link.length === 0
                        ? "board-col hide"
                        : "board-col"
                }
                data-board_id="links"
            >
                <div className="list list-bg-blue" data-list_id="links">
                    <div className="dropdown">
                        <h4 className="list-title">
                            <img alt="" src="images/icon-link.png" />
                            <span contentEditable={true}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault(); // Prevent new line
                                    }
                                }} data-name="links" onBlur={(e) => handleAliesChange(e)} suppressContentEditableWarning={true}>{GetAliasesName(teams, "Links")}</span>
                            <span>{` (${links.length})`}</span>
                            <a
                                className={
                                    clickedOnList.isClicked &&
                                        clickedOnList.listName.includes("link")
                                        ? "list-menu refresh-list"
                                        : "list-menu refresh-list hide"
                                }
                                // className="list-menu refresh-list hide"
                                style={{cursor: "pointer"}}
                                onClick={() => {
                                    toggleEXTnNOCards("links", "", true, "list");
                                }}
                            >
                                <i className="fas fa-sync-alt"></i>
                            </a>
                            <a
                                className={
                                    clickedOnList.isClicked &&
                                        clickedOnList.listName.includes("link")
                                        ? "list-menu dropdown-toggle hide"
                                        : "list-menu dropdown-toggle"
                                }
                                // className="list-menu dropdown-toggle"
                                data-toggle="dropdown"
                                style={{cursor: "pointer"}}
                            >
                                {getSortIcon("Links")}
                                <i className="fas fa-ellipsis-v"></i>
                            </a>
                            <ul className="dropdown-menu dropdown-menu-right" role="menu">
                                <li role="presentation">
                                    <a
                                        onClick={() => isInfos("Links")}
                                        role="menuitem"
                                        className="mytpt-list-info"
                                    >
                                        Info
                                    </a>
                                </li>
                                <li role="presentation">
                                    <a role="menuitem" onClick={() => setLinks()}>
                                        Add new {/* link */}{GetAliasesName(teams, "Links").toLowerCase()}
                                    </a>
                                </li>
                                <li role="presentation">
                                    <a
                                        role="menuitem"
                                        onClick={() => pCardHideOnchange("Links")}
                                        className="hide_list"
                                    >
                                        Hide list
                                    </a>
                                </li>
                                <li role="presentation">
                                    <a role="menuitem" style={{ paddingRight: "5px" }}>
                                        Show Notes
                                        <label className="switch">
                                            <input
                                                name="Links"
                                                onChange={(e) => noteOnchange(e)}
                                                checked={note.Links}
                                                className="dd-switch"
                                                type="checkbox"
                                            />
                                            <span className="slider"></span>
                                        </label>
                                    </a>
                                </li>
                                <li role="presentation" className="divider"></li>
                                <li className="dropdown-header">Sort by:</li>

                                <li role="presentation">
                                    <a
                                        role="menuitem"
                                        className="sortcards"
                                        data-sort_order="n"
                                        onClick={() => handeSorts("NewToOld", "Links")}
                                    >
                                        New to Old
                                    </a>
                                </li>
                                <li role="presentation">
                                    <a
                                        role="menuitem"
                                        className="sortcards"
                                        data-sort_order="nd"
                                        onClick={() => handeSorts("OldToNew", "Links")}
                                    >
                                        Old to New
                                    </a>
                                </li>
                                <li role="presentation">
                                    <a
                                        role="menuitem"
                                        className="sortcards"
                                        data-sort_order="asc"
                                        onClick={() => handeSorts("AtoZ", "Links")}
                                    >
                                        A-Z
                                    </a>
                                </li>
                                <li role="presentation">
                                    <a
                                        role="menuitem"
                                        className="sortcards"
                                        data-sort_order="desc"
                                        onClick={() => handeSorts("ZtoA", "Links")}
                                    >
                                        Z-A
                                    </a>
                                </li>

                                <li role="presentation" className="divider"></li>
                                <li role="presentation">
                                    <a
                                        role="menuitem"
                                        className="show-external"
                                        onClick={() => {
                                            toggleEXTnNOCards("links", "EXT", false);
                                        }}
                                    >
                                        Show items owned by external
                                    </a>
                                </li>
                                <li role="presentation">
                                    <a
                                        role="menuitem"
                                        className="show-noowners"
                                        onClick={() => {
                                            toggleEXTnNOCards("links", "NO", false);
                                        }}
                                    >
                                        Show items without owners
                                    </a>
                                </li>
                            </ul>
                        </h4>
                    </div>
                    <Droppable droppableId="links" direction="vertical">
                        {(linksProvided) => (
                            <div
                                // ref={linksProvided.innerRef}
                                // ref={setCombinedRefs(linksProvided.innerRef, parentLinkRef)}
                                ref={setCombinedRefs(linksProvided.innerRef, LinkListRef)}
                                {...linksProvided.droppableProps}
                                className="card-list sortable-card-list scrollbar"
                                onScroll={(e) => localStorage.setItem('scrollTopLink', e.target.scrollTop)}
                            >
                                <div
                                    className="form-group notes"
                                    style={
                                        note.Links ? { display: "block" } : { display: "none" }
                                    }
                                >
                                    <TextareaAutosize
                                        className="notes"
                                        name="Links"
                                        // defaultValue=''
                                        // value={noteSaveData.Links}
                                        //   onChange={(e) => handleSave(e)}
                                        onBlur={(e) => handleSave(e)}
                                        placeholder="Notes"
                                        // maxLength="100"
                                        defaultValue={noteSaveData.Links}
                                        minRows={3} // Set the minimum number of rows
                                    />
                                </div>
                                <span className="clearable">
                                    <input
                                        type="text"
                                        ref={sLref}
                                        style={{ color: "black" }}
                                        onChange={(e) => setLinkSearch(e.target.value)}
                                        value={linkSearch}
                                        className="form-control search_list transparent"
                                        name="search_list"
                                        placeholder="Start typing to search..."
                                        autoComplete="off"
                                    />
                                    {linkSearch.length > 0 ? (
                                        <i
                                            className="clearable__clear"
                                            onClick={() => setLinkSearch("")}
                                            style={{ display: "inline", color: "black" }}
                                        >
                                            ×
                                        </i>
                                    ) : null}
                                </span>
                                {filteredDLata && filteredDLata.length > 0
                                    ? filteredDLata.map((e, index) => {
                                        if (isTag || isEXT || isNO) {
                                            let Proceed = TagWiseSorting(
                                                e,
                                                isTag,
                                                isEXT,
                                                isNO,
                                                tagFilteredObj,
                                                extNoFilteredObj,
                                                "link"
                                            );
                                            // console.log("Link", Proceed);
                                            if (!Proceed) return;
                                        }

                                        let isExternal = IsOwnerExternal(e, "Link");

                                        return (
                                            <Draggable
                                                key={index}
                                                draggableId={`${e._id}`}
                                                index={index}
                                                isDragDisabled={
                                                    statesLM.Links !== "Parsonal" ? true : false
                                                }
                                            >
                                                {(linksProvided) => (
                                                    <div
                                                        ref={linksProvided.innerRef}
                                                        {...linksProvided.draggableProps}
                                                        {...linksProvided.dragHandleProps}
                                                        // onDragStart={(e) => linksDragStart(e, index)}
                                                        // onDragEnter={(e) => linksDragEnter(e, index)}
                                                        // onDragEnd={linksDrop}

                                                        key={index}
                                                        className={`card ${localStorage.getItem("latest-created") ===
                                                            e._id
                                                            ? "latest-created"
                                                            : ""
                                                            }`}
                                                        data-list_id="links"
                                                        onClick={(event) => {
                                                            if (
                                                                event.target.classList.contains("card") ||
                                                                event.target.classList.contains("card-edit")
                                                            ) {
                                                                setLink(e, filteredDLata, index);
                                                            }
                                                            /* const scrollTop =
                                                                parentLinkRef.current.scrollTop;
                                                            setCardRef(prevCardRef =>
                                                                prevCardRef.map(item =>
                                                                    item.type === "link" ? { ...item, val: scrollTop } : item
                                                                )
                                                            ); */
                                                        }}
                                                    >
                                                        <div className="static-relations">
                                                            {workloadToggle === true && (
                                                                <PillComponent
                                                                    occupied={sumFTEBody(e.workload, [
                                                                        e.owner,
                                                                    ])}
                                                                    total={e.planTotal}
                                                                />
                                                            )}
                                                            {isExternal ? (
                                                                <span
                                                                    onClick={() => {
                                                                        toggleEXTnNOCards(
                                                                            e._id,
                                                                            "EXT",
                                                                            false,
                                                                            "pill"
                                                                        );
                                                                    }}
                                                                    className="custom-badge blue ext-owner-pill"
                                                                >
                                                                    EXT
                                                                </span>
                                                            ) : null}

                                                            {e.owner && e.owner.length > 0 && peopleSorted.some(
                                                                person => person._id === e.owner && !person.softDelete
                                                            ) ? null : (
                                                                <span
                                                                    onClick={() => {
                                                                        toggleEXTnNOCards(
                                                                            e._id,
                                                                            "NO",
                                                                            false,
                                                                            "pill"
                                                                        );
                                                                    }}
                                                                    className="custom-badge no-owner-pill red"
                                                                >
                                                                    NO
                                                                </span>
                                                            )}
                                                            {e.tags && e.tags.length > 0
                                                                ? e.tags.map((r, indexs) => (
                                                                    <span
                                                                        key={indexs}
                                                                        onClick={() => toggleTagCards(r)}
                                                                        className="custom-badge green tags"
                                                                    >
                                                                        {r}
                                                                    </span>
                                                                ))
                                                                : null}
                                                        </div>
                                                        <a
                                                            // onClick={() => setLink(e, filteredDLata, index)}
                                                            className="card-edit"
                                                        >
                                                            {e.linkName}
                                                        </a>
                                                    </div>
                                                )}
                                            </Draggable>
                                        );
                                    })
                                    : null}

                                {linksProvided.placeholder}

                                <div
                                    className="card2"
                                    style={
                                        lFromState.Links
                                            ? { display: "block" }
                                            : { display: "none" }
                                    }
                                >
                                    <form
                                        className="form-new-card"
                                        role="form"
                                        onSubmit={linkSubmit}
                                    >
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                ref={lref}
                                                name="card_name"
                                                value={link}
                                                onChange={handleLinkChange}
                                                required
                                                placeholder={`Add ${GetAliasesName(teams, "Links").toLowerCase()} name`}
                                                className="form-control"
                                                autoComplete="off"
                                            />
                                        </div>
                                        <div className="form-group" style={{ marginTop: "10px" }}>
                                            <button
                                                type="submit"
                                                className="btn btn-mytpt add-quick close-add-card"
                                            >
                                                Save
                                            </button>
                                            <a
                                                style={{cursor: "pointer"}}
                                                type="button"
                                                onClick={() => {
                                                    setLinks({ linkName: link });
                                                    setLinkName("");
                                                    lFromState.Links = false;
                                                }}
                                                className="btn btn-link underline open-tool"
                                            >
                                                Edit details
                                            </a>
                                            <a
                                                style={{cursor: "pointer"}}
                                                onClick={() => linksFrom()}
                                                className="btn btn-link underline close-add-card"
                                            >
                                                Close
                                            </a>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        )}
                    </Droppable>
                    <a
                        className="btn-list add-card"
                        onClick={() => linksFrom()}
                        data-list_id="links"
                        style={
                            !lFromState.Links ? { display: "block" } : { display: "none" }
                        }
                    >
                        + Add new {/* link */}{GetAliasesName(teams, "Links").toLowerCase()}
                    </a>
                </div>
            </div>
        );
    } else {
        return <></>;
    }
}
