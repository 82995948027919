import { useEffect } from "react";

const useRestoreScroll = (ref, type, dependency) => {
  useEffect(() => {
    if (!ref || !ref.current || !type) return;

    const savedScroll = localStorage.getItem(`scrollTop${type}`);
    if (savedScroll !== null) {
      ref.current.scrollTop = parseInt(savedScroll, 10);
    }

    return () => {
      if (ref.current) {
        localStorage.setItem(`scrollTop${type}`, ref.current.scrollTop);
      }
    };
  }, [dependency]); // Runs when data changes (e.g., filtered list updates)
};

export default useRestoreScroll;
