import React, { useEffect, useRef } from 'react'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import PillComponent from '../PillComponent';
import TextareaAutosize from "react-textarea-autosize";
import useRestoreScroll from '../../../HelperFunctions/useRestoreScroll';

export default function FPCirclesList({ tagFilteredMegedData, handleAliesChange, GetAliasesName, teams, curPefCircles, getSortIcon, isInfos, setCircles, pCardHideOnchange, handleDragStart, handleDragEndPF, note, handleSave, noteSaveData, toggleTagCards, temporarilyMovedItem, dCircles, PfToCAndP, removeCircleFCoreOrExt, fpCref, circlefSearch, setCirclefSearch, filteredCFData, pfcircleOrProjectModes, lFromState, circleSubmit, cref, circle, handleCircleChange, setCircleName, circlesFrom, isTag, memberInDatas, focused, pfCicleLead, getAdminTasks, ScrollIntoView, setCircle, cardOpens
}) {

    const FPCirclesListRef = useRef(null);
    useRestoreScroll(FPCirclesListRef, "Circle", filteredCFData);

    return (
        <div
            className={
                Object.keys(tagFilteredMegedData).length > 0 &&
                    tagFilteredMegedData.circle &&
                    tagFilteredMegedData.circle.length === 0
                    ? "board-col hide"
                    : "board-col"
            }
            data-board_id="circles"
        >

            <div className="list list-bg-red" data-list_id="circles">
                <div className="dropdown">
                    <h4 className="list-title">
                        <img alt="" src="images/icon-circle.png" />
                        {/* {GetAliasesName(teams, "Circles")}  (<span>{}</span>){" "} */}
                        <span contentEditable={true}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault(); // Prevent new line
                                }
                            }} data-name="circles" onBlur={(e) => handleAliesChange(e)} suppressContentEditableWarning={true}>{GetAliasesName(teams, "Circles")}</span>
                        <span>{` (${curPefCircles.length})`}</span>{" "}
                        <a className="list-menu refresh-list hide" style={{cursor: "pointer"}}>
                            <i className="fas fa-sync-alt"></i>
                        </a>{" "}
                        <a
                            className="list-menu dropdown-toggle"
                            data-toggle="dropdown"
                            style={{cursor: "pointer"}}
                        >
                            {getSortIcon("Circles")}
                            <i className="fas fa-ellipsis-v"></i>
                        </a>
                        <ul className="dropdown-menu dropdown-menu-right" role="menu">
                            <li role="presentation">
                                <a
                                    onClick={() => isInfos("Circles")}
                                    role="menuitem"
                                    className="mytpt-list-info"
                                >
                                    Info
                                </a>
                            </li>
                            <li role="presentation">
                                <a
                                    role="menuitem"
                                    data-toggle="modal"
                                    data-target="#circlesModal"
                                    onClick={() => setCircles()}
                                >
                                    Add new {GetAliasesName(teams, "Circles").toLowerCase()}
                                </a>
                            </li>
                            <li role="presentation">
                                <a
                                    role="menuitem"
                                    onClick={() => pCardHideOnchange("Circles")}
                                    className="hide_list"
                                >
                                    Hide list
                                </a>
                            </li>
                            <li role="presentation">
                                <a role="menuitem" style={{ paddingRight: "5px" }}>
                                    Show Notes
                                    <label className="switch">
                                        <input
                                            name="show_notes_people"
                                            className="dd-switch"
                                            type="checkbox"
                                        />
                                        <span className="slider"></span>
                                    </label>
                                </a>
                            </li>
                            <li role="presentation" className="hide">
                                <a role="menuitem" className="show-external">
                                    Show external people
                                </a>
                            </li>
                            <li role="presentation" className="hide">
                                <a role="menuitem" className="show-noowners">
                                    Show items without owners
                                </a>
                            </li>
                        </ul>
                    </h4>
                </div>

                <DragDropContext
                    onDragStart={handleDragStart}
                    onDragEnd={handleDragEndPF}
                >
                    <div className="card-list scrollbar"
                        ref={FPCirclesListRef}
                        onScroll={(e) => localStorage.setItem('scrollTopCircle', e.target.scrollTop)}
                    >
                        <div
                            className="form-group notes"
                            style={note.Circles ? { display: "block" } : { display: "none" }}
                        >
                            <TextareaAutosize
                                className="notes"
                                name="Circles"
                                onBlur={(e) => handleSave(e)}
                                placeholder="Notes"
                                // maxLength="100"
                                defaultValue={noteSaveData.Circles}
                                minRows={3} // Set the minimum number of rows
                            />
                        </div>

                        <Droppable
                            className="panel panel-default"
                            droppableId="circles-core"
                            direction="vertical"
                        >
                            {(circlesProvidedPf) => (
                                <div
                                    ref={circlesProvidedPf.innerRef}
                                    {...circlesProvidedPf.droppableProps}
                                    id="core"
                                    className="panel-body box-container"
                                >
                                    {curPefCircles && curPefCircles.length > 0
                                        ? curPefCircles.map((e, index) => {
                                            if (
                                                isTag &&
                                                Object.keys(tagFilteredMegedData).length > 0 &&
                                                tagFilteredMegedData.circle &&
                                                tagFilteredMegedData.circle.length > 0 &&
                                                !tagFilteredMegedData.circle.includes(e._id)
                                            ) {
                                                return null;
                                            }

                                            let MemData = [];
                                            for (let i = 0; i < memberInDatas.length; i++) {
                                                if (memberInDatas[i].memberId === e._id) {
                                                    MemData.push(memberInDatas[i]);
                                                }
                                            }
                                            let flag = false;
                                            if (
                                                focused &&
                                                focused.card.length > 0 &&
                                                focused.card === "People"
                                            ) {
                                                flag = pfCicleLead(e);
                                            }
                                            let isAddAsP = false;
                                            if (MemData && MemData.length === 1) {
                                                if (MemData[0].cardType === "People") {
                                                    isAddAsP = true;
                                                }
                                            }
                                            let curpOIndex = null;
                                            if (MemData && MemData.length > 0) {
                                                for (let i = 0; i < MemData.length; i++) {
                                                    if (MemData[i].cardType == "People") {
                                                        curpOIndex = i;
                                                        break;
                                                    }
                                                }
                                            }

                                            let adminTasks = getAdminTasks(e);

                                            return (
                                                <Draggable
                                                    key={e.id}
                                                    draggableId={`${e._id}`}
                                                    index={index}
                                                    isDragDisabled={
                                                        (MemData.length === 1 ||
                                                            isAddAsP ||
                                                            curpOIndex !== null) &&
                                                            (!flag || isAddAsP)
                                                            ? false
                                                            : true
                                                    }
                                                >
                                                    {(circlesProvidedPf) => (
                                                        <div
                                                            ref={circlesProvidedPf.innerRef}
                                                            {...circlesProvidedPf.draggableProps}
                                                            {...circlesProvidedPf.dragHandleProps}
                                                            key={e._id}
                                                            /* {...(MemData.length === 1 || isAddAsP || curpOIndex !== null) && (!flag || isAddAsP) ? {
                                                      onDragStart: (e) => circlesDragStart(e, index),
                                                      onDragEnter: (e) => circlesDragEnter(e, index),
                                                      onDragEnd: () => removeCircleFCoreOrExt(MemData[curpOIndex !== null ? curpOIndex : 0])
                                                    } : null} */
                                                            // draggable={(MemData.length === 1 || isAddAsP || curpOIndex !== null) && (!flag || isAddAsP) ? true : false}
                                                            // className={e.defaultCircle ? "card default-circle" : "card"}
                                                            className={`card ${localStorage.getItem("latest-created") ===
                                                                e._id
                                                                ? " latest-created "
                                                                : ""
                                                                }`}
                                                            data-list_id="circles"
                                                            onClick={(event) => {
                                                                ScrollIntoView();
                                                                if (
                                                                    event.target.classList.contains("card") ||
                                                                    event.target.classList.contains("card-edit")
                                                                ) {
                                                                    setCircle(e, curPefCircles, index);
                                                                }
                                                            }}
                                                        >
                                                            <div className="static-relations">
                                                                {e.tags && e.tags.length > 0
                                                                    ? e.tags.map((r, indexs) => (
                                                                        <span
                                                                            onClick={() => toggleTagCards(r)}
                                                                            key={indexs}
                                                                            className="custom-badge green tags"
                                                                        >
                                                                            {r}
                                                                        </span>
                                                                    ))
                                                                    : null}

                                                                {flag && flag === "direct" ? (
                                                                    <span className="custom-badge direct-lead red-o">
                                                                        LEAD
                                                                    </span>
                                                                ) : flag && flag === "indirect" ? (
                                                                    <span className="custom-badge direct-lead gray-o">
                                                                        LEAD
                                                                    </span>
                                                                ) : null}

                                                                {/* { standIn === e.standIn && <span className="custom-badge gray-o">LEAD (Stand-In)</span>} */}
                                                                {Object.keys(focused).length > 0 && focused.id && e.standIn &&
                                                                    focused.id === e.standIn &&
                                                                    <span className="custom-badge gray-o">LEAD (Stand-In)</span>
                                                                }

                                                                {MemData && MemData.length > 0
                                                                    ? MemData.map((m, mindex) => {
                                                                        return (
                                                                            <span
                                                                                key={mindex}
                                                                                onClick={() => cardOpens(m)}
                                                                                className={
                                                                                    m.cardType == "People"
                                                                                        ? "custom-badge lightgray text-red person-pill"
                                                                                        : "custom-badge lightgray"
                                                                                }
                                                                            >
                                                                                {m.XTD && m.XTD.length > 0 ? (
                                                                                    <span className="text-red">
                                                                                        XTD{" "}
                                                                                    </span>
                                                                                ) : null}
                                                                                {m.name}
                                                                            </span>
                                                                        );
                                                                    })
                                                                    : null}

                                                                {/* admin stats owned by the person in focus to show here */}
                                                                {adminTasks && adminTasks.length > 0
                                                                    ? adminTasks.map((aTask, atindex) => {
                                                                        return (
                                                                            <span
                                                                                key={atindex}
                                                                                className="custom-badge green-o"
                                                                            >
                                                                                {aTask.statusReport}
                                                                            </span>
                                                                        );
                                                                    })
                                                                    : null}
                                                            </div>

                                                            <a
                                  /* onClick={() => setCircle(e, curPefCircles, index)} */ className="card-edit"
                                                            >
                                                                {e.circleName}
                                                            </a>
                                                            <a className="card-focus">
                                                                <img
                                                                    onClick={() => PfToCAndP("Circles", e)}
                                                                    src="./images/newicons/focus.svg"
                                                                />
                                                            </a>
                                                            {(MemData.length === 1 ||
                                                                isAddAsP ||
                                                                curpOIndex !== null) &&
                                                                (!flag || isAddAsP) ? (
                                                                <a
                                                                    className="card-unlink"
                                                                    onClick={() =>
                                                                        removeCircleFCoreOrExt(
                                                                            MemData[
                                                                            curpOIndex !== null ? curpOIndex : 0
                                                                            ]
                                                                        )
                                                                    }
                                                                    style={{ display: "block", color: "red" }}
                                                                >
                                                                    <i className="icon far fa-times-circle"></i>
                                                                </a>
                                                            ) : null}
                                                        </div>
                                                    )}
                                                </Draggable>
                                            );
                                        })
                                        : null}

                                    {dCircles && dCircles.length > 0
                                        ? dCircles.map((d, index) => {
                                            let flag = false;
                                            if (
                                                focused &&
                                                focused.card.length > 0 &&
                                                focused.card === "People"
                                            ) {
                                                flag = pfCicleLead(d);
                                            }

                                            let adminTasks = getAdminTasks(d);
                                            return (
                                                <div
                                                    key={index}
                                                    className="card default-circle"
                                                    data-default="true"
                                                >
                                                    <span className="static-relations">
                                                        {d.tags && d.tags.length > 0
                                                            ? d.tags.map((r, indexs) => (
                                                                <span
                                                                    onClick={() => toggleTagCards(r)}
                                                                    key={indexs}
                                                                    className="custom-badge green tags"
                                                                >
                                                                    {r}
                                                                </span>
                                                            ))
                                                            : null}

                                                        {flag && flag === "direct" ? (
                                                            <span className="custom-badge direct-lead red-o">
                                                                LEAD
                                                            </span>
                                                        ) : flag && flag === "indirect" ? (
                                                            <span className="custom-badge direct-lead gray-o">
                                                                LEAD
                                                            </span>
                                                        ) : null}

                                                        {Object.keys(focused).length > 0 && focused.id && d.standIn &&
                                                            focused.id === d.standIn &&
                                                            <span className="custom-badge gray-o">LEAD (Stand-In)</span>
                                                        }

                                                        {/* admin stats owned by the person in focus to show here */}
                                                        {adminTasks && adminTasks.length > 0
                                                            ? adminTasks.map((aTask, atindex) => {
                                                                return (
                                                                    <span
                                                                        key={atindex}
                                                                        className="custom-badge green-o"
                                                                    >
                                                                        {aTask.statusReport}
                                                                    </span>
                                                                );
                                                            })
                                                            : null}
                                                    </span>
                                                    <a
                                                        onClick={() => setCircle(d, dCircles, index)}
                                                        className="card-edit"
                                                    >
                                                        {d.circleName}
                                                    </a>
                                                </div>
                                            );
                                        })
                                        : null}

                                    {temporarilyMovedItem &&
                                        temporarilyMovedItem.cardType === "Circle" &&
                                        Object.keys(temporarilyMovedItem.card).length > 0
                                        ? (() => {
                                            let flag = false;
                                            if (
                                                focused &&
                                                focused.card.length > 0 &&
                                                focused.card === "People"
                                            ) {
                                                flag = pfCicleLead(temporarilyMovedItem.card); // Pass the temporarilyMovedItem to the function
                                            }

                                            return (
                                                <div
                                                    key={temporarilyMovedItem.card._id}
                                                    className="card move-up-down"
                                                    style={{
                                                        border: "1px dashed #000",
                                                        opacity: "0.7",
                                                    }}
                                                >
                                                    <span className="static-relations">
                                                        {temporarilyMovedItem.card.tags &&
                                                            temporarilyMovedItem.card.tags.length > 0
                                                            ? temporarilyMovedItem.card.tags.map(
                                                                (r, indexs) => (
                                                                    <span
                                                                        onClick={() => toggleTagCards(r)}
                                                                        key={indexs}
                                                                        className="custom-badge green tags"
                                                                    >
                                                                        {r}
                                                                    </span>
                                                                )
                                                            )
                                                            : null}

                                                        {flag && flag === "direct" ? (
                                                            <span className="custom-badge direct-lead red-o">
                                                                LEAD
                                                            </span>
                                                        ) : flag && flag === "indirect" ? (
                                                            <span className="custom-badge direct-lead gray-o">
                                                                LEAD
                                                            </span>
                                                        ) : null}


                                                    </span>
                                                    <a className="card-edit">
                                                        {temporarilyMovedItem.card.circleName}
                                                    </a>
                                                </div>
                                            );
                                        })()
                                        : null}

                                    {circlesProvidedPf.placeholder}
                                </div>
                            )}
                        </Droppable>

                        <span className="clearable2">
                            <input
                                type="text"
                                ref={fpCref}
                                style={{ color: "black" }}
                                value={circlefSearch}
                                onChange={(e) => setCirclefSearch(e.target.value)}
                                className="form-control search_list2 transparent"
                                name="search_list"
                                placeholder="Search to select more"
                                autoComplete="off"
                            />
                            {circlefSearch.length > 0 ? (
                                <i
                                    className="clearable__clear"
                                    onClick={() => setCirclefSearch("")}
                                    style={{ display: "inline", color: "black" }}
                                >
                                    ×
                                </i>
                            ) : null}
                        </span>

                        <Droppable
                            className="panel panel-default"
                            droppableId="circles-notmatched"
                            direction="vertical"
                        >
                            {(circlesProvidedPf) => (
                                <div
                                    ref={circlesProvidedPf.innerRef}
                                    {...circlesProvidedPf.droppableProps}
                                    id="notmatched"
                                    className="panel-body box-container"
                                >
                                    {filteredCFData && filteredCFData.length > 0
                                        ? filteredCFData.map((e, index) => {
                                            if (
                                                isTag &&
                                                Object.keys(tagFilteredMegedData).length > 0 &&
                                                tagFilteredMegedData.circle &&
                                                tagFilteredMegedData.circle.length > 0 &&
                                                !tagFilteredMegedData.circle.includes(e._id)
                                            ) {
                                                return null;
                                            }
                                            if (
                                                temporarilyMovedItem &&
                                                temporarilyMovedItem.cardType === "Circle" &&
                                                Object.keys(temporarilyMovedItem.card).length > 0 &&
                                                temporarilyMovedItem.card._id === e._id
                                            )
                                                return;

                                            return (
                                                <Draggable
                                                    key={e.id}
                                                    draggableId={`${e._id}`}
                                                    index={index}
                                                    isDragDisabled={e.defaultCircle ? true : false}
                                                >
                                                    {(circlesProvidedPf) => (
                                                        <div
                                                            ref={circlesProvidedPf.innerRef}
                                                            {...circlesProvidedPf.draggableProps}
                                                            {...circlesProvidedPf.dragHandleProps}
                                                            key={e._id}
                                                            // onDragStart={(e) => circlesDragStart(e, index)}
                                                            // onDragEnter={(e) => circlesDragEnter(e, index)}
                                                            // onDragEnd={() => pfcircleOrProjectModes(e, "Circles")}
                                                            // draggable={e.defaultCircle ? false : true}
                                                            className={`card notmatched ${e.defaultCircle
                                                                ? " default-circle "
                                                                : localStorage.getItem("latest-created") ===
                                                                    e._id
                                                                    ? " latest-created "
                                                                    : ""
                                                                }`}
                                                            data-list_id="circles"
                                                        >
                                                            <div className="static-relations">
                                                                {e.tags && e.tags.length > 0
                                                                    ? e.tags.map((r, tindex) => (
                                                                        <span
                                                                            onClick={() => toggleTagCards(r)}
                                                                            key={tindex}
                                                                            className="custom-badge green tags"
                                                                        >
                                                                            {r}
                                                                        </span>
                                                                    ))
                                                                    : null}
                                                            </div>

                                                            <a
                                                                onClick={() =>
                                                                    setCircle(e, filteredCFData, index)
                                                                }
                                                                className="card-edit"
                                                            >
                                                                {e.circleName}
                                                            </a>
                                                            <a
                                                                className="card-link"
                                                                variant="success"
                                                                style={{ display: "block" }}
                                                            >
                                                                <i
                                                                    onClick={() =>
                                                                        pfcircleOrProjectModes(e, "Circles")
                                                                    }
                                                                    className="icon fa fa-plus-circle"
                                                                ></i>
                                                            </a>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            );
                                        })
                                        : null}
                                    {circlesProvidedPf.placeholder}
                                </div>
                            )}
                        </Droppable>

                        <div
                            className="card2"
                            style={
                                lFromState.Circles ? { display: "block" } : { display: "none" }
                            }
                        >
                            <form
                                className="form-new-card"
                                role="form"
                                onSubmit={circleSubmit}
                            >
                                <div className="form-group">
                                    <input
                                        type="text"
                                        ref={cref}
                                        name="card_name"
                                        required
                                        placeholder={`+ Add ${GetAliasesName(teams, "Circles").toLowerCase()} name`}
                                        className="form-control"
                                        value={circle}
                                        onChange={handleCircleChange}
                                        autoComplete="off"
                                    />
                                </div>
                                <div className="form-group" style={{ marginTop: "10px" }}>
                                    <button type="submit" className="btn btn-mytpt add-quick">
                                        Save
                                    </button>
                                    <a
                                        style={{cursor: "pointer"}}
                                        type="button"
                                        onClick={() => {
                                            setCircles({ circleName: circle });
                                            setCircleName("");
                                            lFromState.Circles = false;
                                        }}
                                        className="btn btn-link underline open-tool"
                                    >
                                        Edit details
                                    </a>
                                    <a
                                        style={{cursor: "pointer"}}
                                        onClick={() => circlesFrom()}
                                        className="btn btn-link underline close-add-card"
                                    >
                                        Close
                                    </a>
                                </div>
                            </form>
                        </div>
                    </div>
                </DragDropContext>
                <a
                    className="btn-list add-card"
                    onClick={() => circlesFrom()}
                    data-list_id="circles"
                    style={
                        !lFromState.Circles ? { display: "block" } : { display: "none" }
                    }
                >
                    + Add new {/* circle */} {GetAliasesName(teams, "Circles").toLowerCase()}
                </a>
            </div>

        </div>
    )
}
