import React, { useEffect, useRef } from 'react'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import PillComponent from '../PillComponent';
import TextareaAutosize from "react-textarea-autosize";
import useRestoreScroll from '../../../HelperFunctions/useRestoreScroll';

export default function ProjectList({ tagFilteredMegedData, handleAliesChange, GetAliasesName, filteredPrData, getSortIcon, isInfos, setProjects, pCardHideOnchange, setFocusedMode, noteOnchange, note, handeSorts, setCombinedRefs, handleSave, noteSaveData, projectSearch, setProjectSearch, tagFilteredObj, statesLM, ScrollIntoView, setProject, parentProjectRef, setCardRef, toggleTagCards, handelFocused, projectSubmit, prref, project, handleProjectChange, setProjectName, projectsFrom, compareMode, teams, sPrref, isTag, lFromState }) {

    const ProjectListRef = useRef(null);
    useRestoreScroll(ProjectListRef, "Project", filteredPrData);

    if (!compareMode.active) {
        return (
            <div
                className={
                    Object.keys(tagFilteredMegedData).length > 0 &&
                        tagFilteredMegedData.project &&
                        tagFilteredMegedData.project.length === 0
                        ? "board-col hide"
                        : "board-col"
                }
                data-board_id="projects"
            >
                <div className="list list-bg-red" data-list_id="projects">
                    <div className="dropdown">
                        <h4 className="list-title">
                            <img alt="" src="images/icon-project.png" />
                            <span contentEditable={true}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault(); // Prevent new line
                                    }
                                }} data-name="projects" onBlur={(e) => handleAliesChange(e)} suppressContentEditableWarning={true}>{GetAliasesName(teams, "Projects")}</span>
                            <span>{` (${filteredPrData.length})`}</span>{" "}
                            <a className="list-menu refresh-list hide" style={{cursor: "pointer"}}>
                                <i className="fas fa-sync-alt"></i>
                            </a>
                            <a
                                className="list-menu dropdown-toggle"
                                data-toggle="dropdown"
                                style={{cursor: "pointer"}}
                            >
                                {getSortIcon("Projects")}
                                <i className="fas fa-ellipsis-v"></i>
                            </a>
                            <ul className="dropdown-menu dropdown-menu-right" role="menu">
                                <li role="presentation">
                                    <a
                                        onClick={() => isInfos("Projects")}
                                        role="menuitem"
                                        className="mytpt-list-info"
                                    >
                                        Info
                                    </a>
                                </li>
                                <li role="presentation">
                                    <a role="menuitem" onClick={() => setProjects()}>
                                        Add new {GetAliasesName(teams, "Projects").toLowerCase()}
                                    </a>
                                </li>
                                <li role="presentation">
                                    <a
                                        role="menuitem"
                                        onClick={() => pCardHideOnchange("Projects")}
                                        className="hide_list"
                                    >
                                        Hide list
                                    </a>
                                </li>
                                <li role="presentation">
                                    <a
                                        onClick={() => setFocusedMode("Project")}
                                        role="menuitem"
                                        className="project-mode"
                                    >
                                        Enter project mode
                                    </a>
                                </li>
                                <li role="presentation">
                                    <a role="menuitem" style={{ paddingRight: "5px" }}>
                                        Show Notes
                                        <label className="switch">
                                            <input
                                                name="Projects"
                                                onChange={(e) => noteOnchange(e)}
                                                checked={note.Projects}
                                                className="dd-switch"
                                                type="checkbox"
                                            />
                                            <span className="slider"></span>
                                        </label>
                                    </a>
                                </li>
                                <li role="presentation" className="divider"></li>
                                <li className="dropdown-header">Sort by:</li>

                                <li role="presentation">
                                    <a
                                        role="menuitem"
                                        className="sortcards"
                                        data-sort_order="n"
                                        onClick={() => handeSorts("NewToOld", "Projects")}
                                    >
                                        New to Old
                                    </a>
                                </li>
                                <li role="presentation">
                                    <a
                                        role="menuitem"
                                        className="sortcards"
                                        data-sort_order="nd"
                                        onClick={() => handeSorts("OldToNew", "Projects")}
                                    >
                                        Old to New
                                    </a>
                                </li>
                                <li role="presentation">
                                    <a
                                        role="menuitem"
                                        className="sortcards"
                                        data-sort_order="asc"
                                        onClick={() => handeSorts("AtoZ", "Projects")}
                                    >
                                        A-Z
                                    </a>
                                </li>
                                <li role="presentation">
                                    <a
                                        role="menuitem"
                                        className="sortcards"
                                        data-sort_order="desc"
                                        onClick={() => handeSorts("ZtoA", "Projects")}
                                    >
                                        Z-A
                                    </a>
                                </li>
                            </ul>
                        </h4>
                    </div>
                    <Droppable droppableId="projects" direction="vertical">
                        {(projectsProvided) => (
                            <div
                                // ref={projectsProvided.innerRef}
                                // ref={setCombinedRefs(projectsProvided.innerRef, parentProjectRef)}
                                ref={setCombinedRefs(projectsProvided.innerRef, ProjectListRef)}
                                {...projectsProvided.droppableProps}
                                className="card-list sortable-card-list scrollbar"
                                onScroll={(e) => localStorage.setItem('scrollTopProject', e.target.scrollTop)}
                            >
                                <div
                                    className="form-group notes"
                                    style={
                                        note.Projects ? { display: "block" } : { display: "none" }
                                    }
                                >
                                    <TextareaAutosize
                                        className="notes"
                                        name="Projects"
                                        // defaultValue=''
                                        // value={noteSaveData.Projects}
                                        //   onChange={(e) => handleSave(e)}
                                        onBlur={(e) => handleSave(e)}
                                        placeholder="Notes"
                                        // maxLength="100"
                                        defaultValue={noteSaveData.Projects}
                                        minRows={3} // Set the minimum number of rows
                                    />
                                </div>
                                <span className="clearable">
                                    <input
                                        type="text"
                                        ref={sPrref}
                                        style={{ color: "black" }}
                                        value={projectSearch}
                                        onChange={(e) => setProjectSearch(e.target.value)}
                                        className="form-control search_list transparent"
                                        name="search_list"
                                        placeholder="Start typing to search..."
                                        autoComplete="off"
                                    />
                                    {projectSearch.length > 0 ? (
                                        <i
                                            className="clearable__clear"
                                            onClick={() => setProjectSearch("")}
                                            style={{ display: "inline", color: "black" }}
                                        >
                                            ×
                                        </i>
                                    ) : null}
                                </span>

                                {filteredPrData && filteredPrData.length > 0
                                    ? filteredPrData.map((e, index) => {
                                        if (
                                            isTag &&
                                            Object.keys(tagFilteredObj).length > 0 &&
                                            tagFilteredObj.project &&
                                            tagFilteredObj.project.length > 0 &&
                                            !tagFilteredObj.project.includes(e._id)
                                        ) {
                                            return null;
                                        }
                                        return (
                                            <Draggable
                                                key={index}
                                                draggableId={`${e._id}`}
                                                index={index}
                                                isDragDisabled={
                                                    statesLM.Projects !== "Parsonal" ? true : false
                                                }
                                            >
                                                {(projectsProvided) => (
                                                    <div
                                                        ref={projectsProvided.innerRef}
                                                        {...projectsProvided.draggableProps}
                                                        {...projectsProvided.dragHandleProps}
                                                        // onDragStart={(e) => projectsDragStart(e, index)}
                                                        // onDragEnter={(e) => projectsDragEnter(e, index)}
                                                        // onDragEnd={projectsDrop}
                                                        key={index}
                                                        className={`card ${localStorage.getItem("latest-created") ===
                                                            e._id
                                                            ? "latest-created"
                                                            : ""
                                                            }`}
                                                        data-list_id="projects"
                                                        onClick={(event) => {
                                                            ScrollIntoView();
                                                            if (
                                                                event.target.classList.contains("card") ||
                                                                event.target.classList.contains("card-edit")
                                                            ) {
                                                                setProject(e, filteredPrData, index);
                                                            }
                                                            /* const scrollTop =
                                                                parentProjectRef.current.scrollTop;
                                                            setCardRef(prevCardRef =>
                                                                prevCardRef.map(item =>
                                                                    item.type === "project" ? { ...item, val: scrollTop } : item
                                                                )
                                                            ); */
                                                        }}
                                                    >
                                                        <div className="static-relations">
                                                            {e.tags && e.tags.length > 0
                                                                ? e.tags.map((r, indexs) => (
                                                                    <span
                                                                        onClick={() => toggleTagCards(r)}
                                                                        key={indexs}
                                                                        className="custom-badge green tags"
                                                                    >
                                                                        {r}
                                                                    </span>
                                                                ))
                                                                : null}
                                                        </div>
                                                        <a
                                    /* onClick={() => setProject(e, filteredPrData, index)} */ className="card-edit"
                                                        >
                                                            {e.projectName}
                                                        </a>
                                                        <a className="card-focus">
                                                            <img
                                                                onClick={() =>
                                                                    handelFocused({
                                                                        card: "Projects",
                                                                        index: index,
                                                                        id: e._id,
                                                                    })
                                                                }
                                                                src="./images/newicons/focus.svg"
                                                            />
                                                        </a>
                                                    </div>
                                                )}
                                            </Draggable>
                                        );
                                    })
                                    : null}

                                {projectsProvided.placeholder}

                                <div
                                    className="card2"
                                    style={
                                        lFromState.Projects
                                            ? { display: "block" }
                                            : { display: "none" }
                                    }
                                >
                                    <form
                                        className="form-new-card"
                                        role="form"
                                        onSubmit={projectSubmit}
                                    >
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                ref={prref}
                                                name="card_name"
                                                value={project}
                                                onChange={handleProjectChange}
                                                required
                                                placeholder={`+ Add ${GetAliasesName(teams, "Projects").toLowerCase()} name`}
                                                className="form-control"
                                                autoComplete="off"
                                            />
                                        </div>
                                        <div className="form-group" style={{ marginTop: "10px" }}>
                                            <button
                                                type="submit"
                                                className="btn btn-mytpt add-quick close-add-card"
                                            >
                                                Save
                                            </button>
                                            <a
                                                style={{cursor: "pointer"}}
                                                type="button"
                                                onClick={() => {
                                                    setProjects({ projectName: project });
                                                    setProjectName("");
                                                    lFromState.Projects = false;
                                                }}
                                                className="btn btn-link underline open-tool"
                                            >
                                                Edit details
                                            </a>
                                            <a
                                                style={{cursor: "pointer"}}
                                                onClick={() => projectsFrom()}
                                                className="btn btn-link underline close-add-card"
                                            >
                                                Close
                                            </a>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        )}
                    </Droppable>
                    <a
                        className="btn-list add-card"
                        onClick={() => projectsFrom()}
                        data-list_id="projects"
                        style={
                            !lFromState.Projects ? { display: "block" } : { display: "none" }
                        }
                    >
                        + Add new {/* project */} {GetAliasesName(teams, "Projects").toLowerCase()}
                    </a>
                </div>
            </div>
        );
    } else {
        return <></>;
    }
}
