import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import EChartsComponent from "./EChartsComponent";
import { getTeam, updateTeam } from "../../store/actions/teamAction";
import { GetReviewSubmissionAnalytics } from "../../store/actions/reviewAction";
import Swal from "sweetalert2";
import {
  REVIEW_ERROR_MESSAGE_CLEAR,
  REVIEW_SUCCESS_MESSAGE_CLEAR,
} from "../../store/types/reviewType";

const DummyData = {
  average: [6, 5, 6, 7, 4, 8, 6, 7, 5/* , 6, 7, 8 */],
  date: [
    "January 13, 2024", "February 13, 2024", "March 13, 2024",
    "April 13, 2024", "May 13, 2024", "June 13, 2024",
    "July 13, 2024", "August 13, 2024", "September 13, 2024",
    /* "October 13, 2024", "November 13, 2024", "December 13, 2024" */
  ],
  productivityScores: [6, 8, 9, 7, 5, 8, 6, 7/* , 6, 8, 7, 9 */],
  projectId: "Dummy",
  resilienceScores: [3, 5, 6, 7, 4, 6, 5, 6, 7/* , 5, 6, 8 */],
  wellbeingScores: [9, 2, 5, 6, 8, 7, 4, 5, 6/* , 7, 8, 9 */],
  count: [55, 20, 50, 60, 84, 71, 42, 51, 60]
};


const AnalyticsPage = () => {
  const dispatch = useDispatch();
  const { myInfo, curUserData } = useSelector((state) => state.auth);
  const { teams, template, sMessage, errors } = useSelector(
    (state) => state.teams
  );
  const { reviewAnalyticsData, review_successMessage, review_errorMessage } =
    useSelector((state) => state.review);
  const [data, setData] = useState({ survey: false, surveyFrequency: "" });

  const [analyticsData, setAnalyticsData] = useState({});

  function getNextSurveyDate(frequency) {
    const today = new Date();

    // Helper function to get the next Friday
    function getNextFriday(date) {
      const dayOfWeek = date.getDay();
      const daysUntilFriday = (5 - dayOfWeek + 7) % 7;
      const nextFriday = new Date(date);
      nextFriday.setDate(date.getDate() + daysUntilFriday);
      return nextFriday;
    }

    // Helper function to get the first Friday of the next month
    function getFirstFridayOfNextMonth(date) {
      const nextMonth = new Date(date.getFullYear(), date.getMonth() + 1, 1);
      return getNextFriday(nextMonth);
    }

    // console.log(frequency.toLowerCase());

    switch (frequency.toLowerCase()) {
      case "weekly":
        return getNextFriday(today);

      case "bi-weekly":
        const nextFriday = getNextFriday(today);
        const biWeeklyFriday = new Date(nextFriday);
        biWeeklyFriday.setDate(nextFriday.getDate() + 14); // Add 14 days for bi-weekly
        return biWeeklyFriday;

      case "monthly":
        return getFirstFridayOfNextMonth(today);

      default:
        return "Invalid frequency";
    }
  }

  useEffect(() => {
    document.body.classList.remove('home-page');
    document.body.classList.remove('workflow-page');
    document.body.classList.remove('campfire-page');
    document.body.classList.remove('purpose-tree');
    document.body.classList.add('survey-page');

    dispatch(getTeam({ userId: myInfo.userId }));
  }, []);

  useEffect(() => {
    if (teams.length > 0) {
      let targetTeam = teams.find(
        (t) => t._id === localStorage.getItem("teamId")
      );
      setData((prev) => ({
        ...prev,
        survey: targetTeam.survey,
        surveyFrequency: targetTeam.surveyFrequency,
      }));
      dispatch(
        GetReviewSubmissionAnalytics({
          userId: myInfo.userId,
          teamId: localStorage.getItem("teamId"),
        })
      );
    }
  }, [teams]);

  useEffect(() => {
    if (Object.keys(reviewAnalyticsData).length > 0) {
      // console.log(reviewAnalyticsData)
      /* const firstDataPointAsArray = {
        date: [reviewAnalyticsData.date[0]],
        wellbeingScores: [reviewAnalyticsData.wellbeingScores[0]],
        resilienceScores: [reviewAnalyticsData.resilienceScores[0]],
        productivityScores: [reviewAnalyticsData.productivityScores[0]],
        average: [reviewAnalyticsData.average[0]],
        count: [reviewAnalyticsData.count[0]]
      }; */
      setAnalyticsData(reviewAnalyticsData);
    }
  }, [reviewAnalyticsData]);

  const handleChange = (type, value) => {
    if (type === "checkbox") {
      if (!data.survey) {
        Swal.fire({
          // icon: "info",
          title: "Surveys Activated!",
          showCancelButton: false,
          text: `The next email to all members of this team will be sent on ${getNextSurveyDate(data.surveyFrequency).toLocaleString('en-US', {
            day: 'numeric',
            month: 'short', // Short month name (e.g., Jan, Feb)
            year: 'numeric'
          })}`,
          confirmButtonText: "Ok",
          // cancelButtonText: `Close`,
          // cancelButtonColor: "#EE7364",
        });
      }

      setData((prev) => ({ ...prev, survey: !prev.survey }));
      dispatch(
        updateTeam({
          teamId: localStorage.getItem("teamId"),
          userId: myInfo.userId,
          survey: !data.survey,
          surveyFrequency: data.surveyFrequency,
        })
      );
    } else if (type === "surveyFrequency") {
      // console.log(value);
      setData((prev) => ({ ...prev, surveyFrequency: value }));
      dispatch(
        updateTeam({
          teamId: localStorage.getItem("teamId"),
          userId: myInfo.userId,
          survey: data.survey,
          surveyFrequency: value,
        })
      );
    }
  };

  useEffect(() => {
    // console.log(review_successMessage, review_errorMessage);
    if (review_successMessage) {
      dispatch({ type: REVIEW_SUCCESS_MESSAGE_CLEAR });
      Swal.fire({
        title: "Submission Accepted!",
        showCancelButton: false,
        text: review_successMessage,
      });
      // .then(() => {
      //   navigate("/");
      // });
    }
    if (review_errorMessage) {
      Swal.fire({
        title: "Error",
        showClass: {
          popup: "animate_animated animate_fadeInDown",
        },
        hideClass: {
          popup: "animate_animated animate_fadeOutUp",
        },
        text: review_errorMessage,
      });
      // .then(() => {
      //   navigate("/")
      // });
      dispatch({ type: REVIEW_ERROR_MESSAGE_CLEAR });
    }
  }, [review_successMessage, review_errorMessage]);

  const scrollRef = useRef(null);

  useEffect(() => {
    if (scrollRef.current) {
      // Scroll to the right end
      scrollRef.current.scrollLeft = scrollRef.current.scrollWidth;
    }
  }, [analyticsData]);

  return (
    <div className="container-fluid vertical-center-Tracking">
      <div className="row">
        <div className="col-md-4">
          <div className="tracking-main">
            <div className="tracking-part">
              <h2>📈Tracking Progress</h2>
              <p>
                We’re tracking wellbeing, performance, and resilience via
                surveys filled out by all team members, to ensure a balanced and
                thriving work environment.
              </p>
              <a href="https://teamdecoder.tawk.help/article/surveys" target="_blank">
                Read more
              </a>
            </div>

            <div className="surveys-two">
              <h2>Surveys on/off</h2>
              <label className="switch">
                <input
                  type="checkbox"
                  checked={data.survey}
                  onClick={() => handleChange("checkbox")}
                />
                <span className="slider round"></span>
              </label>
              {/* <input type="checkbox" checked={data.survey} onClick={() => handleChange("checkbox")} style={{display:"none"}}/> */}
            </div>
            <div className="teamdecodersf">
              <h2>
                teamdecoder score & tendency
              </h2>
              {analyticsData &&
                Object.keys(analyticsData).length > 0 &&
                analyticsData.average.length > 0 ? (
                <div className="tendency-number">
                  {analyticsData.average[analyticsData.average.length - 1]}/10{" "}
                  {analyticsData.average[analyticsData.average.length - 1] >
                    (analyticsData.average[analyticsData.average.length - 2] || 0) ? (
                    <img src="/images/uparrow2.png" alt="dummy_data" />
                  ) : (
                    <img src="/images/downarrow2.png" alt="dummy_data" />
                  )}
                </div>
              ) : (
                <div className="tendency-number">
                  {/* {DummyData.average[DummyData.average.length - 1]}/10{" "} */}
                  -/-
                  {/* {DummyData.average[DummyData.average.length - 1] >
                    DummyData.average[DummyData.average.length - 2] ? (
                    <img src="/images/uparrow2.png" alt="dummy_data" />
                  ) : (
                    <img src="/images/downarrow2.png" alt="dummy_data" />
                  )} */}
                </div>
              )}
            </div>
            <div className="teamdecoder-setting">
              <h3>Send survey email</h3>
              <div className="survey-report">
                {/* <label>Send survey email</label> */}
                <div className="dropdown team-list">
                  <div className="btn dropdown-toggle" data-toggle="dropdown">
                    {/* {data.surveyFrequency === ""
                      ? "Select Choice"
                      : data.surveyFrequency.charAt(0).toUpperCase() +
                        data.surveyFrequency.slice(1)} */}

                    {data.surveyFrequency === ""
                      ? "Select Choice"
                      : data.surveyFrequency === "weekly"
                        ? "Every Friday"
                        : data.surveyFrequency === "Bi-weekly"
                          ? "Every other Friday"
                          : data.surveyFrequency === "Monthly"
                            ? "Every 1st Friday of the month"
                            : data.surveyFrequency.charAt(0).toUpperCase() + data.surveyFrequency.slice(1)}

                    <i
                      style={{ marginLeft: "10px" }}
                      className="fa fa-caret-down"
                    ></i>
                  </div>

                  <ul className="dropdown-menu" role="menu">
                    <li
                      role="presentation"
                      onClick={() => {
                        handleChange("surveyFrequency", "weekly");
                      }}
                    >
                      <a role="menuitem" href="#" className="switch-team">
                        Every Friday
                      </a>
                    </li>
                    <li
                      role="presentation"
                      onClick={() => {
                        handleChange("surveyFrequency", "Bi-weekly");
                      }}
                    >
                      <a role="menuitem" href="#" className="switch-team">
                        Every other Friday
                      </a>
                    </li>
                    <li
                      role="presentation"
                      onClick={() => {
                        handleChange("surveyFrequency", "Monthly");
                      }}
                    >
                      <a role="menuitem" href="#" className="switch-team">
                        Every 1st Friday of the month
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              {/* <button>Export</button>
              <button>Discuss now?</button> */}
            </div>
            {/* <div className="export6">
              <h3>Export chart</h3>
              <div className="discuss-report">
                <button>Export</button>
                <button>Discuss now?</button>
              </div>
            </div> */}
            <div className="export6">
              <h3>And now?</h3>
              <p>
                Discuss reasons and make improvements in your next <a href="https://teamdecoder.tawk.help/article/campfire-meetings" target="_blank"
                >campfire meeting</a></p>
            </div>
          </div>
        </div>
        <div className="col-md-8">
          <div className="grgraph-fz"
            ref={scrollRef}
            style={{ overflowX: "auto", whiteSpace: "nowrap" }}
          >
            {analyticsData &&
              analyticsData !== undefined &&
              Object.keys(analyticsData).length > 0 &&
              (analyticsData.date.length !== 0 ||
                analyticsData.productivityScores.length !== 0 ||
                analyticsData.resilienceScores.length !== 0 ||
                analyticsData.wellbeingScores.length !== 0) ? (
              <EChartsComponent analyticData={analyticsData || {}} />
            ) : (
              <EChartsComponent analyticData={DummyData || {}} />
            )}
            <div className="map-duration">
              <ul>
                <li>
                  <span className="wellbeing"></span> <p>Wellbeing</p>
                </li>
                <li>
                  <span className="resiliance"></span> <p>Resiliance </p>
                </li>
                <li>
                  <span className="performance"></span> <p>Performance</p>
                </li>
                <li>
                  <span className="average"></span> <p>Average</p>
                </li>
              </ul>
            </div>
          </div>




          {analyticsData &&
            analyticsData !== undefined &&
            Object.keys(analyticsData).length > 0 &&
            analyticsData.date.length !== 0 &&
            analyticsData.productivityScores.length !== 0 &&
            analyticsData.resilienceScores.length !== 0 &&
            analyticsData.wellbeingScores.length !== 0 ? (
            <></>
          ) : (
            <div className="activatedbox-pouup">
              <img src="/images/activate.png" alt="" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AnalyticsPage;