import React, { useState, useEffect } from "react";

export default function NetworkStatus() {
    const [isOnline, setIsOnline] = useState(navigator.onLine);
    const [countdown, setCountdown] = useState(45); // Initial countdown value

    useEffect(() => {
        const handleOnline = () => {
            setIsOnline(true);
            setCountdown(45);
        };

        const handleOffline = () => {
            setIsOnline(false);
            setCountdown(45);
        };

        window.addEventListener("online", handleOnline);
        window.addEventListener("offline", handleOffline);

        return () => {
            window.removeEventListener("online", handleOnline);
            window.removeEventListener("offline", handleOffline);
        };
    }, []);

    useEffect(() => {
        if (!isOnline) {
            const timer = setInterval(() => {
                setCountdown((prev) => (prev === 0 ? 45 : prev - 1)); // Restart timer at 0
            }, 1000);
            return () => clearInterval(timer);
        }
    }, [isOnline]);

    const handleRetry = () => {
        window.location.reload(); // Reload page to check connection
    };

    return (
        !isOnline && (
            <div
                style={{
                    position: "fixed",
                    top: "0",
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgb(255, 243, 205, 0.9)",
                    color: "#856404",
                    padding: "10px",
                    textAlign: "center",
                    fontSize: "14px",
                    fontWeight: "bold",
                    borderBottom: "2px solid #FFEEBA",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "8px",
                    zIndex: 9
                }}
            >
                <span>⚠️ <b>Not connected.</b> Connecting in {countdown}s...</span>
                <button
                    onClick={handleRetry}
                    style={{
                        backgroundColor: "transparent",
                        border: "none",
                        color: "#007BFF",
                        cursor: "pointer",
                        textDecoration: "underline",
                    }}
                >
                    Try now
                </button>
            </div>
        )
    );
};