import {
    FEEDBACK_ADD_SUCCESS,
    FEEDBACK_ADD_FAIL,
    ALL_FEEDBACK_GET_SUCCESS,
    ALL_FEEDBACK_GET_ERROR,
    FEEDBACK_MESSAGE_CLEAR,
    FEEDBACK_UPDATE_SUCCESS,
    FEEDBACK_UPDATE_FAIL,
    FEEDBACK_DELETE_SUCCESS,
    FEEDBACK_DELETE_FAIL
} from '../types/feedbackType';

const feedbackState = {
    current: null,
    feedbackData: [],
    feedbackMessage: null,
    feedbackError: null
};

export const feedbackReducer = (state = feedbackState, action) => {
    const { type, payload } = action;

    if (type === FEEDBACK_ADD_SUCCESS) {
        return {
            ...state,
            current: payload.current,
            feedbackData: payload.feedbacks.reverse(),
            feedbackMessage: payload.message
        };
    }

    if (type === FEEDBACK_UPDATE_SUCCESS) {
        return {
            ...state,
            current: payload.current,
            feedbackData: payload.feedbacks.reverse(),
            feedbackMessage: payload.message
        };
    }

    if (type === FEEDBACK_ADD_FAIL || type === FEEDBACK_UPDATE_FAIL) {
        return {
            ...state,
            feedbackError: payload.message
        };
    }

    if (type === ALL_FEEDBACK_GET_SUCCESS) {
        return {
            ...state,
            feedbackData: payload.feedbacks.reverse(),
            feedbackMessage: payload.message,
        };
    }

    if (type === ALL_FEEDBACK_GET_ERROR) {
        return {
            ...state,
            feedbackMessage: null,
            feedbackError: payload.message,
        };
    }

    if (type === FEEDBACK_DELETE_SUCCESS) {
        return {
            ...state,
            feedbackMessage: payload.feedbackSuccessMessage,
            feedbackData: payload.feedbacks.reverse(),
            feedbackError: null,
        };
    }

    if (type === FEEDBACK_DELETE_FAIL) {
        return {
            ...state,
            feedbackMessage: null,
            feedbackError: payload.feedbackErrorMessage,
        };
    }

    if (type === FEEDBACK_MESSAGE_CLEAR) {
        return {
            ...state,
            feedbackMessage: null,
            feedbackError: null
        };
    }

    return state;
};
