import React, { useEffect, useRef } from 'react'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import PillComponent from '../PillComponent';
import { FadePopoverTrigger, handleFadeInOut } from './FadeInOutPopover';
import { IsFadeIn, IsFadeOut } from '../../../HelperFunctions/FadeInOutChecker';
import TextareaAutosize from "react-textarea-autosize";
import useRestoreScroll from '../../../HelperFunctions/useRestoreScroll';

export default function FPRolesList({ peoples, showGenericRole, tagFilteredMegedData, handleAliesChange, GetAliasesName, teams, PfoRoles, clickedOnList, toggleEXTnNOCards, getSortIcon, isInfos, pCardHideOnchange, handleDragStart, handleDragEndPF, noteOnchange, note, handleSave, noteSaveData, isTag, isEXT, isNO, TagWiseSorting, tagFilteredObj, extNoFilteredObj, IsOwnerExternal, isSkillRoleLead, setRole, workloadToggle, sumFTEBody_Focus, focused, OwnerExist, toggleTagCards, PRolesSub, SPFRole, fpRref, rolefSearch, setRolefSearch, filteredRFData, PRolesAdd, DPFRole, lFromState, roleSubmit, rref, role, handleRoleChange, setRoles, setRoleName, rolesFrom, list, show, setShow }) {

    const FPRolesList = useRef(null);
    useRestoreScroll(FPRolesList, "Role", filteredRFData);

    return (
        <div
            className={
                !clickedOnList.listName.includes("role") && Object.keys(tagFilteredMegedData).length > 0 &&
                    tagFilteredMegedData.role &&
                    tagFilteredMegedData.role.length === 0
                    ? "board-col hide"
                    : "board-col"
            }
            data-board_id="roles"
        >
            <div className="list list-bg-blue" data-list_id="roles">
                <div className="dropdown">
                    <h4 className="list-title">
                        <img alt="" src="images/icon-role.png" />
                        {/* {GetAliasesName(teams, "Roles")}  (<span>{}</span>){" "} */}
                        <span contentEditable={true}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault(); // Prevent new line
                                }
                            }} data-name="roles" onBlur={(e) => handleAliesChange(e)} suppressContentEditableWarning={true}>{GetAliasesName(teams, "Roles")}</span>
                        <span>{` (${PfoRoles.length})`}</span>
                        <a
                            className={
                                clickedOnList.isClicked &&
                                    clickedOnList.listName.includes("role")
                                    ? "list-menu refresh-list"
                                    : "list-menu refresh-list hide"
                            }
                            style={{cursor: "pointer"}}
                            onClick={() => {
                                toggleEXTnNOCards("roles", "", true);
                            }}
                        >
                            <i className="fas fa-sync-alt"></i>
                        </a>
                        <a
                            className={
                                clickedOnList.isClicked &&
                                    clickedOnList.listName.includes("role")
                                    ? "list-menu dropdown-toggle hide"
                                    : "list-menu dropdown-toggle"
                            }
                            data-toggle="dropdown"
                            style={{cursor: "pointer"}}
                        >
                            {getSortIcon("Roles")}
                            <i className="fas fa-ellipsis-v"></i>
                        </a>
                        <ul className="dropdown-menu dropdown-menu-right" role="menu">
                            <li role="presentation">
                                <a
                                    onClick={() => isInfos("Roles")}
                                    role="menuitem"
                                    className="mytpt-list-info"
                                >
                                    Info
                                </a>
                            </li>
                            <li role="presentation">
                                <a
                                    role="menuitem"
                                    data-toggle="modal"
                                    data-target="#rolesModal"
                                >
                                    Add new {GetAliasesName(teams, "Roles").toLowerCase()}
                                </a>
                            </li>
                            <li role="presentation">
                                <a
                                    role="menuitem"
                                    onClick={() => pCardHideOnchange("Roles")}
                                    className="hide_list"
                                >
                                    Hide list
                                </a>
                            </li>
                            <li role="presentation">
                                <a role="menuitem" style={{ paddingRight: "5px" }}>
                                    Show Notes
                                    <label className="switch">
                                        <input
                                            name="Roles"
                                            onChange={(e) => noteOnchange(e)}
                                            checked={note.Roles}
                                            className="dd-switch"
                                            type="checkbox"
                                        />
                                        <span className="slider"></span>
                                    </label>
                                </a>
                            </li>
                            <li role="presentation" className="divider"></li>
                            <li
                                role="presentation"
                                onClick={() => {
                                    toggleEXTnNOCards("roles", "EXT", false);
                                }}
                            >
                                <a role="menuitem" className="show-external">
                                    Show items owned by external
                                </a>
                            </li>
                            <li
                                role="presentation"
                                onClick={() => {
                                    toggleEXTnNOCards("roles", "NO", false);
                                }}
                            >
                                <a role="menuitem" className="show-noowners">
                                    Show items without owners
                                </a>
                            </li>
                        </ul>
                    </h4>
                </div>

                <DragDropContext
                    onDragStart={handleDragStart}
                    onDragEnd={handleDragEndPF}
                >
                    <div
                        className="card-list sortable-card-list scrollbar"
                        ref={FPRolesList}
                        onScroll={(e) => localStorage.setItem('scrollTopRole', e.target.scrollTop)}
                    >
                        <div
                            className="form-group notes"
                            style={note.Roles ? { display: "block" } : { display: "none" }}
                        >
                            <TextareaAutosize
                                className="notes"
                                name="Roles"
                                // defaultValue=''
                                // value={noteSaveData.Roles}
                                // onChange={(e) => handleSave(e)}
                                onBlur={(e) => handleSave(e)}
                                placeholder="Notes"
                                // maxLength="100"
                                defaultValue={noteSaveData.Roles}
                                minRows={3} // Set the minimum number of rows
                            />
                        </div>

                        <Droppable
                            className="panel panel-default"
                            droppableId="roles-core"
                            direction="vertical"
                        >
                            {(rolesProvidedPf) => (
                                <div
                                    ref={rolesProvidedPf.innerRef}
                                    {...rolesProvidedPf.droppableProps}
                                    id="core"
                                    className="panel-body box-container"
                                >
                                    {PfoRoles && PfoRoles.length > 0
                                        ? PfoRoles.map((e, index) => {
                                            if (isTag || isEXT || isNO) {
                                                let Proceed = TagWiseSorting(
                                                    e,
                                                    isTag,
                                                    isEXT,
                                                    isNO,
                                                    tagFilteredObj,
                                                    extNoFilteredObj,
                                                    "role"
                                                );
                                                if (!Proceed) return;
                                            }
                                            let isExternal = IsOwnerExternal(e, "Role");
                                            let isSRLead = isSkillRoleLead(e, "Role");
                                            return (
                                                <Draggable
                                                    key={e.id}
                                                    draggableId={`${e._id}`}
                                                    index={index}
                                                >
                                                    {(rolesProvidedPf) => (
                                                        <div
                                                            ref={rolesProvidedPf.innerRef}
                                                            {...rolesProvidedPf.draggableProps}
                                                            {...rolesProvidedPf.dragHandleProps}
                                                            // onDragStart={(e) => rolesDragStart(e, index)}
                                                            // onDragEnter={(e) => rolesDragEnter(e, index)}
                                                            // onDragEnd={() => rolesDrops(e, "corRoles")}
                                                            // draggable
                                                            key={e._id}
                                                            className="card"
                                                            data-list_id="roles"
                                                            onClick={(event) => {
                                                                if (
                                                                    event.target.classList.contains("card") ||
                                                                    event.target.classList.contains("card-edit")
                                                                ) {
                                                                    setRole(e, PfoRoles, index);
                                                                }
                                                            }}
                                                        >
                                                            <div className="static-relations">
                                                                {workloadToggle === true && (
                                                                    <PillComponent
                                                                        occupied={sumFTEBody_Focus(
                                                                            e.workload,
                                                                            focused.id
                                                                        )}
                                                                        // total={GetPersonAvailability(peoples,focused.id)}
                                                                        // total={GetTotalWorkLoad(e, "ROLES")}
                                                                        total={e.planTotal}
                                                                        type="TEXT"
                                                                    />
                                                                )}
                                                                {isExternal ? (
                                                                    <span
                                                                        onClick={() => {
                                                                            toggleEXTnNOCards(
                                                                                e._id,
                                                                                "EXT",
                                                                                false,
                                                                                "pill"
                                                                            );
                                                                        }}
                                                                        className="custom-badge blue ext-owner-pill"
                                                                    >
                                                                        EXT
                                                                    </span>
                                                                ) : null}
                                                                {e.owners.length === 0 ||
                                                                    !OwnerExist(e.owners) ? (
                                                                    <span
                                                                        onClick={() => {
                                                                            toggleEXTnNOCards(
                                                                                e._id,
                                                                                "NO",
                                                                                false,
                                                                                "pill"
                                                                            );
                                                                        }}
                                                                        className="custom-badge no-owner-pill red"
                                                                    >
                                                                        NO
                                                                    </span>
                                                                ) : null}
                                                                {e.ownerRole.length > 0 &&
                                                                    e.ownerRole === "Single owner" ? (
                                                                    <span className="custom-badge blue-o single-owner-pill">
                                                                        1
                                                                    </span>
                                                                ) : null}

                                                                {isSRLead ? (
                                                                    <span className="custom-badge direct-lead red-o">
                                                                        LEAD
                                                                    </span>
                                                                ) : null}

                                                                {/* fade-in and fade-out pills - START */}
                                                                {IsFadeIn(focused.id, peoples, e._id) && <span
                                                                    onClick={() => {
                                                                        // Yet to be developed
                                                                        // handleFadeInOut();
                                                                    }}
                                                                    className="custom-badge fade-in"
                                                                >
                                                                    <img src="/images/fadein.png" />
                                                                </span>}

                                                                {/* fade-in and fade-out pills - END */}
                                                                {IsFadeOut(focused.id, peoples, e._id) && <span
                                                                    onClick={() => {
                                                                        // Yet to be developed
                                                                        // handleFadeInOut();
                                                                    }}
                                                                    className="custom-badge fade-out"
                                                                >
                                                                    <img src="/images/fadeout.png" />
                                                                </span>}

                                                                {e.tags && e.tags.length > 0
                                                                    ? e.tags.map((r, indexs) => (
                                                                        <span
                                                                            onClick={() => toggleTagCards(r)}
                                                                            key={indexs}
                                                                            className="custom-badge green tags"
                                                                        >
                                                                            {r}
                                                                        </span>
                                                                    ))
                                                                    : null}
                                                            </div>

                                                            <a
                                                                // onClick={() => setRole(e, PfoRoles, index)}
                                                                className="card-edit"
                                                            >
                                                                {e.roleName}
                                                            </a>
                                                            <a
                                                                className="card-unlink"
                                                                style={{ display: "block", color: "red", right: "25px" }}
                                                            >
                                                                <i
                                                                    onClick={() => PRolesSub(e)}
                                                                    className="icon far fa-times-circle"
                                                                ></i>
                                                            </a>

                                                            <FadePopoverTrigger
                                                                id={e._id}
                                                                focusPeopleId={focused.id}
                                                                show={show}
                                                                setShow={setShow}
                                                                placement={list[list.length - 1] === 'Domains' ? 'left' : 'right'}
                                                                handleFadeInOut={(e, type) => handleFadeInOut(e, type)}
                                                            />
                                                        </div>
                                                    )}
                                                </Draggable>
                                            );
                                        })
                                        : null}

                                    {showGenericRole && SPFRole && SPFRole.length > 0
                                        ? SPFRole.map((d, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    className="card default-role"
                                                    data-default="true"
                                                >
                                                    <div className="static-relations">
                                                        {workloadToggle === true && (
                                                            <PillComponent
                                                                occupied={sumFTEBody_Focus(
                                                                    d.workload,
                                                                    focused.id
                                                                )}
                                                                // total={GetPersonAvailability(peoples,focused.id)}
                                                                // total={GetTotalWorkLoad(e, "ROLES")}
                                                                total={d.planTotal}
                                                                type="TEXT"
                                                            />
                                                        )}
                                                    </div>
                                                    <a
                                                        onClick={() => setRole(d, SPFRole, index)}
                                                        className="card-edit"
                                                    >
                                                        {/* {d.roleName} */}
                                                        {/* {d.roleName ? GetAliasesName(teams, d.roleName.split(" ")[0]) || "" + " " + d.roleName.split(" ")[1] || "" : d.roleName} */}
                                                        {d.roleName
                                                            ? (GetAliasesName(teams, d.roleName.split(" ")[0]) || "") +
                                                            (d.roleName.split(" ")[1] ? " " + d.roleName.split(" ")[1] : "")
                                                            : d.roleName
                                                        }
                                                    </a>
                                                </div>
                                            );
                                        })
                                        : null}

                                    {rolesProvidedPf.placeholder}
                                </div>
                            )}
                        </Droppable>

                        <span className="clearable2">
                            <input
                                type="text"
                                ref={fpRref}
                                style={{ color: "black" }}
                                value={rolefSearch}
                                onChange={(e) => setRolefSearch(e.target.value)}
                                className="form-control search_list2 transparent"
                                name="search_list"
                                placeholder="Search to select more"
                                autoComplete="off"
                            />
                            {rolefSearch.length > 0 ? (
                                <i
                                    className="clearable__clear"
                                    onClick={() => setRolefSearch("")}
                                    style={{ display: "inline", color: "black" }}
                                >
                                    ×
                                </i>
                            ) : null}
                        </span>

                        <Droppable
                            className="panel panel-default"
                            droppableId="roles-notmatched"
                            direction="vertical"
                        >
                            {(rolesProvidedPf) => (
                                <div
                                    ref={rolesProvidedPf.innerRef}
                                    {...rolesProvidedPf.droppableProps}
                                    id="notmatched"
                                    className="panel-body box-container"
                                >
                                    {filteredRFData && filteredRFData.length > 0
                                        ? filteredRFData.map((e, index) => {
                                            if (isTag || isEXT || isNO) {
                                                let Proceed = TagWiseSorting(
                                                    e,
                                                    isTag,
                                                    isEXT,
                                                    isNO,
                                                    tagFilteredObj,
                                                    extNoFilteredObj,
                                                    "role"
                                                );
                                                if (!Proceed) return;
                                            }
                                            let isExternal = IsOwnerExternal(e, "Role");
                                            return (
                                                <Draggable
                                                    key={e.id}
                                                    draggableId={`${e._id}`}
                                                    index={index}
                                                >
                                                    {(rolesProvidedPf) => (
                                                        <div
                                                            ref={rolesProvidedPf.innerRef}
                                                            {...rolesProvidedPf.draggableProps}
                                                            {...rolesProvidedPf.dragHandleProps}
                                                            // onDragStart={(e) => rolesDragStart(e, index)}
                                                            // onDragEnter={(e) => rolesDragEnter(e, index)}
                                                            // onDragEnd={() => rolesDrops(e, "defRoles")}
                                                            // draggable
                                                            key={e._id}
                                                            className={`card notmatched ${localStorage.getItem("latest-created") ===
                                                                e._id
                                                                ? " latest-created "
                                                                : ""
                                                                }`}
                                                            data-list_id="roles"
                                                        >
                                                            <div className="static-relations">
                                                                {isExternal ? (
                                                                    <span
                                                                        onClick={() => {
                                                                            toggleEXTnNOCards(
                                                                                e._id,
                                                                                "EXT",
                                                                                false,
                                                                                "pill"
                                                                            );
                                                                        }}
                                                                        className="custom-badge blue ext-owner-pill"
                                                                    >
                                                                        EXT
                                                                    </span>
                                                                ) : null}
                                                                {e.owners.length === 0 ? (
                                                                    <span
                                                                        onClick={() => {
                                                                            toggleEXTnNOCards(
                                                                                e._id,
                                                                                "NO",
                                                                                false,
                                                                                "pill"
                                                                            );
                                                                        }}
                                                                        className="custom-badge no-owner-pill red"
                                                                    >
                                                                        NO
                                                                    </span>
                                                                ) : null}
                                                                {e.ownerRole.length > 0 &&
                                                                    e.ownerRole === "Single owner" ? (
                                                                    <span className="custom-badge blue-o single-owner-pill">
                                                                        1
                                                                    </span>
                                                                ) : null}
                                                                {e.tags && e.tags.length > 0
                                                                    ? e.tags.map((r, indexs) => (
                                                                        <span
                                                                            onClick={() => toggleTagCards(r)}
                                                                            key={indexs}
                                                                            className="custom-badge green tags"
                                                                        >
                                                                            {r}
                                                                        </span>
                                                                    ))
                                                                    : null}
                                                            </div>

                                                            <a
                                                                onClick={() =>
                                                                    setRole(e, filteredRFData, index)
                                                                }
                                                                className="card-edit"
                                                            >
                                                                {e.roleName}
                                                            </a>

                                                            <a
                                                                className="card-link"
                                                                style={{ display: "block" }}
                                                            >
                                                                <i
                                                                    onClick={() => PRolesAdd(e)}
                                                                    className="icon fa fa-plus-circle"
                                                                ></i>
                                                            </a>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            );
                                        })
                                        : null}

                                    {DPFRole && DPFRole.length > 0
                                        ? DPFRole.map((d, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    className="card default-role"
                                                    data-card_name="Circle Lead"
                                                    data-default="true"
                                                    style={{ display: "none" }}
                                                >
                                                    <a
                                                        onClick={() => setRole(d, DPFRole)}
                                                        className="card-edit"
                                                    >
                                                        {d.roleName}
                                                    </a>
                                                </div>
                                            );
                                        })
                                        : null}

                                    {rolesProvidedPf.placeholder}
                                </div>
                            )}
                        </Droppable>

                        <div
                            className="card2"
                            style={
                                lFromState.Roles ? { display: "block" } : { display: "none" }
                            }
                        >
                            <form className="form-new-card" role="form" onSubmit={roleSubmit}>
                                <div className="form-group">
                                    <input
                                        type="text"
                                        ref={rref}
                                        name="card_name"
                                        required
                                        placeholder={`Add ${GetAliasesName(teams, "Roles").toLowerCase()} name`}
                                        className="form-control"
                                        value={role}
                                        onChange={handleRoleChange}
                                        autoComplete="off"
                                    />
                                </div>
                                <div className="form-group" style={{ marginTop: "10px" }}>
                                    <button type="submit" className="btn btn-mytpt add-quick">
                                        Save
                                    </button>
                                    <a
                                        style={{cursor: "pointer"}}
                                        type="button"
                                        onClick={() => {
                                            setRoles({ roleName: role });
                                            setRoleName("");
                                            lFromState.Roles = false;
                                        }}
                                        className="btn btn-link underline open-tool"
                                    >
                                        Edit details
                                    </a>
                                    <a
                                        style={{cursor: "pointer"}}
                                        onClick={() => rolesFrom()}
                                        className="btn btn-link underline close-add-card"
                                    >
                                        Close
                                    </a>
                                </div>
                            </form>
                        </div>
                    </div>
                </DragDropContext>
                <a
                    className="btn-list add-card"
                    onClick={() => rolesFrom()}
                    data-list_id="roles"
                    style={!lFromState.Roles ? { display: "block" } : { display: "none" }}
                >
                    + Add new {/*role */} {GetAliasesName(teams, "Roles").toLowerCase()}
                </a>
            </div>
        </div>
    )
}
