import React, { useState } from 'react';
import TextareaAutosize from "react-textarea-autosize";
import Select from "react-select";
import { GetAliasesName } from '../../HelperFunctions/GetAliasesName';

export default function WorkFlowComponent({ teams, wfindex, workflow, workflows, addStep, deleteStep, saveWorkflow, copyWorkflow, deleteWorkflow, handleChange, handleBlur, workflowOwnerTypeOptions, workflowOwnerOptions, selectedWorkflowOwnerTypes, selectedWorkflowOwners, selectedStepOwners, report, peopleId, isPresentInStep }) {
    
    const groupStyles = {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    };

    const groupBadgeStyles = {
        backgroundColor: "#EBECF0",
        borderRadius: "2em",
        color: "#172B4D",
        display: "inline-block",
        fontSize: 12,
        fontWeight: "normal",
        lineHeight: "1",
        minWidth: 1,
        padding: "0.16666666666667em 0.5em",
        textAlign: "center",
    };

    const formatGroupLabel = (GroupedOption) => {
        return (
            <div style={groupStyles}>
                <span> {GetAliasesName(teams, GroupedOption.label)} </span>
                <span style={groupBadgeStyles}>{GroupedOption.options.length}</span>
            </div>
        )
    };

    return (
        <div className="workflow" key={wfindex} style={report ? { paddingBottom: "unset" } : undefined}>
            <div className="item wf">
                <div className="title">Workflow {workflow.owner === peopleId && <span className="custom-badge red-o">YOU</span>}</div>
                <div className="name">
                    <input type="text" className="form-control" placeholder="Workflow name" value={workflows[wfindex].name}
                        onChange={(e) => handleChange(wfindex, null, "name", e.target.value)}
                        onBlur={(e) => handleBlur(wfindex)}
                        disabled={report} />
                </div>
                <div className="owner-type">
                    <Select
                        isDisabled={report}
                        closeMenuOnSelect={true}
                        isClearable
                        isSearchable
                        name="ownerType"
                        classNamePrefix="wfselect"
                        className="single-select-container"
                        placeholder="Owner"
                        menuPortalTarget={document.body}  // Moves dropdown outside parent
                        formatGroupLabel={formatGroupLabel}
                        options={workflowOwnerTypeOptions}
                        // menuIsOpen={true}
                        // value={selectedWorkflowOwnerTypes[wfindex]}
                        value={
                            selectedWorkflowOwnerTypes[wfindex] &&
                                selectedWorkflowOwnerTypes[wfindex].value &&
                                selectedWorkflowOwnerTypes[wfindex].value.length > 0
                                ? [
                                    {
                                        value: selectedWorkflowOwnerTypes[wfindex].value,
                                        label: (
                                            <div style={{ fontSize: "14px" }}>
                                                {GetAliasesName(teams, selectedWorkflowOwnerTypes[wfindex].label)}&nbsp;
                                                <span className="custom-badge blue" style={{ display: 'inline-block' }}>
                                                    {`${GetAliasesName(teams, selectedWorkflowOwnerTypes[wfindex].groupLabel)}`}
                                                </span>
                                            </div>
                                        ),
                                    },
                                ]
                                : []
                        }
                        onChange={(selectedOption) => {
                            handleChange(wfindex, null, "ownerType", selectedOption ? selectedOption.value : null);
                        }}

                        onBlur={(e) => handleBlur(wfindex)} // Triggers when menu closes
                    />
                </div>

                {selectedWorkflowOwnerTypes[wfindex] && selectedWorkflowOwnerTypes[wfindex].value && selectedWorkflowOwnerTypes[wfindex].value.length > 0 &&
                    <div className="owner">
                        <Select
                            isDisabled={report}
                            closeMenuOnSelect={true}
                            isClearable
                            isSearchable
                            name="owner"
                            options={workflowOwnerOptions[wfindex]}
                            classNamePrefix="wfselect"
                            className="single-select-container"
                            placeholder="Responsible person"
                            menuPortalTarget={document.body}  // Moves dropdown outside parent
                            // value={selectedWorkflowOwners[wfindex]}

                            value={
                                selectedWorkflowOwners[wfindex] &&
                                    selectedWorkflowOwners[wfindex].value &&
                                    selectedWorkflowOwners[wfindex].value.length > 0
                                    ? [
                                        {
                                            value: selectedWorkflowOwners[wfindex].value,
                                            label: (
                                                <div style={{ fontSize: "14px" }}>
                                                    {selectedWorkflowOwners[wfindex].label}&nbsp;
                                                    <span className="custom-badge yellow" style={{ display: 'inline-block' }}>
                                                        {`${selectedWorkflowOwners[wfindex].groupLabel}`}
                                                    </span>
                                                </div>
                                            ),
                                        },
                                    ]
                                    : []
                            }

                            // menuIsOpen={true}
                            onChange={(selectedOption) => {
                                handleChange(wfindex, null, "owner", selectedOption ? selectedOption.value : null);
                            }}

                            onBlur={(e) => handleBlur(wfindex)} // Triggers when menu closes
                        />
                    </div>
                }

                <div className="notes">
                    <TextareaAutosize
                        disabled={report}
                        name="notes"
                        className="form-control notes"
                        placeholder={!report ? "Write any notes you need here—highlight key points, mention or specify areas to focus on..." : ""}
                        value={workflows[wfindex].notes}
                        minRows={3} // Set minimum rows
                        // maxLength="100"
                        autoFocus={null}
                        onChange={(e) => handleChange(wfindex, null, "notes", e.target.value)}
                        onBlur={(e) => handleBlur(wfindex)}
                    />
                </div>
            </div>

            {workflows[wfindex].steps.map((step, index) => {
                return (
                    <div className={`item st step-${index}`} key={index}>
                        <div className="title">Step {index + 1} {report && isPresentInStep(peopleId, workflow, index) && <span className="custom-badge red-o" style={{ marginBottom: "unset" }}>YOU</span>} {!report && <a className="delete-step" onClick={(e) => deleteStep(wfindex, index)}><img src="/images/newicons/delete.svg" alt="Delete" /></a>}</div>
                        <div className="name">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Step name"
                                value={step.name}
                                onChange={(e) => handleChange(wfindex, index, "name", e.target.value)}
                                onBlur={(e) => handleBlur(wfindex)}
                                disabled={report}
                            />
                        </div>
                        <div className="owners">
                            <Select
                                isDisabled={report}
                                isMulti
                                isClearable
                                isSearchable
                                closeMenuOnSelect={false}
                                placeholder="Owner"
                                classNamePrefix="wfselect"
                                className="multi-select-container"
                                name="owner"
                                formatGroupLabel={formatGroupLabel}
                                options={workflowOwnerTypeOptions}
                                menuPortalTarget={document.body}  // Moves dropdown outside parent
                                // value={(selectedStepOwners[wfindex] && selectedStepOwners[wfindex][index]) || []}
                                value={
                                    selectedStepOwners &&
                                        selectedStepOwners[wfindex] &&
                                        selectedStepOwners[wfindex][index] &&
                                        selectedStepOwners[wfindex][index].length > 0
                                        ? selectedStepOwners[wfindex][index].map((owner) => ({
                                            value: owner.value,
                                            label: (
                                                <div style={{ fontSize: "14px" }}>
                                                    {owner.label}&nbsp;
                                                    <span className="custom-badge blue" style={{ display: "inline-block" }}>
                                                        {`${GetAliasesName(teams, owner.groupLabel)}`}
                                                    </span>
                                                </div>
                                            ),
                                        }))
                                        : []
                                }

                                // menuIsOpen={true}
                                onChange={(selectedOptions) => {
                                    handleChange(wfindex, index, "owners", selectedOptions && selectedOptions.length > 0 ? selectedOptions.map(opt => opt.value) : [])
                                }}

                                onBlur={(e) => handleBlur(wfindex)} // Triggers when menu closes

                            />
                        </div>
                        <div className="notes">
                            <TextareaAutosize
                                disabled={report}
                                name="notes"
                                className="form-control notes"
                                placeholder={!report ? "Write any notes you need here—highlight key points, mention or specify areas to focus on..." : ""}
                                value={step.notes}
                                minRows={3} // Set minimum rows
                                // maxLength="100"
                                autoFocus={null}
                                onChange={(e) => handleChange(wfindex, index, "notes", e.target.value)}
                                onBlur={(e) => handleBlur(wfindex)}
                            />
                        </div>
                    </div>
                )
            })}

            {!report &&
                <>
                    <div className="item new-step" onClick={addStep}>
                        <span>+ Add a new step</span>
                    </div>

                    <div className="actions">
                        {/* <a className="save-workflow" onClick={() => saveWorkflow(wfindex)}><i className="fa fa-check"></i>&nbsp;Save workflow</a> */}

                        {workflow._id !== null && <a className="copy-workflow" onClick={() => copyWorkflow(wfindex)}><img src="/images/newicons/copy.svg" alt="Copy" />&nbsp;Copy workflow</a>}

                        <a className="delete-workflow" onClick={() => deleteWorkflow(wfindex)}><img src="/images/newicons/delete.svg" alt="Delete" />&nbsp;Delete workflow</a>
                    </div>
                </>
            }
        </div>
    );
}
