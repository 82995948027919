import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { REVIEW_ERROR_MESSAGE_CLEAR, REVIEW_SUCCESS_MESSAGE_CLEAR } from '../../store/types/reviewType';
import jwtDecode from 'jwt-decode';
import { ReviewSubmission } from '../../store/actions/reviewAction';
import { CLIENT_URL } from '../../config/keys';
const StarRating = ({ score, setScore }) => {
    const stars = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    return (
        <div className="star-rating">
            {stars.map((star) => (
                <span
                    key={star}
                    onClick={() => setScore(star)}
                    style={{
                        color: star <= score ? '#48DFB6' : '#fff'
                    }}
                >
                    ★
                </span>
            ))}
        </div>
    );
};

export default function ReviewPage() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { loading, successMessage, error, authenticate, myInfo } = useSelector(state => state.auth);
    const { review_successMessage, review_errorMessage } = useSelector(state => state.review);

    let { token } = useParams();
    let { userId, teamId, peopleId, sentDate } = jwtDecode(token);
    // console.log({ userId, teamId, peopleId, sentDate });

    const [wellbeingScore, setWellbeingScore] = useState(0);
    const [productivityScore, setProductivityScore] = useState(0);
    const [resilienceScore, setResilienceScore] = useState(0);

    let [switchToExpirComponent, setSwitchToExpirComponent] = useState(false);

    useEffect(() => {
        document.body.style = 'background-color: #f3d168';
        document.body.classList.add('no-app-pages');
    }, []);

    useEffect(() => {
        if (sentDate) {
            if (Date.now() - new Date(sentDate).getTime() > 1000 * 60 * 60 * 120) { // 120 hours validity
                setSwitchToExpirComponent(true)
            }
        }
    }, [sentDate]);

    const handleSend = () => {
        if (wellbeingScore === 0 || productivityScore === 0 || resilienceScore === 0) {
            Swal.fire({
                title: "Error",
                text: "Please submit ratings to all the three questions!",
                showCancelButton: false,
            })
            return false;
        };
        dispatch(ReviewSubmission({ userId, teamId, peopleId, sentDate, wellbeingScore, productivityScore, resilienceScore }));
    }

    useEffect(() => {
        // console.log(review_successMessage, review_errorMessage);
        if (review_successMessage) {
            setWellbeingScore(0);
            setResilienceScore(0);
            setProductivityScore(0);
            dispatch({ type: REVIEW_SUCCESS_MESSAGE_CLEAR });
            Swal.fire({
                title: "Submission Accepted!",
                showCancelButton: false,
                text: review_successMessage,
            }).then(() => {
                window.location.href = CLIENT_URL+"/request-report";
            });
        };
        if (review_errorMessage) {
            setWellbeingScore(0);
            setResilienceScore(0);
            setProductivityScore(0);
            Swal.fire({
                title: "Error",
                showClass: {
                    popup: "animate_animated animate_fadeInDown",
                },
                hideClass: {
                    popup: "animate_animated animate_fadeOutUp",
                },
                text: review_errorMessage,
            }).then(()=>{
                // navigate("/")
            });
            dispatch({ type: REVIEW_ERROR_MESSAGE_CLEAR });
        };
    }, [review_successMessage, review_errorMessage]);
    
    return (
        <div className="onboarding-wizard full-height d-flex justify-content-center align-items-center">
            <div className="container">
                <div className="row">
                    <div className="col-md-2 col-sm-12 mb-5"></div>
                    <div className="col-md-8 col-sm-12">
                        {!switchToExpirComponent ?
                            (<div className="login-from">
                                <form className='review-form'>
                                    {/* <div id="welcome" className="tab-content"> */}
                                    {/* Wellbeing Score */}
                                    <div className="score-section text-center">
                                        <h3 className='review-question-tag'>Wellbeing Score</h3>
                                        <h2 className='review-question'>How do you feel today?</h2>
                                        <StarRating score={wellbeingScore} setScore={setWellbeingScore} />
                                    </div>

                                    {/* Productivity Score */}
                                    <div className="score-section text-center mt-5">
                                        <h3 className='review-question-tag'>Productivity Score</h3>
                                        <h2 className='review-question'>How effectively can you manage your tasks?</h2>
                                        <StarRating score={productivityScore} setScore={setProductivityScore} />
                                    </div>

                                    {/* Resilience Score */}
                                    <div className="score-section text-center mt-5">
                                        <h3 className='review-question-tag'>Resilience Score</h3>
                                        <h2 className='review-question'>How strong is your team?</h2>
                                        <StarRating score={resilienceScore} setScore={setResilienceScore} />
                                    </div>

                                    {/* Buttons */}
                                    <div className="review-button-group mt-5 text-center">
                                        <button
                                            type="button"
                                            className="button button-darkgrey-v46"
                                            onClick={handleSend}
                                            style={{ width: "20%" }}
                                        >
                                            Send
                                        </button>
                                        {/* <button
                                            type="button"
                                            className="button button-white-v46"
                                            onClick={() => console.log('Visit score panel clicked')}
                                        >
                                            Visit score panel
                                        </button> */}
                                    </div>
                                    {/* </div> */}
                                </form>
                            </div>) : (<div className="login-from">
                                <form className='review-form'>
                                    {/* <div id="welcome" className="tab-content"> */}
                                    {/* Wellbeing Score */}
                                    <div className="score-section text-center">
                                        <h3 className='review-question-tag'>Oops</h3>
                                        <h2 className='review-question'>Your link has been expired</h2>
                                    </div>
                                </form>
                            </div>)}
                    </div>
                    <div className="col-md-2 col-sm-12 mb-5"></div>
                </div>
            </div>
        </div>
    );
}
