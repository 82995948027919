import React, { useState, useEffect } from 'react'
import CreatableSelect from 'react-select/creatable';
import makeAnimated from 'react-select/animated';
// import { colourOptions } from './data';
import { useDispatch, useSelector } from 'react-redux';
import { tagCreate } from '../../store/actions/tagsAction'


const animatedComponents = makeAnimated();

export default function SingleSelet({ data, people, skill, domain, link, project, circle, role }) {
  let dispatch = useDispatch()
  const { tags, tagsMessage, tagsError } = useSelector(state => state.tags)

  const { loading, successMessage, error, authenticate, myInfo } = useSelector(state => state.auth)


  let arrs = []
  if (tags && tags.length > 0) {
    tags.map(e => e.tags.map(s => {
      let inc = arrs.includes(s)
      if (!inc) {
        arrs.push(s)
      }
    }))
  }

  var arr = []
  arrs.map(e => {
    arr.push({ value: e, label: e })
  })


  var cumTags = []

  if (data && data.tags && data.tags.length > 0) {
    for (let i = 0; i < data.tags.length; i++) {
      cumTags.push({ value: `${data.tags[i]}`, label: `${data.tags[i]}` })
    }
  }


  const options = arr

  const [inputValue, setInputValue] = React.useState('');
  const [value, setValue] = React.useState(cumTags);


  let newTag = []

  if (value && value.length > 0) {
    for (let i = 0; i < value.length; i++) {
      let inc = arrs.includes(value[i].value)
      if (!inc) {
        newTag.push(value[i].value)
      }

    }
  }


  let tagData = []
  if (value) {
    for (let i = 0; i < value.length; i++) {
      tagData.push(value[i].value)
    }
    if (people) {
      people.tags = tagData
      people.newTag = newTag
    } else if (skill) {
      skill.tags = tagData
      skill.newTag = newTag
    } else if (domain) {
      domain.tags = tagData
      domain.newTag = newTag
    } else if (link) {
      link.tags = tagData
      link.newTag = newTag
    } else if (project) {
      project.tags = tagData
      project.newTag = newTag
    } else if (circle) {
      circle.tags = tagData
      circle.newTag = newTag
    } else if (role) {
      role.tags = tagData
      role.newTag = newTag
    }
  }


  return (
    <>
      <div>
        <CreatableSelect
          classNamePrefix="select"
          className="multiple-select-container"
          closeMenuOnSelect={false}
          inputValue={inputValue}
          components={animatedComponents}
          isClearable
          isMulti
          isSearchable
          onChange={(newValue) => setValue(newValue)}
          onInputChange={(newValue) => setInputValue(newValue)}
          placeholder="+ Add Tags"
          options={options}
          value={value}
          isDisabled={false}
          styles={{
            /* control: (baseStyles, state) => ({
              ...baseStyles,
              borderColor: state.isFocused ? '' : 'white',
              minWidth: '200px'
            }), */

            option: (baseStyles) => ({
              ...baseStyles,
              backgroundColor: 'white',
              width: 'max-content',
              borderRadius: '3px',
              marginBottom: '10px',
              marginRight: '10px',
              display: 'inline-block',
              padding: '3px',
              cursor: 'pointer',
              fontSize: '14px',
              ':hover': {
                backgroundColor: '#E3E5EA'
              }
            })
          }}
        // menuIsOpen={true}
        />
      </div>
      {/* {
        (value === value) ? (
          <div> + Add Tags</div>
        )
          :
          (<></>)
      } */}

    </>
  )
}