import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  HISTORY_MESSAGE_CLEAR,
  HISTORY_DATAS_CLEAR,
} from "../../../store/types/historyType";
import { historyGet } from "../../../store/actions/historyAction";
import Documents from "../../Body/Modal/Documents";
import Feedback from "../../Feedback";
import History from "../../History";

export default function PeopleModal({ data, closePeople, peopleId, feedbackData }) {
  const {
    error,
    successMessage,
    reportInfo,
    reportTeam,
    reportPeople,
    reportSkill,
    reportRole,
    reportDomain,
    reportLink,
    reportCircle,
    reportProject,
    reportMeeting,
  } = useSelector((state) => state.authReport);

  const { historys, historyMessagee, historyError, historyMessage } = useSelector((state) => state.historys);

  const [showFeedback, setShowFeedback] = useState(false);

  const [showHistory, setShowHistory] = useState(false);

  const [allHistory, setAllHistory] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (data && data._id) {
      dispatch(historyGet({ cardId: data._id }));
    }
  }, [data])

  //mentor
  let curMentor = {};

  if (
    data &&
    data.mentor &&
    data.mentor.length > 0 &&
    reportPeople.length > 0
  ) {
    for (let i = 0; i < reportPeople.length; i++) {
      if (reportPeople[i]._id === data.mentor) {
        curMentor = reportPeople[i];
      }
    }
  }

  //mentees
  let curMentees = [];
  if (data && data.mentees.length > 0 && reportPeople.length > 0) {
    for (let i = 0; i < data.mentees.length; i++) {
      for (let j = 0; j < reportPeople.length; j++) {
        if (reportPeople[j]._id === data.mentees[i]) {
          curMentees.push(reportPeople[j]);
        }
      }
    }
  }

  const ClosePeopleModal = () => {
    dispatch({ type: HISTORY_DATAS_CLEAR });
    closePeople()
  }

  return (
    <div id="peopleModal" role="dialog">
      <div className="modal-dialog modal-lg modal-dialog-scrollable report-modal">
        <form className="form">
          {/* Modal content*/}
          <div className="modal-content">
            <div className="modal-header header-yellow">
              <h4 className="modal-title">
                <img
                  src="../images/icon-people.png"
                  style={{
                    width: "17px",
                    marginTop: "-3px",
                    marginRight: "5px",
                  }}
                />{" "}
                PERSON
              </h4>
            </div>
            <div className="modal-body">
              <div className="alert"></div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <div className="input-group mb-3">
                      <input
                        type="hidden"
                        id="first_name"
                        name="first_name"
                        value={data.fname}
                      />
                      <div
                        name="first_name"
                        className="form-control tool-name"
                        placeholder="First name"
                      >
                        {data.fname}
                      </div>
                    </div>
                    <div className="input-group mb-3">
                      <input
                        type="hidden"
                        id="last_name"
                        name="last_name"
                        value={data.lname}
                      />
                      <div
                        name="last_name"
                        className="form-control tool-name"
                        placeholder="Last name"
                      >
                        {data.lname}
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <input
                      type="phone"
                      name="phone"
                      value={data.mobile}
                      className="form-control"
                      placeholder="+ Add phone number"
                      autoComplete="off"
                      disabled="disabled"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="email"
                      name="email"
                      value={data.email}
                      className="form-control"
                      placeholder="+ Add email address"
                      autoComplete="off"
                      disabled="disabled"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="radio"
                      className="logit"
                      id="internal"
                      name="member_type"
                      value="Internal"
                      checked={data.workRole === "Internal" ? true : false}
                      disabled="disabled"
                    />
                    <label htmlFor="internal" className="inline-label">Internal</label>

                    <input
                      type="radio"
                      className="logit"
                      id="external"
                      name="member_type"
                      value="External"
                      checked={data.workRole === "External" ? true : false}
                      disabled="disabled"
                    />
                    <label htmlFor="external" className="inline-label">External</label>
                  </div>
                  <div className="form-group flag" style={{ display: "none" }}>
                    <label>
                      People marked external will be flagged:{" "}
                      <span className="custom-badge blue">EXT</span>
                    </label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mentor">
                    <h4>Mentor</h4>
                    {Object.keys(curMentor).length > 0 ? (
                      <div className="selectize-control mentor s_selectize readonly single">
                        <div className="report-tool-item">{`${curMentor.fname} ${curMentor.lname}`}</div>
                      </div>
                    ) : null}
                  </div>

                  <div className="form-group">
                    <h4>Mentees</h4>
                    {curMentees && curMentees.length > 0
                      ? curMentees.map((e, index) => {
                        return (
                          <div key={index} className="report-tool-item">
                            {/* {e.workRole === "External" ? (
                                <span className="custom-badge blue">EXT</span>
                              ) : null}
                              {`${e.fname} ${e.lname}`}{" "} */}

                            {e.workRole === "External" ? (
                              <><span className="custom-badge blue" style={{ display: 'inline-block', marginBottom: '0' }}>EXT</span> {`${e.fname} ${e.lname}`}</>
                            ) : `${e.fname} ${e.lname}`}
                          </div>
                        );
                      })
                      : null}
                  </div>
                  <Documents data={data} type="MY-REPORT" />

                  <div className="form-group">
                    <h4>Tags</h4>
                    <div className="tagator_element">
                      {data.tags && data.tags.length > 0
                        ? data.tags.map((e, index) => {
                          return (
                            <div key={index} className="tagator_tag">
                              {e}
                            </div>
                          );
                        })
                        : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <div className="row">
                <div className="col-xs-4 text-left">
                  <button
                    onClick={() => { setShowHistory(false); setShowFeedback(!showFeedback) }}
                    style={
                      showFeedback
                        ? { backgroundColor: "#F3D068" }
                        : null
                    }
                    type="button"
                    className="btn btn-mytpt-gray history"
                  >
                    <img alt="Feedback" style={{ width: "20px" }} src="../images/feedback.png" />
                  </button>

                  <button
                    onClick={() => { setShowHistory(!showHistory); setShowFeedback(false); }}
                    style={
                      showHistory
                        ? { backgroundColor: "#F3D068" }
                        : null
                    }
                    type="button"
                    className="btn btn-mytpt-gray history"
                  >
                    {/* <i className="fa fa-history"></i> */}
                    <img alt="" src="../images/newicons/history.svg" />
                  </button>
                </div>
                <div className="col-xs-8 text-right">
                  <button
                    type="button"
                    onClick={ClosePeopleModal}
                    data-tippy-content="Close Tool"
                    className="btn btn-mytpt-red"
                    data-dismiss="modal"
                  >
                    <i className="fa fa-times"></i>
                  </button>
                </div>
              </div>

              {showHistory ? (
                <div className="row activities">
                  <div className="col-xs-12">
                    <h4>History</h4>
                    <History historys={historys} historyMessagee={historyMessagee} historyError={historyError} allHistory={allHistory} setAllHistory={setAllHistory} />
                  </div>
                </div>
              ) : null}

              {showFeedback ? (
                <div className="row activities">
                  <div className="col-xs-12">
                    <h4>Feedback</h4>
                    <Feedback teamId={localStorage.getItem('teamId')} cardId={data._id} peopleId={peopleId} feedbackData={feedbackData} />
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
