import React, { useState, useEffect, useRef } from "react";
import Tags from "../Tags";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { useDispatch, useSelector } from "react-redux";
import { TagsInput } from "react-tag-input-component";
import TextareaAutosize from "react-textarea-autosize";
// import { getTeam } from '../../../store/actions/teamAction';
import { createProject, getProject, updateProject, deleteProject } from "../../../store/actions/projectAction";
import { useAlert } from "react-alert";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import { SERVER_URI } from "../../../config/keys";
import { PROJECT_SUCCESS_MESSAGE_CLEAR, PROJECT_ERROR_CLEAR, PROJECT_ID_CLEAR } from "../../../store/types/projectType";
import { createMeetings, updateMeetings, getMeetings, deleteMeetings } from "../../../store/actions/meetingAction";
import { MEETINGS_SUCCESS_MESSAGE_CLEAR, MEETINGS_ERROR_CLEAR } from "../../../store/types/meetingType";
import { TAGS_SUCCESS_MESSAGE_CLEAR } from "../../../store/types/tagsType";
import { getTags, tagCreate } from "../../../store/actions/tagsAction";
import { updateState, getState } from "../../../store/actions/stateAction";
import { historyGet, historyCreate } from "../../../store/actions/historyAction";
import { createMember, getMember } from "../../../store/actions/memberAction";
import { HISTORY_MESSAGE_CLEAR, HISTORY_DATAS_CLEAR } from "../../../store/types/historyType";

import { createCircle, getCircle, updateCircle, deleteCircle } from "../../../store/actions/circleAction";
import { CIRCLE_SUCCESS_MESSAGE_CLEAR, CIRCLE_ERROR_CLEAR, CIRCLE_ID_CLEAR } from "../../../store/types/circleType";
import Objectives from "./Objectives";
import Tasks from "./Tasks";
import { freezeWindow, unfreezeWindow } from '../../../../src/HelperFunctions/Overlay';
import Documents from "./Documents";
import { GetAliasesName } from "../../../HelperFunctions/GetAliasesName";
import History from "../../History";
import Feedback from "../../Feedback";

export default function ProjectModel({ data, closeProject, setProjects, CloseState, stateCloseState, isNToP, focusMode, setFocused, focused, clearModes, teams, setPurposetreeLoader, feedbackData, setCampfireComponent }) {
  const animatedComponents = makeAnimated();
  let dispatch = useDispatch();

  const { loading, successMessage, error, authenticate, myInfo, curUserData } = useSelector((state) => state.auth);
  const { meetings, meetingsMessage, meetingId, meetingsMessagee, meetingsError } = useSelector((state) => state.meetings);
  const { peoples, peopleMessagee, peopleError } = useSelector((state) => state.peoples);
  const { domains, domainMessage, domainError } = useSelector((state) => state.domains);
  const { skills, skillMessagee, killError } = useSelector((state) => state.skills);
  const { roles, roleMessagee, roleError } = useSelector((state) => state.roles);
  const { links, cuLinkId, linkMessagee, linkError } = useSelector((state) => state.links);
  const { projects, cuProjectId, projectMessagee, projectError } = useSelector((state) => state.projects);
  const { states, cuStateId, statesMessagee, statesError, statesMessage } = useSelector((state) => state.states);
  const { historys, historyMessagee, historyError, historyMessage } = useSelector((state) => state.historys);
  const { members, memberMessagee, memberError, memberMessage } = useSelector((state) => state.members);
  const { pfmembers, pfmemberMessagee, pfmemberError, pfmemberMessage } = useSelector((state) => state.pfmembers);

  const [showFeedback, setShowFeedback] = useState(false);

  const [showHistory, setShowHistory] = useState(false);

  const [allHistory, setAllHistory] = useState(false);

  useEffect(() => {
    if (data && data._id) {
      dispatch(historyGet({ cardId: data._id }));
    }

    if(data) {
      setDocument(data.documents);
    }
  }, [data])

  let adminId = myInfo.userId;
  let teamId = localStorage.getItem("teamId");
  let url = `${SERVER_URI}/user/project/create`;
  let urls = `${SERVER_URI}/user/project/update`;

  let arr = {
    _id: data && data._id ? data._id : null,
    teamId: data && data.teamId ? data.teamId : null,
    projectName: data && data.projectName ? data.projectName : "",
    tasks: data && data.tasks ? data.tasks : [],
    objectives: data && data.objectives ? data.objectives : [],
    lead: data && data.lead ? data.lead : null,
    standIn: data && data.standIn ? data.standIn : null,
    leadToDos: data.leadToDos ? data.leadToDos : [],
    meetings: data.meetings ? data.meetings : [],
    administration: data.administration ? data.administration : [],
    newTag: [],
    purpose: data && data.purpose ? data.purpose : "",
    tags: data && data.tags ? data.tags : [],
    adminId: adminId ? adminId : null,
    url: urls ? urls : "",
    notes: data && data.notes ? data.notes : "",
    documents: data && data.documents ? data.documents : [],
  };

  const [project, setProject] = useState(arr);
  const [prevMeetingHistory, setPrevMeeting] = useState("");

  const handleChange = (e) => {
    setProject({
      ...project,
      [e.target.name]: e.target.value,
    });
  };

  const groupStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  };

  const groupBadgeStyles = {
    backgroundColor: "#EBECF0",
    borderRadius: "2em",
    color: "#172B4D",
    display: "inline-block",
    fontSize: 12,
    fontWeight: "normal",
    lineHeight: "1",
    minWidth: 1,
    padding: "0.16666666666667em 0.5em",
    textAlign: "center",
  };


  //options roles
  var roleData = [];
  roles.map((e) => {
    roleData.push({ value: `${e._id}`, label: `${e.roleName}` });
  });


  //options skills
  var skillsData = [];
  skills.map((e) => {
    skillsData.push({ value: `${e._id}`, label: `${e.skillName}` });
  });


  //options peoples
  var Data = [];
  peoples.map((e) => {
    Data.push({ value: `${e._id}`, label: `${e.fname} ${e.lname}` });
  });


  const peopleOptions = Data;

  const roleOptions = [];
  if (roles && roles.length > 0) {
    roles.map((e) => {
      if (e && Object.keys(e).length > 0 && e.ownerRole === "Single owner") {
        let curPeople = ""
        if (e.ownerRole === "Single owner" && e.owners && e.owners.length > 0) {
          for (let j = 0; j < peoples.length; j++) {
            if (peoples[j]._id === e.owners[0]) {
              curPeople = `${peoples[j].fname} ${peoples[j].lname}`
              break;
            }
          }
        }
        roleOptions.push({ value: `${e._id}`, label: `${e.roleName} (Role, ${curPeople ? curPeople : "No Owner"})` });
      }
    });
  }

  const skillOptions = [];
  if (skills && skills.length > 0) {
    skills.map((e) => {
      if (e && Object.keys(e).length > 0 && e.ownerType === "Single owner") {
        let curPeople = ""
        if (e.ownerType === "Single owner" && e.owners && e.owners.length > 0) {
          for (let j = 0; j < peoples.length; j++) {
            if (peoples[j]._id === e.owners[0]) {
              curPeople = `${peoples[j].fname} ${peoples[j].lname}`
              break;
            }
          }
        }
        skillOptions.push({ value: `${e._id}`, label: `${e.skillName} (Skill, ${curPeople ? curPeople : "No Owner"})` });
      }
    });
  }


  const groupedOptions = [
    {
      label: "People",
      options: peopleOptions,
    },
    {
      label: "Single owner skill",
      options: skillOptions,
    },
    {
      label: "Single owner role",
      options: roleOptions,
    },
  ];

  const formatGroupLabel = (GroupedOption) => (
    <div style={groupStyles}>
      <span>{GroupedOption.label}</span>
      <span style={groupBadgeStyles}>{GroupedOption.options.length}</span>
    </div>
  );

  var onlyMemberOptions = [];

  if (members && members.length > 0) {
    const currentProjectMembers = members.filter(item => item.memberType === project._id);
    if (currentProjectMembers.length > 0) {
      let cm = currentProjectMembers[0].coreMembers;
      // console.log(225, cm)

      if (cm.People.length > 0) {
        peoples.map((e) => {
          for (let i = 0; i < cm.People.length; i++) {
            const addedPerson = peoples.filter(item => item._id === cm.People[i]);
            if (addedPerson && addedPerson.length > 0) {
              const personId = addedPerson[0]._id;
              // console.log(229, addedPerson)
              if (!onlyMemberOptions.some(option => option.value === personId)) {
                onlyMemberOptions.push({ value: personId, label: `${addedPerson[0].fname} ${addedPerson[0].lname}` });
              }
            }
          }
        });
      }

      if (cm.Skills.length > 0) {
        skills.map((e) => {
          if (cm.Skills.includes(e._id) && e.owners && e.owners.length > 0) {
            for (let i = 0; i < peoples.length; i++) {
              for (let j = 0; j < e.owners.length; j++) {
                let tragetPfMember = pfmembers.find((sm) => sm.memberType === project._id);
                let isPresent = tragetPfMember.coreMembers.Skills.some(skill =>
                  skill.CardId === e._id.toString() && skill.peopleIds === e.owners[j].toString()
                );
                if (e.owners[j] && peoples[i]._id && peoples[i]._id.toString() === e.owners[j].toString() && isPresent) {
                  if (!onlyMemberOptions.some(option => option.value === peoples[i]._id)) {
                    onlyMemberOptions.push({ value: peoples[i]._id, label: `${peoples[i].fname} ${peoples[i].lname}` });
                  }
                }
              }
            }
          }
        });
      }

      if (cm.Roles.length > 0) {
        roles.map((e) => {
          if (cm.Roles.includes(e._id) && e.owners && e.owners.length > 0) {
            for (let i = 0; i < peoples.length; i++) {
              for (let j = 0; j < e.owners.length; j++) {
                let tragetPfMember = pfmembers.find((sm) => sm.memberType === project._id);
                let isPresent = tragetPfMember.coreMembers.Roles.some(role =>
                  role.CardId === e._id.toString() && role.peopleIds === e.owners[j].toString()
                );
                if (e.owners[j] && peoples[i]._id && peoples[i]._id.toString() === e.owners[j].toString() && isPresent) {
                  if (!onlyMemberOptions.some(option => option.value === peoples[i]._id)) {
                    onlyMemberOptions.push({ value: peoples[i]._id, label: `${peoples[i].fname} ${peoples[i].lname}` });
                  }
                }
              }
            }
          }
        });
      }

      if (cm.Links.length > 0) {
        links.map((e) => {
          if (cm.Links.includes(e._id) && e.owner && e.owner.length > 0) {
            for (let i = 0; i < peoples.length; i++) {
              if (e.owner && peoples[i]._id && peoples[i]._id.toString() === e.owner.toString()) {
                // console.log(peoples[i]._id, peoples[i].fname);
                if (!onlyMemberOptions.some(option => option.value === peoples[i]._id)) {
                  onlyMemberOptions.push({ value: peoples[i]._id, label: `${peoples[i].fname} ${peoples[i].lname}` });
                }
              }
            }
          }
        });
      }

      if (cm.Domains.length > 0) {
        domains.map((e) => {
          if (cm.Domains.includes(e._id) && e.owners && e.owners.owner && e.owners.owner.length > 0) {
            for (let i = 0; i < peoples.length; i++) {
              if (e.owners && e.owners.owner && peoples[i]._id && peoples[i]._id.toString() === e.owners.owner.toString()) {
                // console.log(peoples[i]._id, peoples[i].fname);
                if (!onlyMemberOptions.some(option => option.value === peoples[i]._id)) {
                  onlyMemberOptions.push({ value: peoples[i]._id, label: `${peoples[i].fname} ${peoples[i].lname}` });
                }
              }
            }
          }
        });
      }
      // console.log(onlyMemberOptions)
    }
  }

  // For Tasks Field .......

  let taskData = [];

  if (project.tasks.length > 0) {
    project.tasks.map((e) => {
      taskData.push({ tasks: e });
    });
    taskData.push({ tasks: "" });
  }

  if (taskData.length === 0) {
    taskData.push({ tasks: "" });
  }

  const [formValues, setFormValues] = useState(taskData);

  let handleForm = (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);
  };

  let addFormFields = () => {
    let len = formValues.length;
    if (len > 0) {
      if (formValues[len - 1].tasks.length > 0) {
        setFormValues([...formValues, { tasks: "" }]);
      }
    }
  };

  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  const [isClicking, setIsCliking] = useState(false);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      addFormFields(e);
      setIsCliking(true)
    }
  };

  useEffect(() => {
    const textarea = document.getElementById(`task${formValues.length - 1}`);
    if (textarea && isClicking) {
      textarea.focus();
      setIsCliking(false)
    }
  }, [formValues, setFormValues])



  let newTasks = [];
  if (formValues.length > 0) {
    formValues.map((e) => {
      if (e.tasks.length > 0) {
        newTasks.push(e.tasks);
      }
    });
  }

  project.tasks = newTasks;

  // For LeadToDos Field.....

  let leadToDoss = [];
  if (project.leadToDos.length > 0) {
    project.leadToDos.map((e) => {
      leadToDoss.push({ leadToDos: e });
    });
    leadToDoss.push({ leadToDos: "" });
  }

  if (leadToDoss.length === 0) {
    leadToDoss.push({ leadToDos: "" });
  }

  const [formValue, setFormValue] = useState(leadToDoss);

  let handleForms = (i, e) => {
    let newFormValues = [...formValue];
    newFormValues[i][e.target.name] = e.target.value;
    setFormValue(newFormValues);
  };

  let addFormsFields = () => {
    let len = formValue.length;
    if (len > 0) {
      if (formValue[len - 1].leadToDos.length > 0) {
        setFormValue([...formValue, { leadToDos: "" }]);
      }
    }
  };

  let removeFormsFields = (i) => {
    let newFormValues = [...formValue];
    newFormValues.splice(i, 1);
    setFormValue(newFormValues);
  };

  const handleKeysPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      addFormsFields(e);
      setIsCliking(true)
    }
  };


  useEffect(() => {
    const textarea = document.getElementById(`tasks${formValue.length - 1}`);
    if (textarea && isClicking) {
      textarea.focus();
      setIsCliking(false)
    }
  }, [formValue, setFormValue])




  let leadsToDo = [];
  if (formValue.length > 0) {
    formValue.map((e) => {
      if (e.leadToDos.length > 0) {
        leadsToDo.push(e.leadToDos);
      }
    });
  }

  project.leadToDos = leadsToDo;

  // For StandIn Field.....

  let cuStandIn = {};
  if (arr.standIn) {
    for (let i = 0; i < peoples.length; i++) {
      let inc = [data.standIn].includes(peoples[i]._id);
      if (inc) {
        cuStandIn = {
          value: `${peoples[i]._id}`,
          label: `${peoples[i].fname} ${peoples[i].lname}`,
        };
        break;
      }
    }
  }

  const [values, setValues] = React.useState(cuStandIn);
  // console.log("total setValues ",values)
  useEffect(() => {
    if (values && values.value) {
      project.standIn = values.value;
    }
  }, [values, setValues]);

  /* indranil */
  const handleLeadChange = (newvalue) => {

    if (newvalue && values && values.value === newvalue.value) {
      Swal.fire({
        title: "Error",
        text: "Lead and stand in cannot be the same person!",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Ok",
      })
      return false;
    }

    if (newvalue && Object.keys(newvalue).length > 0) {
      setValue(newvalue);
      // console.log("valuesss",newvalue)
    } else if (project.standIn) {
      Swal.fire({
        title: "Note",
        text: "If you delete the lead the standin will also be deleted!",
        // icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      }).then((result) => {
        if (result.isConfirmed) {
          setValue({})
          setValues({})
        }
      });
    } else {
      setValue({})
      setValues({})
    }
  }

  const selectStandIn = (sData) => {

    if (sData && sData.value === value.value) {
      Swal.fire({
        title: "Error",
        text: "Lead and stand in cannot be the same person!",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Ok",
      })
    } else {
      setValues(sData)
    }
  }





  // For Lead Field.....

  let cuLead = {};
  if (arr.lead) {
    for (let i = 0; i < peoples.length; i++) {
      let inc = [data.lead].includes(peoples[i]._id);
      if (inc) {
        cuLead = {
          value: `${peoples[i]._id}`,
          label: `${peoples[i].fname} ${peoples[i].lname}`,
        };
        break;
      }
    }
    if (Object.keys(cuLead).length === 0) {
      if (skills && skills.length > 0) {
        for (let i = 0; i < skills.length; i++) {
          if (skills[i]._id === arr.lead) {
            let curPeople = ""
            if (skills[i].ownerType === "Single owner" && skills[i].owners && skills[i].owners.length > 0) {
              for (let j = 0; j < peoples.length; j++) {
                if (peoples[j]._id === skills[i].owners[0]) {
                  curPeople = `${peoples[j].fname} ${peoples[j].lname}`
                  break;
                }
              }
            }
            cuLead = {
              value: `${skills[i]._id}`,
              label: `${skills[i].skillName} (Skill, ${curPeople ? curPeople : "No Owner"})`,
            };
            break;
          }
        }
      }
    }
    if (Object.keys(cuLead).length === 0) {
      if (roles && roles.length > 0) {
        for (let i = 0; i < roles.length; i++) {
          if (roles[i]._id === arr.lead) {
            let curPeople = ""
            if (roles[i].ownerRole === "Single owner" && roles[i].owners && roles[i].owners.length > 0) {
              for (let j = 0; j < peoples.length; j++) {
                if (peoples[j]._id === roles[i].owners[0]) {
                  curPeople = `${peoples[j].fname} ${peoples[j].lname}`
                  break;
                }
              }
            }
            cuLead = {
              value: `${roles[i]._id}`,
              label: `${roles[i].roleName} (Role, ${curPeople ? curPeople : "No Owner"})`,
            };
            break;
          }
        }
      }
    }
  }

  const [value, setValue] = React.useState(cuLead);
  // console.log("sdfjhsfs sdfjshdg ",value)


  useEffect(() => {
    if (value && value.value) {
      project.lead = value.value;
    }
  }, [value, setValue])


  // For Administration Field.....

  let Administration = [];
  if (project.administration.length > 0) {
    project.administration.map((e) => {
      if (e.owner) {
        for (let i = 0; i < peoples.length; i++) {
          let inc = [e.owner].includes(peoples[i]._id);
          if (inc) {
            Administration.push({
              statusReport: e.statusReport,
              owner: {
                value: `${peoples[i]._id}`,
                label: `${peoples[i].fname} ${peoples[i].lname}`,
              },
            });
            break;
          }
        }
      } else {
        Administration.push({
          statusReport: e.statusReport,
          owner: {
            value: "",
            label: "",
          },
        });
      }
    });
  }

  if (Administration.length === 0) {
    Administration.push({ statusReport: "", owner: { value: "", label: "" } });
  }

  const [formsValue, setFormsValue] = useState(Administration);

  let handlesForms = (i, e) => {
    let newFormValues = [...formsValue];
    newFormValues[i][e.target.name] = e.target.value;
    setFormsValue(newFormValues);
    // console.log("642**** ---", newFormValues);
    let updated_project = { ...project, administration: newFormValues };
    // console.log("645","handlesforms --p", updated_project);
    setProject(updated_project);
  };

  let handleoForms = (i, e) => {
    // console.log(i, e);
    let newFormValue = [...formsValue];
    if (newFormValue[i].statusReport) {
      newFormValue[i].owner = e ? e : { value: '', label: '' };
      // console.log(653,{formsValue});
      // console.log(654,{newFormValue});
      setFormsValue(newFormValue);

      let updated_project = { ...project, administration: newFormValue };
      // console.log("657","handleoforms --p", updated_project);
      setProject(updated_project);
    } else {
      Swal.fire({
        title: "Error",
        text: "Please add a administration task first!",
        // icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        // cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      })
    }
  };

  let addFormsField = () => {
    let len = formsValue.length;
    if (len > 0) {
      if (
        formsValue[len - 1].statusReport.length > 0
      ) {
        setFormsValue([
          ...formsValue,
          {
            statusReport: "", owner: {
              value: "",
              label: "",
            }
          },
        ]);
      }
    }
  };

  let removeFormsField = (i) => {
    let newFormValues = [...formsValue];
    if (formsValue.length > 1) {
      newFormValues.splice(i, 1);
      setFormsValue(newFormValues);

      let updated_project = { ...project, administration: formsValue };
      // console.log("678","RemoveCircle", updated_project);
      setProject(updated_project);
    } else if (formsValue.length === 1) {
      setFormsValue([{ statusReport: "", owner: null }]);

      let updated_project = { ...project, administration: [{ statusReport: "", owner: null }] };
      // console.log("678","RemoveCircle", updated_project);
      setProject(updated_project);
    }
  };

  const handleKeysPres = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      addFormsField(e);
    }
    // e.preventDefault()
  };

  let Administrations = [];
  if (formsValue.length > 0) {
    formsValue.map((e) => {
      if (
        e.statusReport &&
        e.statusReport.length > 0 &&
        e.owner
      ) {
        Administrations.push({
          statusReport: e.statusReport,
          owner: e.owner && e.owner.value ? e.owner.value : null,
        });
      }
    });
  }

  if (Administrations && Administrations.length > 0) {
    project.administration = Administrations;
  }



  //meetings

  let Meetings = [];
  if (project) {
    if (project.meetings.length > 0) {
      for (let i = 0; i < meetings.length; i++) {
        let inc = project.meetings.includes(meetings[i]._id);
        if (inc) {
          Meetings.push(meetings[i]);
        }
      }
    }
  }

  let murl = `${SERVER_URI}/user/meetings/create`;
  let murls = `${SERVER_URI}/user/meetings/update`;

  var arrs = {
    _id: "",
    meetingsName: "",
    meetingsPurpose: "",
    recurrence: "",
    duration: "",
    durationType: "Minutes",
    recurrenceType: "Day(s)",
    XTD: false,
    adminId: adminId,
    url: murl,
    teamId: teamId,
  };

  const [meeting, setMeeting] = useState(arrs);
  const [meetingEdit, setMeetingEdit] = useState(false);

  const handleChangeMeetings = (e) => {
    setMeeting({
      ...meeting,
      [e.target.name]: e.target.value,
    });
  };

  //added by ranjit
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  const centeredPlaceholderStyle = {
    lineHeight: "3rem",
    height: "2rem",
    padding: "0",
  };


  let meetingHistoryState = [];

  const meetingsHandel = (index, element) => {
    let str = `${element.meetingsName} ${element.meetingsPurpose} ${element.recurrence} ${element.recurrenceType} ${element.duration} ${element.durationType}`;
    setPrevMeeting(str);

    let arrsData = {
      _id: element._id,
      teamId: element.teamId,
      meetingsName: element.meetingsName,
      meetingsPurpose: element.meetingsPurpose,
      recurrence: element.recurrence,
      duration: element.recurrence,
      durationType: element.durationType,
      recurrenceType: element.recurrenceType,
      XTD: element.XTD,
      adminId: adminId,
      url: murls,
    };
    setMeetingEdit(true)
    setMeeting(arrsData);
  };

  // const [xtd, setXtd] = useState(meeting.XTD);
  const xtdHandelChange = (e) => {
    setMeeting({
      ...meeting,
      XTD: e.target.checked,
    });
  };

  // useEffect(() => {
  //   meeting.XTD = xtd;
  // }, [xtd, setXtd]);

  const meetingSubmit = (e) => {
    e.preventDefault();

    if (meeting.meetingsName === '') {
      Swal.fire({
        title: "Error",
        text: "Meeting name is required!",
        confirmButtonColor: "#3085d6"
      })

      return false;
    }

    let flag = "";
    if (meeting.XTD) {
      flag = "incl.XTD";
    }

    let str = `${meeting.meetingsName} ${meeting.meetingsPurpose} ${meeting.recurrence} ${meeting.recurrenceType} ${meeting.duration} ${meeting.durationType} ${flag}`;
    let obj = {
      cardId: data._id,
      teamId: teamId,
      field: "Meeting",
      prev: prevMeetingHistory,
      next: str,
      hcTime: currentTime(),
      cardStatus: "changed",
    };
    meetingHistoryState.push(obj);
    //update
    if (meeting._id) {
      dispatch(updateMeetings(meeting)).then(() => {
        if (meetingsMessage) {
          setMeetingEdit(false)
          // dispatch(historyCreate(meetingHistoryState));
        }
      });
    } else {
      //create
      dispatch(createMeetings(meeting));
    }
  };

  const meetingDeletes = (index, element) => {
    let str = `${element.meetingsName} ${element.meetingsPurpose} ${element.recurrence} ${element.recurrenceType} ${element.duration} ${element.durationType}`;
    let deleteHistory = {
      cardId: data._id,
      teamId: teamId,
      field: "Meeting",
      prev: prevMeetingHistory,
      next: str,
      hcTime: currentTime(),
      cardStatus: "removed",
    };
    meetingHistoryState.push(deleteHistory);
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to restore!",
      // icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        let obj = {};
        obj.teamId = localStorage.getItem("teamId");
        obj.userId = myInfo.userId;

        let objs = {};
        objs.teamId = localStorage.getItem("teamId");
        objs.userId = myInfo.userId;
        objs.curproject = states.Projects;

        let cuMeetings = project.meetings.filter((value) => {
          return value.toString() !== element._id.toString();
        });
        project.meetings = cuMeetings;
        dispatch(updateProject(project)).then((results) => {
          dispatch({ type: PROJECT_SUCCESS_MESSAGE_CLEAR });
          dispatch(
            deleteMeetings({
              adminId: myInfo.userId,
              teamId: teamId,
              meetingId: element._id.toString(),
            })
          )
            .then((resData) => {
              dispatch(getMeetings(obj));
            })
            .then(() => {
              // dispatch(historyCreate(meetingHistoryState));
            });
          dispatch(getProject(objs));
        });
      }
    });
  };

  let curTag = [];
  if (project.newTag.length > 0) {
    for (let i = 0; i < project.newTag.length; i++) {
      curTag.push({ userId: myInfo.userId, tags: [project.newTag[i]] });
    }
  }

  const currentTime = () => {
    var months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    var d = new Date();
    var day = days[d.getDay()];
    var hr = d.getHours();
    var min = d.getMinutes();
    if (min < 10) {
      min = "0" + min;
    }
    var ampm = "AM";
    if (hr > 12) {
      hr -= 12;
      ampm = "PM";
    }
    var date = d.getDate();
    var month = months[d.getMonth()];
    var year = d.getFullYear();
    let curTime =
      month + " " + date + ", " + year + " " + hr + ":" + min + " " + ampm;
    return curTime;
  };

  /* let historyState = [];

  if (data && Object.keys(data).length > 0) {
    if (data.projectName || project.projectName) {
      if (data.projectName !== project.projectName) {
        if (data.projectName.length > 0 && project.projectName.length > 0) {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Project Name",
            prev: data.projectName,
            next: project.projectName,
            hcTime: currentTime(),
            cardStatus: "changed",
          };
          historyState.push(obj);
        }
      }
    }
    if (data.purpose || project.purpose) {
      if (data.purpose !== project.purpose) {
        if (!data.purpose && project.purpose.length > 0) {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Purpose",
            prev: data.purpose,
            next: project.purpose,
            hcTime: currentTime(),
            cardStatus: "added",
          };
          historyState.push(obj);
        } else if (data.purpose.length > 0 && project.purpose.length > 0) {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Purpose",
            prev: data.purpose,
            next: project.purpose,
            hcTime: currentTime(),
            cardStatus: "changed",
          };
          historyState.push(obj);
        } else if (data.purpose.length > 0 && !project.purpose) {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Purpose",
            prev: data.purpose,
            next: project.purpose,
            hcTime: currentTime(),
            cardStatus: "removed",
          };
          historyState.push(obj);
        }
      }
    }
    if (data.tasks || project.tasks) {
      if (
        data.tasks &&
        project.tasks &&
        data.tasks.join() !== project.tasks.join()
      ) {
        if (!data.tasks.join() && project.tasks.length > 0) {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Tasks",
            prev: data.tasks.join(),
            next: project.tasks.join(),
            hcTime: currentTime(),
            cardStatus: "added",
          };
          historyState.push(obj);
        } else if (
          data.tasks &&
          data.tasks.join().length > 0 &&
          project.tasks &&
          project.tasks.join().length > 0
        ) {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Tasks",
            prev: data.tasks.join(),
            next: project.tasks.join(),
            hcTime: currentTime(),
            cardStatus: "changed",
          };
          historyState.push(obj);
        } else if (
          data.tasks &&
          data.tasks.join().length > 0 &&
          project.tasks &&
          !project.tasks.join()
        ) {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Tasks",
            prev: data.tasks.join(),
            next: project.tasks.join(),
            hcTime: currentTime(),
            cardStatus: "removed",
          };
          historyState.push(obj);
        }
      }
    }
    // if (data.owner || link.owner) {
    //   let oldOwners = ''
    //   let newOwners = ''
    //   if (peoples && peoples.length > 0) {
    //     for (let i = 0; i < peoples.length; i++) {
    //       if (peoples[i]._id === data.owner) {
    //         oldOwners = `${peoples[i].fname} ${peoples[i].lname}`
    //       }
    //       if (peoples[i]._id === link.owner) {
    //         newOwners = `${peoples[i].fname} ${peoples[i].lname}`
    //       }
    //     }
    //   }
    //   if (oldOwners !== newOwners) {
    //     if (oldOwners === '' && newOwners.length > 0) {
    //       let obj = {
    //         cardId: data._id,
    //         teamId: teamId,
    //         field: 'Owner',
    //         prev: oldOwners,
    //         next: newOwners,
    //         hcTime: currentTime(),
    //         cardStatus: 'added'
    //       }
    //       historyState.push(obj)
    //     } else if (oldOwners.length > 0 && newOwners.length > 0) {
    //       let obj = {
    //         cardId: data._id,
    //         teamId: teamId,
    //         field: 'Owner',
    //         prev: oldOwners,
    //         next: newOwners,
    //         hcTime: currentTime(),
    //         cardStatus: 'changed'
    //       }
    //       historyState.push(obj)
    //     } else if (oldOwners.length > 0 && newOwners === '') {
    //       let obj = {
    //         cardId: data._id,
    //         teamId: teamId,
    //         field: 'Owner',
    //         prev: oldOwners,
    //         next: newOwners,
    //         hcTime: currentTime(),
    //         cardStatus: 'removed'
    //       }
    //       historyState.push(obj)
    //     }
    //   }
    // }
    if (data.lead || project.lead) {
      let oldLead = "";
      let newLead = "";
      if (peoples && peoples.length > 0) {
        for (let i = 0; i < peoples.length; i++) {
          if (data.lead && peoples[i]._id === data.lead) {
            oldLead = `${peoples[i].fname} ${peoples[i].lname}`;
          }
          if (project.lead && peoples[i]._id === project.lead) {
            newLead = `${peoples[i].fname} ${peoples[i].lname}`;
          }
        }
      }
      if (oldLead !== newLead) {
        if (!oldLead && newLead.length > 0) {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Lead",
            prev: oldLead,
            next: newLead,
            hcTime: currentTime(),
            cardStatus: "added",
          };
          historyState.push(obj);
        } else if (oldLead.length > 0 && newLead.length > 0) {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Lead",
            prev: oldLead,
            next: newLead,
            hcTime: currentTime(),
            cardStatus: "changed",
          };
          historyState.push(obj);
        } else if (oldLead.length > 0 && !newLead) {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Lead",
            prev: oldLead,
            next: newLead,
            hcTime: currentTime(),
            cardStatus: "removed",
          };
          historyState.push(obj);
        }
      }
    }
    if (
      (data.leadToDos && data.leadToDos.length > 0) ||
      (project.leadToDos && project.leadToDos.length > 0)
    ) {
      if (data.leadToDos.join() !== project.leadToDos.join()) {
        if (!data.leadToDos.join() && project.leadToDos.length > 0) {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Tags",
            prev: data.leadToDos.join(),
            next: project.leadToDos.join(),
            hcTime: currentTime(),
            cardStatus: "added",
          };
          historyState.push(obj);
        } else if (data.leadToDos.length > 0 && project.leadToDos.length > 0) {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Tags",
            prev: data.leadToDos.join(),
            next: project.leadToDos.join(),
            hcTime: currentTime(),
            cardStatus: "changed",
          };
          historyState.push(obj);
        } else if (
          data.leadToDos.length > 0 &&
          project.leadToDos.length === 0
        ) {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Tags",
            prev: data.leadToDos.join(),
            next: project.leadToDos.join(),
            hcTime: currentTime(),
            cardStatus: "removed",
          };
          historyState.push(obj);
        }
      }
    }
    if (data.standIn || project.standIn) {
      let oldStandIn = "";
      let newStandIn = "";
      if (peoples && peoples.length > 0) {
        for (let i = 0; i < peoples.length; i++) {
          if (peoples[i]._id === data.standIn) {
            oldStandIn = `${peoples[i].fname} ${peoples[i].lname}`;
          }
          if (peoples[i]._id === project.standIn) {
            newStandIn = `${peoples[i].fname} ${peoples[i].lname}`;
          }
        }
      }
      if (oldStandIn !== newStandIn) {
        if (!oldStandIn && newStandIn.length > 0) {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Standin",
            prev: oldStandIn,
            next: newStandIn,
            hcTime: currentTime(),
            cardStatus: "added",
          };
          historyState.push(obj);
        } else if (oldStandIn.length > 0 && newStandIn.length > 0) {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Standin",
            prev: oldStandIn,
            next: newStandIn,
            hcTime: currentTime(),
            cardStatus: "changed",
          };
          historyState.push(obj);
        } else if (oldStandIn.length > 0 && !newStandIn) {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Standin",
            prev: oldStandIn,
            next: newStandIn,
            hcTime: currentTime(),
            cardStatus: "removed",
          };
          historyState.push(obj);
        }
      }
    }
    if (
      (data.tags && data.tags.length > 0) ||
      (project.tags && project.tags.length > 0)
    ) {
      if (data.tags && project.tags && data.tags.join() !== project.tags.join()) {
        if (data.tags.join() && project.tags.length > 0) {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Tags",
            prev: data.tags.join(),
            next: project.tags.join(),
            hcTime: currentTime(),
            cardStatus: "added",
          };
          historyState.push(obj);
        } else if (data.tags.length > 0 && project.tags.length > 0) {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Tags",
            prev: data.tags.join(),
            next: project.tags.join(),
            hcTime: currentTime(),
            cardStatus: "changed",
          };
          historyState.push(obj);
        } else if (data.tags.length > 0 && project.tags.length === 0) {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Tags",
            prev: data.tags.join(),
            next: project.tags.join(),
            hcTime: currentTime(),
            cardStatus: "removed",
          };
          historyState.push(obj);
        }
      }
    }
  } */

  const projectToCircle = (data) => {
    if (data._id && data._id.length > 0) {
      let objData = {
        teamId: data.teamId,
        circleName: data.projectName,
        tasks: data.tasks,
        lead: data.lead,
        standIn: data.standIn,
        leadToDos: data.leadToDos ? data.leadToDos : [],
        meetings: data.meetings ? data.meetings : [],
        administration: data.administration ? data.administration : [],
        projectId: data._id,
        purpose: data.purpose,
        tags: data.tags,
        adminId: adminId,
        url: `${SERVER_URI}/user/circle/create`,
      };
      let objsData = {};
      objsData.teamId = localStorage.getItem("teamId");
      objsData.userId = myInfo.userId;

      let obj = {};
      obj.teamId = localStorage.getItem("teamId");
      obj.adminId = project.adminId;
      obj.url = `${SERVER_URI}/user/project/delete`;
      obj.projectId = data._id;
      let objs = {};
      objs.teamId = localStorage.getItem("teamId");
      objs.userId = myInfo.userId;
      /* let cuProjects = states.Projects.filter((e) => {
        return e !== obj.projectId;
      }); */

      let circleSort = localStorage.getItem("circleSort");
      let projectSort = localStorage.getItem("projectSort");
      freezeWindow(false)
      dispatch(createCircle({ ...objData, circleSort, transfer: true })).then(() => {
        // dispatch(getCircle(objsData));
        dispatch(deleteProject({ ...obj, projectSort })).then(() => {
          dispatch(getState({ userId: myInfo.userId, teamId: obj.teamId }));
          dispatch({ type: CIRCLE_ID_CLEAR });
          dispatch({ type: CIRCLE_SUCCESS_MESSAGE_CLEAR });
          dispatch({ type: HISTORY_DATAS_CLEAR });
          unfreezeWindow()
        }).then(() => {
          clearModes();
        });
      });
    }
  };

  const handelUpdate = async () => {
    //update
    let projectSort = localStorage.getItem("projectSort");
    if (project._id) {
      project.lead = value && value.value ? value.value : null;
      project.standIn = value.value && values ? values.value : null;
      // project.teamId = localStorage.getItem("teamId");

      // console.log(1330,project);

      dispatch(updateProject({ ...project, projectSort })).then(() => {
        // dispatch(historyCreate(historyState))
      });
    }
    // dispatch(historyCreate(newMeetingAdded)).then(() => {
    //   dispatch({ type: HISTORY_DATAS_CLEAR });
    // })
    return true
  }

  const handlePreviosNext = async (data) => {
    dispatch({ type: HISTORY_DATAS_CLEAR });
    let curUpdateData = []
    if (projects && projects.length > 0 && isNToP && isNToP.length > 0) {
      for (let i = 0; i < isNToP.length; i++) {
        for (let j = 0; j < projects.length; j++) {
          if (projects[j]._id === isNToP[i]._id) {
            curUpdateData.push(projects[j])
            break;
          }
        }
      }
    }
    if (data === "prev") {
      if (curUpdateData && curUpdateData.length > 0) {
        for (let i = 0; i < curUpdateData.length; i++) {
          if (curUpdateData[i]._id === project._id && i >= 0) {
            localStorage.setItem("npState", "Project")
            let index = i === 0 ? curUpdateData.length - 1 : i - 1;
            await handelUpdate().then(() => {
              setProjects(curUpdateData[index], curUpdateData, 0, "Project");
            })
          }
        }
      }
    } else if (data === "next") {
      if (curUpdateData && curUpdateData.length > 0) {
        for (let i = 0; i < curUpdateData.length; i++) {
          if (curUpdateData[i]._id === project._id && i <= curUpdateData.length - 1) {
            localStorage.setItem("npState", "Project")
            let index = i < curUpdateData.length - 1 ? i + 1 : 0;
            await handelUpdate().then(() => {
              setProjects(curUpdateData[index], curUpdateData, 0, "Project");
            })
          }
        }
      }
    }
  };

  const projectCopy = (e) => {
    e.preventDefault();
    dispatch({ type: HISTORY_DATAS_CLEAR });
    setProject({
      ...project,
      _id: "",
      projectName: project.projectName + " (Copy)",
      // meetings: [],
    });
    // dispatch(createProject(datas))
  };

  // Project All Actions.....

  //added by ranjit
  let newMeetingAdded = [];
  useEffect(() => {
    if (project) {
      let arr = projects.filter((value) => {
        return value._id === project._id;
      });
      if (arr[0] && arr[0].meetings && arr[0].meetings.length) {
        let x = arr[0].meetings.length;
        for (let i = x; i < Meetings.length; i++) {
          let str = `${Meetings[i].meetingsName} ${Meetings[i].meetingsPurpose} ${Meetings[i].recurrence} ${Meetings[i].recurrenceType} ${Meetings[i].duration} ${Meetings[i].durationType}`;
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Meeting",
            prev: "",
            next: str,
            hcTime: currentTime(),
            cardStatus: "added",
          };
          // console.log(obj);
          newMeetingAdded.push(obj);
        }
      }
    }
  }, [project, Meetings]);

  //
  const [documents, setDocument] = useState();

  const projectSubmit = (e) => {
    e.preventDefault();
    localStorage.setItem("npState", "")
    let projectSort = localStorage.getItem("projectSort");
    //update
    project.documents = documents

    closeProject();
    stateCloseState("Projects");

    if (project._id) {
      // console.log(value)
      project.lead = value && value.value ? value.value : null;
      project.standIn = value.value && values ? values.value : null;
      // console.log(project, value.value);
      // console.log({ ...project, projectSort })
      dispatch(updateProject({ ...project, projectSort })).then(() => {
        // dispatch(historyCreate(historyState));
        // dispatch(getProject({ adminId: myInfo.userId, teamId: myInfo.teamId }));
      });
    } else {
      //create
      dispatch(createProject({ ...project, teamId: localStorage.getItem("teamId"), projectSort }));
    }
    // dispatch(historyCreate(newMeetingAdded)).then(() => {
    //   dispatch({ type: HISTORY_DATAS_CLEAR });
    // })
  };


  useEffect(() => {
    if (CloseState && CloseState === "Projects") {
      stateCloseState("")
      //update
      localStorage.setItem("npState", "")
      //update
      let projectSort = localStorage.getItem("projectSort");
      project.teamId = localStorage.getItem("teamId");
      if (project._id) {
        // console.log(value)
        project.documents = documents;
        project.lead = value && value.value ? value.value : null;
        project.standIn = value.value && values ? values.value : null;
        // console.log(project, value.value);

        dispatch(updateProject({ ...project, projectSort })).then(() => {
          // dispatch(historyCreate(historyState))
        });
      } else {
        if (project.projectName !== "") {
          //create
          // console.log(project)
          project.documents = documents;
          dispatch(createProject({ ...project, projectSort }));
        }
      }
      // dispatch(historyCreate(newMeetingAdded)).then(() => {
      //   dispatch({ type: HISTORY_DATAS_CLEAR });
      // })
    }
  }, [CloseState, stateCloseState])


  const projectDelete = (e) => {
    // e.preventDefault();
    // Swal.fire({
    //   title: "Are you sure?",
    //   text: "You can still restore this from trash!",
    //   // icon: "warning",
    //   showCancelButton: true,
    //   confirmButtonColor: "#3085d6",
    //   cancelButtonColor: "#d33",
    //   confirmButtonText: "Yes, delete it!",
    // }).then((result) => {
    //   if (result.isConfirmed) {
    //     let obj = {};
    //     obj.teamId = localStorage.getItem("teamId");
    //     obj.adminId = project.adminId;
    //     obj.url = `${SERVER_URI}/user/project/delete`;
    //     obj.projectId = project._id;
    //     obj.softdelete = true;
    //     let objs = {};
    //     objs.teamId = localStorage.getItem("teamId");
    //     objs.userId = myInfo.userId;
    //     dispatch(deleteProject(obj))
    //       .then(() => {
    //         if (project.meetings.length > 0) {
    //           dispatch(getMeetings(objs));
    //           dispatch({ type: PROJECT_ID_CLEAR });
    //           dispatch({ type: HISTORY_DATAS_CLEAR });
    //         }
    //         closeProject();
    //       })
    //   }
    // });
    e.preventDefault();
    Swal.fire({
      title: "Are you sure?",
      text: "You can choose to archive or delete this.",
      showDenyButton: true, // Enables a second button for archiving
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      denyButtonColor: "#ffc107", // Yellow color for the Archive button
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
      denyButtonText: "Archive",
    }).then((result) => {
      let obj = {
        teamId: localStorage.getItem("teamId"),
        adminId: project.adminId,
        projectId: project._id,
      };
      let objs = {
        teamId: localStorage.getItem("teamId"),
        userId: myInfo.userId,
      };

      if (result.isConfirmed) {
        // Action for delete
        obj.url = `${SERVER_URI}/user/project/delete`;

        dispatch(deleteProject(obj)).then(() => {
          if (project.meetings.length > 0) {
            dispatch(getMeetings(objs));
          }
          dispatch({ type: PROJECT_ID_CLEAR });
          dispatch({ type: HISTORY_DATAS_CLEAR });
          closeProject();
        });
      } else if (result.isDenied) {
        // Action for archive
        obj.url = `${SERVER_URI}/user/project/delete`;
        obj.softdelete = true;

        dispatch(deleteProject(obj)).then(() => {
          if (project.meetings.length > 0) {
            dispatch(getMeetings(objs));
          }
          dispatch({ type: PROJECT_ID_CLEAR });
          dispatch({ type: HISTORY_DATAS_CLEAR });
          closeProject();
        });
      }
    });

  };

  useEffect(() => {
    let obj = {};
    obj.teamId = localStorage.getItem("teamId");
    obj.userId = myInfo.userId;

    if (projectMessagee && projectMessagee.length > 0) {
      dispatch(getState(obj));
      let curproject = localStorage.getItem("projectSort");
      let curprojects = curproject.split(",");
      dispatch({ type: PROJECT_SUCCESS_MESSAGE_CLEAR });
      // dispatch(getProject({ ...obj, curproject: curprojects }));
      let curState = localStorage.getItem("npState")
      if (curState !== "Project") {
        closeProject();
      }
    }

    if (projectError) {
      Swal.fire({
        // icon: "error",
        title: "Error",
        showClass: {
          popup: "animate_animated animate_fadeInDown",
        },
        hideClass: {
          popup: "animate_animated animate_fadeOutUp",
        },
        text: projectError,
      });
      dispatch({ type: PROJECT_ERROR_CLEAR });
      // closeProject();
    }

    dispatch(getTags({ userId: myInfo.userId }));
  }, [projectMessagee, projectError, cuProjectId]);

  useEffect(() => {
    let obj = {};
    obj.teamId = localStorage.getItem("teamId");
    obj.userId = myInfo.userId;

    if (meetingsMessagee) {
      dispatch(getMeetings(obj)).then(() => {
        if (meetingId && meetingId.length > 0) {
          // circle.meetings = [...new Set([])]
          setProject({
            ...project,
            ["meetings"]: [...new Set([...project.meetings, meetingId])],
          });
        }
        setMeeting(arrs);
      });
      dispatch({ type: MEETINGS_SUCCESS_MESSAGE_CLEAR });
    }

    if (meetingsError) {
      Swal.fire({
        // icon: "error",
        title: "Error",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        text: meetingsError,
      });
      // alert.error(peopleError)
      dispatch({ type: MEETINGS_ERROR_CLEAR });
      // dispatch(getDomain(obj));
      // closeProject();
    }
  }, [meetingsMessagee, meetingsError]);


  useEffect(() => {
    if (historyMessage) {
      dispatch({ type: HISTORY_MESSAGE_CLEAR });
    }
  }, [historyMessage && historys]);

  const ProjectMFocus = (id) => {
    setCampfireComponent(false);
    let fData = {
      card: "Projects",
      index: Number(localStorage.getItem("FIndex")),
      id: id
    };
    setFocused(fData);
    closeProject();
  };

  const ref = useRef(null);
  const handleClick = () => {
    ref.current.focus();
  };

  const [curIsFocused, setCurIsFocused] = useState(false)

  useEffect(() => {
    let isNToPObj = isNToP && isNToP.find(i => i._id === focused.id);
    if (focused && Object.keys(focused).length > 0) {
      if (focused.card === "Projects" && project && project._id && project._id.length > 0 && isNToP && isNToP.length > 0) {
        // if (isNToP[Number(focused.index)]._id === project._id) {
        if (isNToPObj && isNToPObj._id === project._id) {
          setCurIsFocused(true)
        }
      }
    }
  }, [focused])

  const CloseProjectModal = () => {
    setPurposetreeLoader((prev => ({ ...prev, project: false })));
    dispatch({ type: HISTORY_DATAS_CLEAR });
    closeProject();
  };

  //added by ranjit
  const onDragStart = (e, index) => {
    e.dataTransfer.setData("index", index);
  };

  const onDragOver = (e, index) => {
    e.preventDefault();
  };

  const onDrop = (e, index) => {
    const draggedIndex = e.dataTransfer.getData("index");
    const items = [...formValues];
    const draggedItem = items[draggedIndex];
    items.splice(draggedIndex, 1);
    items.splice(index, 0, draggedItem);
    setFormValues(items);
  };

  const onDragStartLead = (e, index) => {
    e.dataTransfer.setData("index", index);
  };

  const onDragOverLead = (e, index) => {
    e.preventDefault();
  };

  const onDropLead = (e, index) => {
    const draggedIndex = e.dataTransfer.getData("index");
    const items = [...formValue];
    const draggedItem = items[draggedIndex];
    items.splice(draggedIndex, 1);
    items.splice(index, 0, draggedItem);
    setFormValue(items);
  };






  const CustomOption = ({ innerProps, label, value }) => {
    let flag = false;
    if (peoples && peoples.length > 0) {
      for (let i = 0; i < peoples.length; i++) {
        if (peoples[i]._id === value && peoples[i].workRole === "External") {
          flag = true;
          break;
        }
      }
    }
    let data = ``;
    if (flag) {
      data += `<span class="custom-badge blue">EXT</span>`
    }
    data += label
    return (
      <div {...innerProps} className="tdListItem">
        <span dangerouslySetInnerHTML={{ __html: data }} />
      </div>
    )
  };



  const isExtPerson = (Id) => {
    if (peoples && peoples.length > 0) {
      let flag = false;
      for (let i = 0; i < peoples.length; i++) {
        if (peoples[i]._id === Id && peoples[i].workRole === "External") {
          flag = true;
          break;
        }
      }
      return flag;
    }
  }



  const outerBoxClick = () => {
    if (formValues && formValues.length > 0 && formValues[formValues.length - 1].tasks && formValues[formValues.length - 1].tasks.length > 0) {
      addFormFields();
      setIsCliking(true)
    }
    if (formValue && formValue.length > 0 && formValue[formValue.length - 1].leadToDos && formValue[formValue.length - 1].leadToDos.length > 0) {
      addFormsFields();
      setIsCliking(true)
    }
  }

  const HandleSecretDownloadTool = () => {

    // Create the content of the file
    const fileContent =
      `Name: ${project.projectName}\nPurpose: ${project.purpose}\nTasks: ${project.tasks.join(',')}\nlead: ${cuLead.label}\nTags: ${project.tags.join(',')}`;

    // Create a Blob object from the content with file content and file type
    const blob = new Blob([fileContent], { type: 'text/plain' });

    // Create a URL for the Blob
    const fileUrl = URL.createObjectURL(blob);

    // Create a temporary anchor element
    const link = document.createElement('a');
    link.href = fileUrl;

    // Set the download attribute with a default file name
    link.download = `${project.projectName}_circle.txt`;

    // Programmatically click the link to trigger the download
    link.click();

    // Clean up the object URL
    URL.revokeObjectURL(fileUrl);
  };

  return (
    <div id="projectModal" onClick={() => outerBoxClick()} role="dialog">
      <div className="modal-dialog modal-lg modal-dialog-scrollable">
        <form className="form" onSubmit={(e) => projectSubmit(e)}>
          <div className="modal-content">

            <div className="modal-header header-green">
              <div className="row ">
                <div className="col-xs-8 text-left">
                  <h4 className="modal-title">
                    <img
                      src="images/icon-project.png"
                      style={{ width: "17px", marginTop: "-3px", marginRight: "5px" }}
                      onClick={HandleSecretDownloadTool}
                    />{" "}
                    {/* PROJECT */}{GetAliasesName(teams, "Projects").toUpperCase()}{" "}
                    <span
                      onClick={() => projectToCircle(project)}
                      className="move-to"
                      data-source_list="projects"
                      data-target_list="circles"
                    >
                      Convert To {/* Circle */}{GetAliasesName(teams, "Circles")}
                    </span>
                  </h4>
                </div>
                {focusMode && focusMode.length > 0 ? null : (
                  <div className="col-xs-4 text-right">
                    <Tippy content={<span>Previous {/* Project */} {GetAliasesName(teams, "Projects")}</span>}>
                      <button
                        style={{ marginRight: "10px", cursor: "pointer" }}
                        onClick={() => handlePreviosNext("prev")}
                        type="button"
                        className="btn btn-mytpt-gray show-prev"
                      >
                        <i

                          className="fa fa-angle-left"
                        ></i>
                      </button>
                    </Tippy>
                    <Tippy content={<span>Next {/* Project */}{GetAliasesName(teams, "Projects")}</span>}>
                      <button
                        style={{ cursor: "pointer" }}
                        type="button"
                        onClick={() => handlePreviosNext("next")}
                        className="btn btn-mytpt-gray show-next"
                      >
                        <i

                          className="fa fa-angle-right"
                        ></i>
                      </button>
                    </Tippy>
                  </div>
                )}
              </div>
            </div>

            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <TextareaAutosize
                      type="text"
                      ref={ref}
                      name="projectName"
                      value={project.projectName}
                      onKeyDown={handleKeyDown}
                      onChange={handleChange}
                      className="form-control  tool-name logit"
                      placeholder={`+ Add ${GetAliasesName(teams, "Projects").toLowerCase()} name`}
                      style={centeredPlaceholderStyle}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <h4>Purpose</h4>
                    <TextareaAutosize
                      // cacheMeasurements
                      type="text"
                      value={project.purpose}
                      onChange={handleChange}
                      className="form-control purpose logit"
                      name="purpose"
                      placeholder="+ Add purpose"
                    />
                  </div>

                  <div className="form-group tasks">
                    <Tasks formValues={formValues} onDragStart={onDragStart} onDragOver={onDragOver} onDrop={onDrop} handleForm={handleForm} handleKeyPress={handleKeyPress} removeFormFields={removeFormFields} />
                  </div>

                  <div className="form-group objectives">
                    <Objectives data={project} project={project} />
                  </div>

                  <div className="form-group meetings">
                    <h4>Meetings</h4>
                    <div className="meeting-list meeting-container">
                      {Meetings.map((e, index) => {
                        return (
                          <div
                            key={index}
                            // onMouseOver={changeBackground}
                            // onMouseEnter={() => setIsShown(true)}
                            // onMouseLeave={() => setIsShown(false)}
                            className="meeting"
                          >
                            <div className="meeting-content">
                              <p>
                                <b>{e.meetingsName}</b>
                              </p>
                              <p>{e.meetingsPurpose}</p>
                              {e.recurrenceType === "As needed" ||
                                e.durationType === "As needed" ? (
                                <p>
                                  {e.recurrenceType} {e.durationType}
                                </p>
                              ) : (
                                <>
                                  {" "}
                                  {e.recurrence && (
                                    <p>
                                      Every {e.recurrence} {e.recurrenceType}{" "}
                                      {e.duration && (
                                        <span>
                                          for {e.duration} {e.durationType}
                                        </span>
                                      )}
                                    </p>
                                  )}
                                </>
                              )}
                              {/* <p>
                                Every {e.recurrence} {e.duration} for{" "}
                                {e.durationType} Hours
                              </p> */}
                              <p>{e.XTD ? "incl. XTD" : null}</p>
                            </div>
                            <div className="meeting-actions">
                              <a
                                className="edit-meeting "
                                onClick={() => meetingsHandel(index, e)}
                                data-modal_name="circlesModal"
                                data-meeting_id="4850"
                              >
                                <span className="fa fa-pencil-alt"></span>
                              </a>
                              <a
                                className="delete-meeting"
                                onClick={() => meetingDeletes(index, e)}
                                data-modal_name="circlesModal"
                                data-meeting_id="4850"
                              >
                                <span className="fa fa-times"></span>
                              </a>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <div className="row new-meeting">
                      <div className="col-xs-12">
                        <input
                          type="hidden"
                          className={meetingEdit ? "form-control meeting_id editable-selectize" : "form-control meeting_id"}
                          value=""
                          onChange={handleChangeMeetings}
                        />
                        <input
                          type="text"
                          name="meetingsName"
                          value={meeting.meetingsName}
                          onChange={handleChangeMeetings}
                          className={meetingEdit ? "form-control meeting-name logit editable-selectize" : "form-control meeting-name logit"}
                          placeholder="+ Add meeting name"
                        />
                      </div>
                      <div className="col-xs-12">
                        <textarea
                          className={meetingEdit ? "form-control meeting-purpose autogrow logit editable-selectize" : "form-control meeting-purpose autogrow logit"}
                          name="meetingsPurpose"
                          value={meeting.meetingsPurpose}
                          onChange={handleChangeMeetings}
                          placeholder="+ Add meeting purpose (maxlength: 140 characters)"
                          autoComplete="off"
                          data-modal_id="circlesModal"
                        ></textarea>
                      </div>
                      <div className="col-xs-12">
                        <table style={{ width: "100%" }}>
                          <tbody>
                            <tr>
                              <td>
                                <label>Every</label>
                              </td>
                              <td>
                                <input
                                  type="number"
                                  name="recurrence"
                                  value={meeting.recurrence}
                                  onChange={handleChangeMeetings}
                                  className={meetingEdit ? "form-control recurrence logit editable-selectize" : "form-control recurrence logit"}
                                  min="1"
                                  pattern="[0-9]{2}"
                                />
                              </td>
                              <td>
                                <select
                                  type="select"
                                  name="recurrenceType"
                                  onChange={handleChangeMeetings}
                                  value={meeting.recurrenceType}
                                  className={meetingEdit ? "form-control recurrence-type logit editable-selectize" : "form-control recurrence-type logit"}
                                >
                                  <option value="Day(s)">Day(s)</option>
                                  <option value="Week(s)">Week(s)</option>
                                  <option value="Month(s)">Month(s)</option>
                                  <option value="Year(s)">Year(s)</option>
                                  <option value="As needed">As needed</option>
                                </select>
                              </td>

                              <td>
                                <label>For</label>
                              </td>
                              <td>
                                <input
                                  type="number"
                                  name="duration"
                                  value={meeting.duration}
                                  onChange={handleChangeMeetings}
                                  className={meetingEdit ? "form-control duration logit editable-selectize" : "form-control duration logit"}
                                  min="1"
                                  pattern="[0-9]{2}"
                                />
                              </td>
                              <td>
                                <select
                                  type="select"
                                  name="durationType"
                                  onChange={handleChangeMeetings}
                                  value={meeting.durationType}
                                  className={meetingEdit ? "form-control duration-type logit editable-selectize" : "form-control duration-type logit"}
                                >
                                  <option value="Minutes">Minutes</option>
                                  <option value="Hours">Hours</option>
                                  <option value="As needed">As needed</option>
                                </select>
                              </td>
                            </tr>
                            <tr>
                              <td colSpan="3">
                                <input
                                  type="checkbox"
                                  name="participants"
                                  className="participants logit"
                                  id="participants"
                                  onChange={(e) => xtdHandelChange(e)}
                                  value={meeting.XTD}
                                  checked={meeting.XTD}
                                />
                                <label
                                  htmlFor="participants"
                                  className="inline-label mtop"
                                > incl. XTD
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="col-xs-12 text-right">
                        <button
                          type="button"
                          onClick={(e) => meetingSubmit(e)}
                          className="btn btn-mytpt new-meeting-btn"
                        >
                          <span className="fa fa-plus"></span>
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            setMeeting(arrs)
                            setMeetingEdit(false)
                          }}
                          className="btn btn-mytpt-gray meeting-close"
                          data-dismiss="modal"
                        >
                          <i className="fa fa-times"></i>
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <h4>Notes</h4>
                    <TextareaAutosize
                      className="form-control purpose logit"
                      name="notes"
                      // defaultValue=''
                      value={project.notes}
                      onChange={handleChange}
                      placeholder="+ Add notes"
                      // maxLength="100"
                      autoFocus={null}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group project-lead ss">
                        <h4>Lead</h4>
                        <Select
                          options={groupedOptions}
                          closeMenuOnSelect={true}
                          classNamePrefix="select"
                          className="single-select-container"
                          // components={animatedComponents}
                          isClearable
                          isSearchable
                          value={value && value.value && value.value.length > 0 ? [{
                            value: value.value,
                            label: (
                              <div style={{ fontSize: "14px" }}>
                                {/* {value.value && isExtPerson(value.value) ?
                                  <span className="custom-badge blue"  >
                                    EXT
                                  </span>
                                  : null}
                                {value.label} */}

                                {value.value && isExtPerson(value.value) ?
                                  <>
                                    <span className="custom-badge blue" style={{
                                      display: "inline-block",
                                    }}>
                                      EXT
                                    </span> {" "}{value.label}
                                  </>
                                  : value.label}
                              </div>
                            ),
                          }] : []}
                          onChange={(newValue) => handleLeadChange(newValue)}
                          formatGroupLabel={formatGroupLabel}
                          placeholder="+ Add lead"
                          components={{ Option: CustomOption }}
                        // menuIsOpen={true}
                        />
                      </div>

                      <div className="form-group project-lead-standin ss">
                        <h4>Stand In</h4>
                        <Select
                          closeMenuOnSelect={true}
                          classNamePrefix="select"
                          className="single-select-container"
                          // components={animatedComponents}
                          isClearable
                          isSearchable
                          onChange={(newValue) => selectStandIn(newValue)}
                          value={values && values.value && values.value.length > 0 ? [{
                            value: values.value,
                            label: (
                              <div style={{ fontSize: "14px" }}>
                                {/* {values.value && isExtPerson(values.value) ?
                                  <span className="custom-badge blue"  >
                                    EXT
                                  </span>
                                  : null}
                                {values.label} */}

                                {values.value && isExtPerson(values.value) ?
                                  <>
                                    <span className="custom-badge blue" style={{
                                      display: "inline-block",
                                    }}>
                                      EXT
                                    </span>{" "}{values.label}
                                  </>
                                  : values.label}
                              </div>
                            ),
                          }] : []}
                          options={onlyMemberOptions}
                          placeholder={"+ Add stand-in"}
                          components={{ Option: CustomOption }}
                        />
                      </div>

                      <div className="form-group todo">
                        <h4>Lead Tasks</h4>
                        <div className="row task-list">
                          {formValue.map((element, index) => (
                            <div
                              draggable
                              onDragStart={(e) => onDragStartLead(e, index)}
                              onDragOver={(e) => onDragOverLead(e, index)}
                              onDrop={(e) => onDropLead(e, index)}
                              className={formValue.length - 1 === index && element.leadToDos.length === 0
                                ? "col-xs-12 tasks new-task"
                                : "col-xs-12 task"
                              }
                              key={index}
                            >
                              <TextareaAutosize
                                // cacheMeasurements
                                id={`tasks${index}`}
                                type="text"
                                placeholder="+ Add task"
                                name="leadToDos"
                                className="form-control logit"
                                value={element.leadToDos || ""}
                                onChange={(e) => handleForms(index, e)}
                                onKeyPress={handleKeysPress}
                              >
                                {/* <span className="fa fa-circle"> </span> */}
                              </TextareaAutosize>
                              {formValue.length - 1 !== index &&
                                element.leadToDos.length !== 0 ? (
                                <button
                                  type="button"
                                  className="button remove btn btn-remove"
                                  onClick={() => removeFormsFields(index)}
                                >
                                  <span
                                    style={{ paddingLeft: "15%" }}
                                    className="fa fa-times"
                                    aria-hidden="true"
                                  ></span>
                                </button>
                              ) : null}
                            </div>
                          ))}
                        </div>
                      </div>

                      <div className="form-group administration">
                        <h4>Administration</h4>
                        <div className="admin-list admin-container">
                          {formsValue.map((element, index) => (
                            <div className="admin" key={index}>
                              <div
                                className="project-admin-name"
                                style={{ paddingRight: "5px" }}
                              >
                                <input
                                  type="hidden"
                                  className="form-control meeting_id"
                                  value=""
                                  onChange={handleChange}
                                />
                                <input
                                  type="text"
                                  name="statusReport"
                                  value={element.statusReport || ""}
                                  onChange={(e) => handlesForms(index, e)}
                                  className="form-control meeting-name logit"
                                  placeholder="+ Add (e.g. Status Report)"
                                  style={{ border: "none" }}
                                />
                              </div>
                              <div
                                className="pick-circle-admin-owner ss"
                                style={{ paddingRight: "0px" }}
                              >
                                <Select
                                  closeMenuOnSelect={true}
                                  classNamePrefix="select"
                                  className="single-select-container"
                                  components={animatedComponents}
                                  isClearable
                                  isSearchable
                                  name="owner"
                                  defaultValue={
                                    formsValue &&
                                      formsValue.length > 0 &&
                                      formsValue.owner &&
                                      formValue.owner.label
                                      ? [formsValue.owner.label]
                                      : []
                                  }
                                  onChange={(e) => handleoForms(index, e)}
                                  value={
                                    element.owner &&
                                      element.owner.label &&
                                      element.owner.label.length > 0
                                      ? [element.owner]
                                      : []
                                  }
                                  options={onlyMemberOptions}
                                  placeholder="+ Add owner"
                                />
                              </div>
                              <div
                                className="admin-actions text-right"
                                style={{ paddingLeft: "3px" }}
                              >
                                {index !== formsValue.length ? (
                                  <button
                                    type="button"
                                    onClick={() => removeFormsField(index)}
                                    className="btn btn-mytpt-gray btn-remove reset-admin"
                                    title="Reset"
                                  >
                                    <span className="fa fa-times"></span>
                                  </button>
                                ) : null}
                              </div>
                            </div>
                          ))}

                          <button
                            type="button"
                            onClick={() => addFormsField()}
                            className="btn btn-mytpt add-admin pull-right"
                          >
                            <span className="fa fa-plus"></span>
                          </button>
                        </div>
                      </div>
                      <Documents data={data} project={project} setDocumentData={setDocument} />
                      <div className="form-group tags">
                        <h4>Tags</h4>
                        <Tags
                          data={arr}
                          onChange={handleChange}
                          project={project}
                          name="tags"
                        />
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <div className="row">
                <div className="col-xs-4 text-left">
                  {localStorage.getItem("mode") !== "View" && (
                    <Tippy content={<span>Copy This {/* Project */}{GetAliasesName(teams, "Projects")}</span>}>
                      <button
                        type="button"
                        onClick={(e) => projectCopy(e)}
                        className="btn btn-mytpt-gray clone"
                        data-id="projectsModal"
                      >
                        <img alt="" src="./images/newicons/copy.svg" />
                      </button>
                    </Tippy>
                  )}
                  {localStorage.getItem("mode") !== "View" && project._id && !curIsFocused ? (
                    <Tippy content={<span>Delete This {/* Project */}{GetAliasesName(teams, "Projects")}</span>}>
                      <button
                        type="button"
                        onClick={projectDelete}
                        className="btn btn-mytpt-gray delete"
                        data-id="projectsModal"
                        data-post_type="domain"
                      >
                        <img alt="" src="./images/newicons/delete.svg" />
                      </button>
                    </Tippy>
                  ) : null}

                  <Tippy content={<span>Enter Focus Mode</span>}>
                    <button
                      onClick={() => ProjectMFocus(project._id)}
                      type="button"
                      className="btn btn-mytpt-gray focusit"
                      data-id="projectsModal"
                      data-dismiss="modal"
                    >
                      {/* <i className="far fa-dot-circle"></i> */}
                      <img alt="" src="./images/newicons/focus.svg" />
                    </button>
                  </Tippy>
                  <Tippy content={<span>View Feedback</span>}>
                    <button
                      onClick={() => { setShowHistory(false); setShowFeedback(!showFeedback) }}
                      style={
                        showFeedback
                          ? { backgroundColor: "#F3D068" }
                          : null
                      }
                      type="button"
                      className="btn btn-mytpt-gray history"
                    >
                      <img alt="Feedback" style={{ width: "20px" }} src="../images/feedback.png" />
                    </button>
                  </Tippy>
                  <Tippy content={<span>View History</span>}>
                    <button
                      onClick={() => { setShowHistory(!showHistory); setShowFeedback(false); }}
                      style={
                        showHistory
                          ? { backgroundColor: "#F3D068" }
                          : null
                      }
                      type="button"
                      className="btn btn-mytpt-gray history"
                    >
                      {/* <i className="fa fa-history"></i> */}
                      <img alt="" src="./images/newicons/history.svg" />
                    </button>
                  </Tippy>
                </div>
                <div className="col-xs-8 text-right">
                  <Tippy content={<span>Close Tool</span>}>
                    <button
                      type="button"
                      onClick={CloseProjectModal}
                      className="btn btn-mytpt-red"
                      data-dismiss="modal"
                    >
                      <i className="fa fa-times"></i>
                    </button>
                  </Tippy>
                  {localStorage.getItem("mode") !== "View" && (
                    <Tippy content={<span>Save And Close</span>}>
                      <button
                        type="submit"
                        className="btn btn-mytpt save-close"
                        data-modal_id="projectsModal"
                      >
                        <i className="fa fa-check"></i>
                      </button>
                    </Tippy>
                  )}
                </div>
              </div>
              {showHistory ? (
                <div className="row activities">
                  <div className="col-xs-12">
                    <h4>History</h4>
                    <History historys={historys} historyMessagee={historyMessagee} historyError={historyError} allHistory={allHistory} setAllHistory={setAllHistory} />
                  </div>
                </div>
              ) : null}

              {showFeedback ? (
                <div className="row activities">
                  <div className="col-xs-12">
                    <h4>Feedback</h4>
                    <Feedback teamId={localStorage.getItem('teamId')} cardId={data._id} peopleId={null} feedbackData={feedbackData} />
                  </div>
                </div>
              ) : null}
            </div>

          </div>
        </form>
      </div>
    </div>
  );
}
