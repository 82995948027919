export const FEEDBACK_ADD_SUCCESS = 'FEEDBACK_ADD_SUCCESS';
export const FEEDBACK_ADD_FAIL = 'FEEDBACK_ADD_FAIL';

export const ALL_FEEDBACK_GET_SUCCESS = 'ALL_FEEDBACK_GET_SUCCESS';
export const ALL_FEEDBACK_GET_ERROR = 'ALL_FEEDBACK_GET_ERROR';

export const FEEDBACK_MESSAGE_CLEAR = 'FEEDBACK_MESSAGE_CLEAR';

export const FEEDBACK_UPDATE_SUCCESS = 'FEEDBACK_UPDATE_SUCCESS';
export const FEEDBACK_UPDATE_FAIL = 'FEEDBACK_UPDATE_FAIL';

export const FEEDBACK_DELETE_SUCCESS = 'FEEDBACK_DELETE_SUCCESS';
export const FEEDBACK_DELETE_FAIL = 'FEEDBACK_DELETE_FAIL';
