import React, { useEffect, useRef } from 'react'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import PillComponent from '../PillComponent';
import { FadePopoverTrigger, handleFadeInOut } from './FadeInOutPopover';
import { IsFadeIn, IsFadeOut } from '../../../HelperFunctions/FadeInOutChecker';
import TextareaAutosize from "react-textarea-autosize";
import useRestoreScroll from '../../../HelperFunctions/useRestoreScroll';

export default function FPDomainsList({ peoples, tagFilteredMegedData, handleAliesChange, GetAliasesName, teams, PfoDomains, clickedOnList, toggleEXTnNOCards, getSortIcon, isInfos, pCardHideOnchange, handleDragStart, handleDragEndPF, note, handleSave, noteSaveData, isTag, isEXT, isNO, TagWiseSorting, tagFilteredObj, extNoFilteredObj, IsOwnerExternal, dWhoIsOwnerType, dIsDirectMember, peopleSorted, focused, setDomain, workloadToggle, sumFTEBody_Focus, cardOpensCfDomain, toggleTagCards, PDomainsSub, fpDref, domainfSearch, setDomainfSearch, filteredDFData, PDomainsAdd, lFromState, domainSubmit, dref, domain, handleDomainChange, setDomains, setDomainName, domainsFrom, noteOnchange, list, show, setShow }) {

    const FPDomainsListRef = useRef(null);
    useRestoreScroll(FPDomainsListRef, "Domain", filteredDFData);

    return (
        <div
            className={
                !clickedOnList.listName.includes("domain") && Object.keys(tagFilteredMegedData).length > 0 &&
                    tagFilteredMegedData.domain &&
                    tagFilteredMegedData.domain.length === 0
                    ? "board-col hide"
                    : "board-col"
            }
            data-board_id="domains"
        >
            <div className="list list-bg-blue" data-list_id="domains">
                <div className="dropdown">
                    <h4 className="list-title">
                        <img alt="" src="images/icon-domain.png" /> {/* {GetAliasesName(teams, "Domains")} (
            <span>{}</span>){" "} */}
                        <span contentEditable={true}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault(); // Prevent new line
                                }
                            }} data-name="domains" onBlur={(e) => handleAliesChange(e)} suppressContentEditableWarning={true}>{GetAliasesName(teams, "Domains")}</span>
                        <span>{` (${PfoDomains.length})`}</span>{" "}
                        <a
                            className={
                                clickedOnList.isClicked &&
                                    clickedOnList.listName.includes("domain")
                                    ? "list-menu refresh-list"
                                    : "list-menu refresh-list hide"
                            }
                            style={{cursor: "pointer"}}
                            onClick={() => {
                                toggleEXTnNOCards("domains", "", true, "list");
                            }}
                        >
                            <i className="fas fa-sync-alt"></i>
                        </a>{" "}
                        <a
                            className={
                                clickedOnList.isClicked &&
                                    clickedOnList.listName.includes("domain")
                                    ? "list-menu dropdown-toggle hide"
                                    : "list-menu dropdown-toggle"
                            }
                            data-toggle="dropdown"
                            style={{cursor: "pointer"}}
                        >
                            {getSortIcon("Domains")}
                            <i className="fas fa-ellipsis-v"></i>
                        </a>
                        <ul className="dropdown-menu dropdown-menu-right" role="menu">
                            <li role="presentation">
                                <a
                                    onClick={() => isInfos("Domains")}
                                    role="menuitem"
                                    className="mytpt-list-info"
                                >
                                    Info
                                </a>
                            </li>
                            <li role="presentation">
                                <a
                                    role="menuitem"
                                    data-toggle="modal"
                                    data-target="#domainModal"
                                >
                                    Add new {GetAliasesName(teams, "Domains").toLowerCase()}
                                </a>
                            </li>
                            <li role="presentation">
                                <a
                                    role="menuitem"
                                    onClick={() => pCardHideOnchange("Domains")}
                                    className="hide_list"
                                >
                                    Hide list
                                </a>
                            </li>
                            <li role="presentation">
                                <a role="menuitem" style={{ paddingRight: "5px" }}>
                                    Show Notes
                                    <label className="switch">
                                        <input
                                            name="Domains"
                                            onChange={(e) => noteOnchange(e)}
                                            checked={note.Domains}
                                            className="dd-switch"
                                            type="checkbox"
                                        />
                                        <span className="slider"></span>
                                    </label>
                                </a>
                            </li>
                            <li role="presentation" className="divider"></li>
                            <li
                                role="presentation"
                                onClick={() => {
                                    toggleEXTnNOCards("domains", "EXT", false);
                                }}
                            >
                                <a role="menuitem" className="show-external">
                                    Show items owned by external
                                </a>
                            </li>
                            <li
                                role="presentation"
                                onClick={() => {
                                    toggleEXTnNOCards("domains", "NO", false);
                                }}
                            >
                                <a role="menuitem" className="show-noowners">
                                    Show items without owners
                                </a>
                            </li>
                        </ul>
                    </h4>
                </div>

                <DragDropContext
                    onDragStart={handleDragStart}
                    onDragEnd={handleDragEndPF}
                >
                    <div className="card-list scrollbar"
                        ref={FPDomainsListRef}
                        onScroll={(e) => localStorage.setItem('scrollTopDomain', e.target.scrollTop)}>
                        <div
                            className="form-group notes"
                            style={note.Domains ? { display: "block" } : { display: "none" }}
                        >
                            <TextareaAutosize
                                className="notes"
                                name="Domains"
                                // defaultValue=''
                                // value={noteSaveData.Domains}
                                //   onChange={(e) => handleSave(e)}
                                onBlur={(e) => handleSave(e)}
                                placeholder="Notes"
                                // maxLength="100"
                                defaultValue={noteSaveData.Domains}
                                minRows={3} // Set the minimum number of rows
                            />
                        </div>

                        <Droppable
                            className="panel panel-default"
                            droppableId="domains-core"
                            direction="vertical"
                        >
                            {(domainsProvidedPf) => (
                                <div
                                    ref={domainsProvidedPf.innerRef}
                                    {...domainsProvidedPf.droppableProps}
                                    id="core"
                                    className="panel-body box-container"
                                >
                                    {/* members listed here*/}
                                    {PfoDomains && PfoDomains.length > 0
                                        ? PfoDomains.map((e, index) => {
                                            if (isTag || isEXT || isNO) {
                                                let Proceed = TagWiseSorting(
                                                    e,
                                                    isTag,
                                                    isEXT,
                                                    isNO,
                                                    tagFilteredObj,
                                                    extNoFilteredObj,
                                                    "domain"
                                                );
                                                if (!Proceed) return;
                                            }
                                            let isExternal = IsOwnerExternal(e, "Domain");
                                            let whoIsOwnerType = dWhoIsOwnerType(e);
                                            let isDirectMember = dIsDirectMember(e);
                                            let flag = false;
                                            if (
                                                e &&
                                                e.standIn &&
                                                e.standIn.length > 0 &&
                                                peopleSorted.length > 0 &&
                                                focused &&
                                                focused.card &&
                                                focused.card.length > 0
                                            ) {
                                                if (e.standIn === focused.id) {
                                                    flag = true;
                                                }
                                            }
                                            return (
                                                <Draggable
                                                    key={e.id}
                                                    draggableId={`${e._id}`}
                                                    index={index}
                                                >
                                                    {(domainsProvidedPf) => (
                                                        <div
                                                            ref={domainsProvidedPf.innerRef}
                                                            {...domainsProvidedPf.draggableProps}
                                                            {...domainsProvidedPf.dragHandleProps}
                                                            // onDragStart={(e) => domainsDragStart(e, index)}
                                                            // onDragEnter={(e) => domainsDragEnter(e, index)}
                                                            // onDragEnd={() => domainsDrops(e, "corDomains")}
                                                            // draggable
                                                            key={e._id}
                                                            className="card"
                                                            data-list_id="domain"
                                                            onClick={(event) => {
                                                                if (
                                                                    event.target.classList.contains("card") ||
                                                                    event.target.classList.contains("card-edit")
                                                                ) {
                                                                    setDomain(e, PfoDomains, index);
                                                                }
                                                            }}
                                                        >
                                                            <div className="static-relations">
                                                                {workloadToggle === true && (
                                                                    <PillComponent
                                                                        occupied={sumFTEBody_Focus(
                                                                            e.workload,
                                                                            focused.id
                                                                        )}
                                                                        // total={GetPersonAvailability(peoples,focused.id)}
                                                                        // total={GetTotalWorkLoad(e, "DOMAINS")}
                                                                        total={e.planTotal}
                                                                        type="TEXT"
                                                                    />
                                                                )}

                                                                {isExternal ? (
                                                                    <span
                                                                        onClick={() => {
                                                                            toggleEXTnNOCards(
                                                                                e._id,
                                                                                "EXT",
                                                                                false,
                                                                                "pill"
                                                                            );
                                                                        }}
                                                                        className="custom-badge blue ext-owner-pill"
                                                                    >
                                                                        EXT
                                                                    </span>
                                                                ) : null}

                                                                {whoIsOwnerType &&
                                                                    Object.keys(whoIsOwnerType).length > 0 ? (
                                                                    <span
                                                                        onClick={() =>
                                                                            cardOpensCfDomain(whoIsOwnerType)
                                                                        }
                                                                        className="custom-badge lightgray"
                                                                    >
                                                                        {whoIsOwnerType.name}
                                                                    </span>
                                                                ) : null}

                                                                {e.owners &&
                                                                    e.owners.owner &&
                                                                    e.owners.owner.length > 0 ? null : (
                                                                    <span
                                                                        onClick={() => {
                                                                            toggleEXTnNOCards(
                                                                                e._id,
                                                                                "NO",
                                                                                false,
                                                                                "pill"
                                                                            );
                                                                        }}
                                                                        className="custom-badge no-owner-pill red"
                                                                    >
                                                                        NO
                                                                    </span>
                                                                )}

                                                                {flag ? (
                                                                    <span className="custom-badge gray-o">
                                                                        STAND IN
                                                                    </span>
                                                                ) : null}

                                                                {/* fade-in and fade-out pills - START */}
                                                                {IsFadeIn(focused.id, peoples, e._id) && <span
                                                                    onClick={() => {
                                                                        // Yet to be developed
                                                                        // handleFadeInOut();
                                                                    }}
                                                                    className="custom-badge fade-in"
                                                                >
                                                                    <img src="/images/fadein.png" />
                                                                </span>}

                                                                {/* fade-in and fade-out pills - END */}
                                                                {IsFadeOut(focused.id, peoples, e._id) && <span
                                                                    onClick={() => {
                                                                        // Yet to be developed
                                                                        // handleFadeInOut();
                                                                    }}
                                                                    className="custom-badge fade-out"
                                                                >
                                                                    <img src="/images/fadeout.png" />
                                                                </span>}

                                                                {e.tags && e.tags.length > 0
                                                                    ? e.tags.map((r, indexs) => (
                                                                        <span
                                                                            onClick={() => toggleTagCards(r)}
                                                                            key={indexs}
                                                                            className="custom-badge green tags"
                                                                        >
                                                                            {r}
                                                                        </span>
                                                                    ))
                                                                    : null}
                                                            </div>

                                                            <a
                                  /* onClick={() => setDomain(e, PfoDomains, index)} */ className="card-edit"
                                                            >
                                                                {e.domainName}
                                                            </a>

                                                            {isDirectMember && !flag ? (
                                                                <>
                                                                    <a
                                                                        className="card-unlink"
                                                                        style={{ display: "block", color: "red", right: "25px" }}
                                                                    >
                                                                        <i
                                                                            onClick={() => PDomainsSub(e)}
                                                                            className="icon far fa-times-circle"
                                                                        ></i>
                                                                    </a>

                                                                </>
                                                            ) : null}

                                                            <FadePopoverTrigger
                                                                id={e._id}
                                                                focusPeopleId={focused.id}
                                                                show={show}
                                                                setShow={setShow}
                                                                placement={list[list.length - 1] === 'Domains' ? 'left' : 'right'}
                                                                handleFadeInOut={(e, type) => handleFadeInOut(e, type)}
                                                            />
                                                        </div>
                                                    )}
                                                </Draggable>
                                            );
                                        })
                                        : null}
                                    {domainsProvidedPf.placeholder}
                                </div>
                            )}
                        </Droppable>

                        <span className="clearable2">
                            <input
                                type="text"
                                ref={fpDref}
                                style={{ color: "black" }}
                                value={domainfSearch}
                                onChange={(e) => setDomainfSearch(e.target.value)}
                                className="form-control search_list2 transparent"
                                name="search_list"
                                placeholder="Search to select more"
                                autoComplete="off"
                            />
                            {domainfSearch.length > 0 ? (
                                <i
                                    className="clearable__clear"
                                    onClick={() => setDomainfSearch("")}
                                    style={{ display: "inline", color: "black" }}
                                >
                                    ×
                                </i>
                            ) : null}
                        </span>

                        <Droppable
                            className="panel panel-default"
                            droppableId="domains-notmatched"
                            direction="vertical"
                        >
                            {(domainsProvidedPf) => (
                                <div
                                    ref={domainsProvidedPf.innerRef}
                                    {...domainsProvidedPf.droppableProps}
                                    id="notmatched"
                                    className="panel-body box-container"
                                >
                                    {filteredDFData && filteredDFData.length > 0
                                        ? filteredDFData.map((e, index) => {
                                            if (isTag || isEXT || isNO) {
                                                let Proceed = TagWiseSorting(
                                                    e,
                                                    isTag,
                                                    isEXT,
                                                    isNO,
                                                    tagFilteredObj,
                                                    extNoFilteredObj,
                                                    "domain"
                                                );
                                                if (!Proceed) return;
                                            }
                                            let isExternal = IsOwnerExternal(e, "Domain");
                                            let whoIsOwnerType = dWhoIsOwnerType(e);
                                            return (
                                                <Draggable
                                                    key={e.id}
                                                    draggableId={`${e._id}`}
                                                    index={index}
                                                    isDragDisabled={e.owners && e.owners.type ? true : false}
                                                >
                                                    {(domainsProvidedPf) => (
                                                        <div
                                                            ref={domainsProvidedPf.innerRef}
                                                            {...domainsProvidedPf.draggableProps}
                                                            {...domainsProvidedPf.dragHandleProps}
                                                            // onDragStart={(e) => domainsDragStart(e, index)}
                                                            // onDragEnter={(e) => domainsDragEnter(e, index)}
                                                            // onDragEnd={() => domainsDrops(e, "defDomains")}
                                                            // draggable
                                                            key={e._id}
                                                            className={`card notmatched ${localStorage.getItem("latest-created") ===
                                                                e._id
                                                                ? " latest-created "
                                                                : ""
                                                                }`}
                                                            data-list_id="domains"
                                                        >
                                                            <div className="static-relations">
                                                                {whoIsOwnerType &&
                                                                    Object.keys(whoIsOwnerType).length > 0 ? (
                                                                    <span
                                                                        onClick={() =>
                                                                            cardOpensCfDomain(whoIsOwnerType)
                                                                        }
                                                                        className="custom-badge lightgray"
                                                                    >
                                                                        {whoIsOwnerType.name}
                                                                    </span>
                                                                ) : null}
                                                                {isExternal ? (
                                                                    <span
                                                                        onClick={() => {
                                                                            toggleEXTnNOCards(
                                                                                e._id,
                                                                                "EXT",
                                                                                false,
                                                                                "pill"
                                                                            );
                                                                        }}
                                                                        className="custom-badge blue ext-owner-pill"
                                                                    >
                                                                        EXT
                                                                    </span>
                                                                ) : null}
                                                                {e.owners &&
                                                                    e.owners.owner &&
                                                                    e.owners.owner.length > 0 ? null : (
                                                                    <span
                                                                        onClick={() => {
                                                                            toggleEXTnNOCards(
                                                                                e._id,
                                                                                "NO",
                                                                                false,
                                                                                "pill"
                                                                            );
                                                                        }}
                                                                        className="custom-badge no-owner-pill red"
                                                                    >
                                                                        NO
                                                                    </span>
                                                                )}
                                                                {e.tags && e.tags.length > 0
                                                                    ? e.tags.map((r, indexs) => (
                                                                        <span
                                                                            onClick={() => toggleTagCards(r)}
                                                                            key={indexs}
                                                                            className="custom-badge green tags"
                                                                        >
                                                                            {r}
                                                                        </span>
                                                                    ))
                                                                    : null}
                                                            </div>

                                                            <a
                                                                onClick={() =>
                                                                    setDomain(e, filteredDFData, index)
                                                                }
                                                                className="card-edit"
                                                            >
                                                                {e.domainName}
                                                            </a>

                                                            {e.owners && !e.owners.type ? (
                                                                <a
                                                                    className="card-link"
                                                                    style={{ display: "block" }}
                                                                >
                                                                    <i
                                                                        onClick={() => PDomainsAdd(e)}
                                                                        className="icon fa fa-plus-circle"
                                                                    ></i>
                                                                </a>
                                                            ) : null}
                                                        </div>
                                                    )}
                                                </Draggable>
                                            );
                                        })
                                        : null}
                                    {domainsProvidedPf.placeholder}
                                </div>
                            )}
                        </Droppable>

                        <div
                            className="card2"
                            style={
                                lFromState.Domains ? { display: "block" } : { display: "none" }
                            }
                        >
                            <form
                                className="form-new-card"
                                role="form"
                                onSubmit={domainSubmit}
                            >
                                <div className="form-group">
                                    <input
                                        type="text"
                                        ref={dref}
                                        name="card_name"
                                        required
                                        placeholder={`Add ${GetAliasesName(teams, "Domains").toLowerCase()} name`}
                                        className="form-control"
                                        value={domain}
                                        onChange={handleDomainChange}
                                        autoComplete="off"
                                    />
                                </div>
                                <div className="form-group" style={{ marginTop: "10px" }}>
                                    <button type="submit" className="btn btn-mytpt add-quick">
                                        Save
                                    </button>
                                    <a
                                        style={{cursor: "pointer"}}
                                        type="button"
                                        onClick={() => {
                                            setDomains({ domainName: domain });
                                            setDomainName("");
                                            lFromState.Domains = false;
                                        }}
                                        className="btn btn-link underline open-tool"
                                    >
                                        Edit details
                                    </a>
                                    <a
                                        style={{cursor: "pointer"}}
                                        onClick={() => domainsFrom()}
                                        className="btn btn-link underline close-add-card"
                                    >
                                        Close
                                    </a>
                                </div>
                            </form>
                        </div>
                    </div>
                </DragDropContext>
                <a
                    className="btn-list add-card"
                    onClick={() => domainsFrom()}
                    data-list_id="domain"
                    style={
                        !lFromState.Domains ? { display: "block" } : { display: "none" }
                    }
                >
                    + Add new {/*domain */} {GetAliasesName(teams, "Domains").toLowerCase()}
                </a>
            </div>
        </div>
    )
}
