import React, { useEffect, useRef } from 'react'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import PillComponent from '../PillComponent';
import TextareaAutosize from "react-textarea-autosize";
import useRestoreScroll from '../../../HelperFunctions/useRestoreScroll';

export default function RoleList({ showGenericRole, tagFilteredMegedData, handleAliesChange, GetAliasesName, teams, filteredRData, clickedOnList, toggleEXTnNOCards, getSortIcon, isInfos, setRoles, pCardHideOnchange, noteOnchange, note, handeSorts, setCombinedRefs, parentRoleRef, handleSave, noteSaveData, sRref, roleSearch, setRoleSearch, isTag, isEXT, isNO, TagWiseSorting, tagFilteredObj, extNoFilteredObj, IsOwnerExternal, statesLM, setRole, setCardRef, workloadToggle, sumFTEBody, OwnerExist, toggleTagCards, compareMode, handleCheckboxSelection, IsChecked, lFromState, roleSubmit, rref, role, handleRoleChange, setRoleName, rolesFrom, dRoles
}) {

    const RoleListRef = useRef(null);
    useRestoreScroll(RoleListRef, "Role", filteredRData);

    return (
        <div
            className={
                !clickedOnList.listName.includes("role") && Object.keys(tagFilteredMegedData).length > 0 &&
                    tagFilteredMegedData.role &&
                    tagFilteredMegedData.role.length === 0
                    ? "board-col hide"
                    : "board-col"
            }
            data-board_id="roles"
        >
            <div className="list list-bg-blue" data-list_id="roles">
                <div className="dropdown">
                    <h4 className="list-title">
                        <img alt="" src="images/icon-role.png" />
                        <span contentEditable={true}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault(); // Prevent new line
                                }
                            }}
                            data-name="roles"
                            onBlur={(e) => handleAliesChange(e)}
                            suppressContentEditableWarning={true}
                        >{GetAliasesName(teams, "Roles")}</span>
                        <span>{` (${filteredRData.length})`}</span>
                        <a
                            className={
                                clickedOnList.isClicked &&
                                    clickedOnList.listName.includes("role")
                                    ? "list-menu refresh-list"
                                    : "list-menu refresh-list hide"
                            }
                            // className="list-menu refresh-list hide"
                            style={{cursor: "pointer"}}
                            onClick={() => {
                                toggleEXTnNOCards("roles", "", true, "list");
                            }}
                        >
                            <i className="fas fa-sync-alt"></i>
                        </a>
                        <a
                            className={
                                clickedOnList.isClicked &&
                                    clickedOnList.listName.includes("role")
                                    ? "list-menu dropdown-toggle hide"
                                    : "list-menu dropdown-toggle"
                            }
                            // className="list-menu dropdown-toggle"
                            data-toggle="dropdown"
                            style={{cursor: "pointer"}}
                        >
                            {getSortIcon("Roles")}
                            <i className="fas fa-ellipsis-v"></i>
                        </a>
                        <ul className="dropdown-menu dropdown-menu-right" role="menu">
                            <li role="presentation">
                                <a
                                    onClick={() => isInfos("Roles")}
                                    role="menuitem"
                                    className="mytpt-list-info"
                                >
                                    Info
                                </a>
                            </li>
                            <li role="presentation">
                                <a role="menuitem" type="button" onClick={setRoles}>
                                    Add new {/* role */}{GetAliasesName(teams, "Roles").toLowerCase()}
                                </a>
                            </li>
                            <li role="presentation">
                                <a
                                    role="menuitem"
                                    onClick={() => pCardHideOnchange("Roles")}
                                    className="hide_list"
                                >
                                    Hide list
                                </a>
                            </li>
                            <li role="presentation">
                                <a role="menuitem" style={{ paddingRight: "5px" }}>
                                    Show Notes
                                    <label className="switch">
                                        <input
                                            name="Roles"
                                            onChange={(e) => noteOnchange(e)}
                                            checked={note.Roles}
                                            className="dd-switch"
                                            type="checkbox"
                                        />
                                        <span className="slider"></span>
                                    </label>
                                </a>
                            </li>
                            <li role="presentation" className="divider"></li>
                            <li className="dropdown-header">Sort by:</li>

                            <li role="presentation">
                                <a
                                    role="menuitem"
                                    className="sortcards"
                                    data-sort_order="n"
                                    onClick={() => handeSorts("NewToOld", "Roles")}
                                >
                                    New to Old
                                </a>
                            </li>
                            <li role="presentation">
                                <a
                                    role="menuitem"
                                    className="sortcards"
                                    data-sort_order="nd"
                                    onClick={() => handeSorts("OldToNew", "Roles")}
                                >
                                    Old to New
                                </a>
                            </li>
                            <li role="presentation">
                                <a
                                    role="menuitem"
                                    className="sortcards"
                                    data-sort_order="asc"
                                    onClick={() => handeSorts("AtoZ", "Roles")}
                                >
                                    A-Z
                                </a>
                            </li>
                            <li role="presentation">
                                <a
                                    role="menuitem"
                                    className="sortcards"
                                    data-sort_order="desc"
                                    onClick={() => handeSorts("ZtoA", "Roles")}
                                >
                                    Z-A
                                </a>
                            </li>
                            <li role="presentation" className="divider"></li>

                            <li role="presentation" className="divider"></li>
                            <li role="presentation">
                                <a
                                    role="menuitem"
                                    className="show-external"
                                    onClick={() => {
                                        toggleEXTnNOCards("roles", "EXT", false);
                                    }}
                                >
                                    Show items owned by external
                                </a>
                            </li>
                            <li role="presentation">
                                <a
                                    role="menuitem"
                                    className="show-noowners"
                                    onClick={() => {
                                        toggleEXTnNOCards("roles", "NO", false);
                                    }}
                                >
                                    Show items without owners
                                </a>
                            </li>
                        </ul>
                    </h4>
                </div>
                <Droppable droppableId="roles" direction="vertical">
                    {(rolesProvided) => (
                        <div
                            // ref={rolesProvided.innerRef}
                            // {...rolesProvided.droppableProps}
                            // ref={setCombinedRefs(rolesProvided.innerRef, parentRoleRef)}
                            ref={setCombinedRefs(rolesProvided.innerRef, RoleListRef)}
                            {...rolesProvided.droppableProps}
                            className="card-list sortable-card-list scrollbar"
                            onScroll={(e) => localStorage.setItem('scrollTopRole', e.target.scrollTop)}
                        >
                            <div
                                className="form-group notes"
                                style={
                                    note.Roles ? { display: "block" } : { display: "none" }
                                }
                            >
                                <TextareaAutosize
                                    className="notes"
                                    name="Roles"
                                    // defaultValue=''
                                    // value={noteSaveData.Roles}
                                    // onChange={(e) => handleSave(e)}
                                    onBlur={(e) => handleSave(e)}
                                    placeholder="Notes"
                                    // maxLength="100"
                                    defaultValue={noteSaveData.Roles}
                                    minRows={3} // Set the minimum number of rows
                                />
                            </div>
                            <span className="clearable">
                                <input
                                    type="text"
                                    style={{ color: "black" }}
                                    ref={sRref}
                                    value={roleSearch}
                                    onChange={(e) => setRoleSearch(e.target.value)}
                                    className="form-control search_list transparent"
                                    name="search_list"
                                    placeholder="Start typing to search..."
                                    autoComplete="off"
                                />
                                {roleSearch.length > 0 ? (
                                    <i
                                        className="clearable__clear"
                                        onClick={() => setRoleSearch("")}
                                        style={{ display: "inline", color: "black" }}
                                    >
                                        ×
                                    </i>
                                ) : null}
                            </span>

                            {filteredRData && filteredRData.length > 0
                                ? filteredRData.map((e, index) => {
                                    if (isTag || isEXT || isNO) {
                                        let Proceed = TagWiseSorting(
                                            e,
                                            isTag,
                                            isEXT,
                                            isNO,
                                            tagFilteredObj,
                                            extNoFilteredObj,
                                            "role"
                                        );
                                        if (!Proceed) return;
                                    }
                                    let isExternal = IsOwnerExternal(e, "Role");
                                    if (e.defaultRole) return;

                                    return (
                                        <Draggable
                                            key={index}
                                            draggableId={`${e._id}`}
                                            index={index}
                                            isDragDisabled={
                                                statesLM.Roles !== "Parsonal" ? true : false
                                            }
                                        >
                                            {(rolesProvided) => (
                                                <div
                                                    ref={rolesProvided.innerRef}
                                                    {...rolesProvided.draggableProps}
                                                    {...rolesProvided.dragHandleProps}
                                                    // onDragStart={(e) => rolesDragStart(e, index)}
                                                    // onDragEnter={(e) => rolesDragEnter(e, index)}
                                                    // onDragEnd={rolesDrop}

                                                    key={index}
                                                    className={`card ${localStorage.getItem("latest-created") ===
                                                        e._id
                                                        ? "latest-created"
                                                        : ""
                                                        }`}
                                                    onClick={(event) => {
                                                        if (
                                                            event.target.classList.contains("card") ||
                                                            event.target.classList.contains("card-edit")
                                                        ) {
                                                            setRole(e, filteredRData, index);
                                                        }
                                                        /* const scrollTop =
                                                            parentRoleRef.current.scrollTop;
                                                        setCardRef(prevCardRef =>
                                                            prevCardRef.map(item =>
                                                                item.type === "role" ? { ...item, val: scrollTop } : item
                                                            )
                                                        ); */
                                                    }}
                                                >
                                                    <div className="static-relations">
                                                        {workloadToggle === true && (
                                                            <PillComponent
                                                                occupied={sumFTEBody(
                                                                    e.workload,
                                                                    e.owners
                                                                )}
                                                                total={e.planTotal}
                                                            />
                                                        )}
                                                        {isExternal === true ? (
                                                            <span
                                                                onClick={() => {
                                                                    toggleEXTnNOCards(
                                                                        e._id,
                                                                        "EXT",
                                                                        false,
                                                                        "pill"
                                                                    );
                                                                }}
                                                                className="custom-badge blue ext-owner-pill"
                                                            >
                                                                EXT
                                                            </span>
                                                        ) : null}
                                                        {e.owners.length === 0 ||
                                                            !OwnerExist(e.owners) ? (
                                                            <span
                                                                onClick={() => {
                                                                    toggleEXTnNOCards(
                                                                        e._id,
                                                                        "NO",
                                                                        false,
                                                                        "pill"
                                                                    );
                                                                }}
                                                                className="custom-badge no-owner-pill red"
                                                            >
                                                                NO
                                                            </span>
                                                        ) : null}
                                                        {e.ownerRole.length > 0 &&
                                                            e.ownerRole === "Single owner" ? (
                                                            <span className="custom-badge blue-o single-owner-pill">
                                                                1
                                                            </span>
                                                        ) : null}
                                                        {e.tags && e.tags.length > 0
                                                            ? e.tags.map((r, indexs) => (
                                                                <span
                                                                    onClick={() => toggleTagCards(r)}
                                                                    key={indexs}
                                                                    className="custom-badge green tags"
                                                                >
                                                                    {r}
                                                                </span>
                                                            ))
                                                            : null}
                                                    </div>
                                                    <a
                                                        // onClick={() => setRole(e, filteredRData, index)}
                                                        className="card-edit"
                                                    >
                                                        {e.roleName}
                                                    </a>
                                                    {compareMode.active &&
                                                        compareMode.compareTypes /*&&  showCheckBox("role")*/ && (
                                                            <input
                                                                type="checkbox"
                                                                id={e._id}
                                                                name={e.roleName}
                                                                onChange={() =>
                                                                    handleCheckboxSelection(e._id, "role")
                                                                }
                                                                checked={IsChecked(e._id)}
                                                            />
                                                        )}
                                                </div>
                                            )}
                                        </Draggable>
                                    );
                                })
                                : null}

                            {showGenericRole && dRoles && dRoles.length > 0
                                ? dRoles.map((d, index) => {
                                    if (isTag || isEXT || isNO) {
                                        let Proceed = TagWiseSorting(
                                            d,
                                            isTag,
                                            isEXT,
                                            isNO,
                                            tagFilteredObj,
                                            extNoFilteredObj,
                                            "role"
                                        );
                                        if (!Proceed) return;
                                    }
                                    return (
                                        <div
                                            key={index}
                                            className="card default-role"
                                            data-card_name="Circle Lead"
                                            data-default="true"
                                            data-html2canvas-ignore="true"
                                        >
                                            <div className="static-relations">
                                                {workloadToggle === true && (
                                                    <PillComponent
                                                        occupied={sumFTEBody(d.workload, d.owners)}
                                                        total={d.planTotal}
                                                    />
                                                )}

                                                {d.tags && d.tags.length > 0
                                                    ? d.tags.map((r, indexs) => (
                                                        <span
                                                            onClick={() => toggleTagCards(r)}
                                                            key={indexs}
                                                            className="custom-badge green tags"
                                                        >
                                                            {r}
                                                        </span>
                                                    ))
                                                    : null}
                                            </div>
                                            <a
                                                onClick={() => {
                                                    setRole(d, dRoles);
                                                    const scrollTop =
                                                        parentRoleRef.current.scrollTop;
                                                    setCardRef(prevCardRef =>
                                                        prevCardRef.map(item =>
                                                            item.type === "role" ? { ...item, val: scrollTop } : item
                                                        )
                                                    );
                                                }}
                                                className="card-edit"
                                            >
                                                {/* {d.roleName} */}{d.roleName ? `${GetAliasesName(teams, d.roleName.split(" ")[0]) || ""} ${d.roleName.split(" ")[1] || ""} ` : d.roleName}
                                            </a>
                                        </div>
                                    );
                                })
                                : null}

                            {rolesProvided.placeholder}

                            <div
                                className="card2"
                                style={
                                    lFromState.Roles
                                        ? { display: "block" }
                                        : { display: "none" }
                                }
                            >
                                <form
                                    className="form-new-card"
                                    role="form"
                                    onSubmit={roleSubmit}
                                >
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            ref={rref}
                                            name="card_name"
                                            value={role}
                                            onChange={handleRoleChange}
                                            required
                                            placeholder={`Add ${GetAliasesName(teams, "Roles").toLowerCase()} name`}
                                            className="form-control"
                                            autoComplete="off"
                                        />
                                    </div>
                                    <div className="form-group" style={{ marginTop: "10px" }}>
                                        <button
                                            type="submit"
                                            className="btn btn-mytpt add-quick close-add-card"
                                        >
                                            Save
                                        </button>
                                        <a
                                            style={{cursor: "pointer"}}
                                            type="button"
                                            onClick={() => {
                                                setRoles({ roleName: role });
                                                setRoleName("");
                                                lFromState.Roles = false;
                                            }}
                                            className="btn btn-link underline open-tool"
                                        >
                                            Edit details
                                        </a>
                                        <a
                                            style={{cursor: "pointer"}}
                                            onClick={() => rolesFrom()}
                                            className="btn btn-link underline close-add-card"
                                        >
                                            Close
                                        </a>
                                    </div>
                                </form>
                            </div>
                        </div>
                    )}
                </Droppable>
                <a
                    className="btn-list add-card"
                    onClick={() => rolesFrom()}
                    data-list_id="roles"
                    style={
                        !lFromState.Roles ? { display: "block" } : { display: "none" }
                    }
                >
                    + Add new {/* role */}{GetAliasesName(teams, "Roles").toLowerCase()}
                </a>
            </div>
        </div>
    );
}
