import React, { useEffect, useRef } from 'react'
import TextareaAutosize from "react-textarea-autosize";
import useRestoreScroll from '../../../HelperFunctions/useRestoreScroll';
export default function PrLinksList({ tagFilteredMegedData, handleAliesChange, GetAliasesName, teams, PrcorfoLinks, PrextfoLinks, clickedOnList, toggleEXTnNOCards, getSortIcon, isInfos, pCardHideOnchange, note, handleSave, noteSaveData, pFLonDrop, pFLonDragOver, isTag, isEXT, isNO, TagWiseSorting, IsOwnerExternal, isPFProjectsAddL, pFLonDrag, setLink, OwnerExist, toggleTagCards, pFlinkSub, fsprLref, pfLinksSearch, setPfLinksSearch, filteredPFLData, OverlayTrigger, list, popover, cFPeopleHandel, lFromState, linkSubmit, lref, handleLinkChange, setLinks, setLinkName, linksFrom, tagFilteredObj, extNoFilteredObj, link }) {

  const PrLinkListRef = useRef(null);
  useRestoreScroll(PrLinkListRef, "Link", filteredPFLData);

  return (
    <div
      className={
        !clickedOnList.listName.includes("link") && Object.keys(tagFilteredMegedData).length > 0 &&
          tagFilteredMegedData.link &&
          tagFilteredMegedData.link.length === 0
          ? "board-col hide"
          : "board-col"
      }
      data-board_id="links"
    >
      <div className="list list-bg-blue" data-list_id="links">
        <div className="dropdown">
          <h4 className="list-title">
            <img alt="" src="images/icon-link.png" /> {/* {GetAliasesName(teams, "Links")} (
            <span>{}</span>){" "} */}
            <span contentEditable={true}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault(); // Prevent new line
                }
              }} data-name="links" onBlur={(e) => handleAliesChange(e)} suppressContentEditableWarning={true}>{GetAliasesName(teams, "Links")}</span>
            <span>{` (${PrcorfoLinks.length + PrextfoLinks.length})`}</span>
            <a
              className={
                clickedOnList.isClicked &&
                  clickedOnList.listName.includes("link")
                  ? "list-menu refresh-list"
                  : "list-menu refresh-list hide"
              }
              style={{cursor: "pointer"}}
              onClick={() => {
                toggleEXTnNOCards("links", "", true, "list");
              }}
            >
              <i className="fas fa-sync-alt"></i>
            </a>{" "}
            <a
              className={
                clickedOnList.isClicked &&
                  clickedOnList.listName.includes("link")
                  ? "list-menu dropdown-toggle hide"
                  : "list-menu dropdown-toggle"
              }
              data-toggle="dropdown"
              style={{cursor: "pointer"}}
            >
              {getSortIcon("Links")}
              <i className="fas fa-ellipsis-v"></i>
            </a>
            <ul className="dropdown-menu dropdown-menu-right" role="menu">
              <li role="presentation">
                <a
                  onClick={() => isInfos("Links")}
                  role="menuitem"
                  className="mytpt-list-info"
                >
                  Info
                </a>
              </li>
              <li role="presentation">
                <a
                  role="menuitem"
                  data-toggle="modal"
                  data-target="#linksModal"
                >
                  Add new {GetAliasesName(teams, "Links").toLowerCase()}
                </a>
              </li>
              <li role="presentation">
                <a
                  role="menuitem"
                  onClick={() => pCardHideOnchange("Links")}
                  className="hide_list"
                >
                  Hide list
                </a>
              </li>
              <li role="presentation" className="divider"></li>
              <li
                role="presentation"
                onClick={() => {
                  toggleEXTnNOCards("links", "EXT", false);
                }}
              >
                <a role="menuitem" className="show-external">
                  Show items owned by external
                </a>
              </li>
              <li
                role="presentation"
                onClick={() => {
                  toggleEXTnNOCards("links", "NO", false);
                }}
              >
                <a role="menuitem" className="show-noowners">
                  Show items without owners
                </a>
              </li>
            </ul>
          </h4>
        </div>

        <div className="card-list scrollbar"
          ref={PrLinkListRef}
          onScroll={(e) => localStorage.setItem('scrollTopLink', e.target.scrollTop)}
        >
          <div
            className="form-group notes"
            style={note.Links ? { display: "block" } : { display: "none" }}
          >
            <TextareaAutosize
              className="notes"
              name="Links"
              // defaultValue=''
              // value={noteSaveData.Links}
              //   onChange={(e) => handleSave(e)}
              onBlur={(e) => handleSave(e)}
              placeholder="Notes"
              // maxLength="100"
              defaultValue={noteSaveData.Links}
              minRows={3} // Set the minimum number of rows
            />
          </div>

          <h6 style={{ borderBottom: "1px solid #000" }}>Core members</h6>
          <div className="panel panel-default">
            <div
              onDrop={() => pFLonDrop("pFlCore")}
              onDragOver={(event) => pFLonDragOver(event)}
              id="core"
              className="panel-body box-container"
            >
              {/* Core members listed here*/}
              {PrcorfoLinks && PrcorfoLinks.length > 0
                ? PrcorfoLinks.map((e, index) => {
                  if (isTag || isEXT || isNO) {
                    let Proceed = TagWiseSorting(
                      e,
                      isTag,
                      isEXT,
                      isNO,
                      tagFilteredObj,
                      extNoFilteredObj,
                      "link"
                    );
                    if (!Proceed) return;
                  }
                  let isExternal = IsOwnerExternal(e, "Link");
                  let isPFProjects = isPFProjectsAddL(e._id, "core");
                  return (
                    <div
                      // onDragStart={(e) => linksDragStart(e, index)}
                      // onDragEnter={(e) => linksDragEnter(e, index)}
                      // onDragEnd={linksDrop}
                      onDrag={() => pFLonDrag(e, "pFlCore", isPFProjects)}
                      draggable
                      key={index}
                      className="card "
                      data-list_id="links"
                      data-card_id={e._id}
                      onClick={(event) => {
                        if (
                          event.target.classList.contains("card") ||
                          event.target.classList.contains("card-edit")
                        ) {
                          setLink(e, PrcorfoLinks, index);
                        }
                      }}
                    >
                      <div className="static-relations">
                        {isExternal ? (
                          <span
                            onClick={() => {
                              toggleEXTnNOCards(e._id, "EXT", false, "pill");
                            }}
                            className="custom-badge blue ext-owner-pill"
                          >
                            EXT
                          </span>
                        ) : null}
                        {e.owner &&
                          e.owner.length > 0 &&
                          OwnerExist([e.owner]) ? null : (
                          <span
                            onClick={() => {
                              toggleEXTnNOCards(e._id, "NO", false, "pill");
                            }}
                            className="custom-badge no-owner-pill red"
                          >
                            NO
                          </span>
                        )}
                        {e.tags && e.tags.length > 0
                          ? e.tags.map((r, index) => (
                            <span
                              onClick={() => toggleTagCards(r)}
                              key={index}
                              className="custom-badge green tags"
                            >
                              {r}
                            </span>
                          ))
                          : null}
                      </div>

                      <a
                        // onClick={() => setLink(e, PrcorfoLinks, index)}
                        className="card-edit"
                      >
                        {e.linkName}
                      </a>
                      <a
                        className="card-unlink"
                        style={{ display: "block", color: "red" }}
                      >
                        <i
                          onClick={() => pFlinkSub(e, "core")}
                          className="icon far fa-times-circle"
                        ></i>
                      </a>
                    </div>
                  );
                })
                : null}
            </div>
          </div>

          <h6 style={{ borderBottom: "1px solid #000" }}>Extended members</h6>
          <div className="panel panel-default">
            <div
              onDrop={() => pFLonDrop("pFlExtend")}
              onDragOver={(event) => pFLonDragOver(event)}
              id="extended"
              className="panel-body box-container"
            >
              {/* Extended members listed here*/}
              {PrextfoLinks && PrextfoLinks.length > 0
                ? PrextfoLinks.map((e, index) => {
                  if (isTag || isEXT || isNO) {
                    let Proceed = TagWiseSorting(
                      e,
                      isTag,
                      isEXT,
                      isNO,
                      tagFilteredObj,
                      extNoFilteredObj,
                      "link"
                    );
                    if (!Proceed) return;
                  }
                  let isExternal = IsOwnerExternal(e, "Link");
                  let isPFProjects = isPFProjectsAddL(e._id, "extended");
                  return (
                    <div
                      // onDragStart={(e) => linksDragStart(e, index)}
                      // onDragEnter={(e) => linksDragEnter(e, index)}
                      // onDragEnd={linksDrop}
                      onDrag={() => pFLonDrag(e, "pFlExtend", isPFProjects)}
                      draggable
                      key={index}
                      className="card "
                      data-list_id="links"
                      data-card_id={e._id}
                      onClick={(event) => {
                        if (
                          event.target.classList.contains("card") ||
                          event.target.classList.contains("card-edit")
                        ) {
                          setLink(e, PrextfoLinks, index);
                        }
                      }}
                    >
                      <div className="static-relations">
                        {isExternal ? (
                          <span
                            onClick={() => {
                              toggleEXTnNOCards(e._id, "EXT", false, "pill");
                            }}
                            className="custom-badge blue ext-owner-pill"
                          >
                            EXT
                          </span>
                        ) : null}
                        {e.owner &&
                          e.owner.length > 0 &&
                          OwnerExist([e.owner]) ? null : (
                          <span
                            onClick={() => {
                              toggleEXTnNOCards(e._id, "NO", false, "pill");
                            }}
                            className="custom-badge no-owner-pill red"
                          >
                            NO
                          </span>
                        )}
                        {e.tags && e.tags.length > 0
                          ? e.tags.map((r, index) => (
                            <span
                              onClick={() => toggleTagCards(r)}
                              key={index}
                              className="custom-badge green tags"
                            >
                              {r}
                            </span>
                          ))
                          : null}
                      </div>

                      <a
                        // onClick={() => setLink(e, PrextfoLinks, index)}
                        className="card-edit"
                      >
                        {e.linkName}
                      </a>
                      <a
                        className="card-unlink"
                        style={{ display: "block", color: "red" }}
                      >
                        <i
                          onClick={() => pFlinkSub(e, "extend")}
                          className="icon far fa-times-circle"
                        ></i>
                      </a>
                    </div>
                  );
                })
                : null}
            </div>
          </div>

          <span className="clearable2">
            <input
              type="text"
              ref={fsprLref}
              style={{ color: "black" }}
              value={pfLinksSearch}
              onChange={(e) => setPfLinksSearch(e.target.value)}
              className="form-control search_list2 transparent"
              name="search_list"
              placeholder="Search to select more"
              autoComplete="off"
            />
            {pfLinksSearch.length > 0 ? (
              <i
                className="clearable__clear"
                onClick={() => setPfLinksSearch("")}
                style={{ display: "inline", color: "black" }}
              >
                ×
              </i>
            ) : null}
          </span>

          <div className="panel panel-default">
            <div
              onDrop={() => pFLonDrop("pFlDefult")}
              onDragOver={(event) => pFLonDragOver(event)}
              id="notmatched"
              className="panel-body box-container"
            >
              {filteredPFLData && filteredPFLData.length > 0
                ? filteredPFLData.map((e, index) => {
                  if (isTag || isEXT || isNO) {
                    let Proceed = TagWiseSorting(
                      e,
                      isTag,
                      isEXT,
                      isNO,
                      tagFilteredObj,
                      extNoFilteredObj,
                      "link"
                    );
                    if (!Proceed) return;
                  }
                  let isExternal = IsOwnerExternal(e, "Link");
                  return (
                    <div
                      // onDragStart={(e) => linksDragStart(e, index)}
                      // onDragEnter={(e) => linksDragEnter(e, index)}
                      // onDragEnd={linksDrop}
                      onDrag={() => pFLonDrag(e, "pFlDefult")}
                      draggable
                      key={index}
                      className={`card notmatched ${localStorage.getItem("latest-created") === e._id
                        ? " latest-created "
                        : ""
                        }`}
                      data-list_id="links"
                      onClick={(event) => {
                        if (
                          event.target.classList.contains("card") ||
                          event.target.classList.contains("card-edit")
                        ) {
                          setLink(e, filteredPFLData, index);
                        }
                      }}
                    >
                      <div className="static-relations">
                        {isExternal ? (
                          <span
                            onClick={() => {
                              toggleEXTnNOCards(e._id, "EXT", false, "pill");
                            }}
                            className="custom-badge blue ext-owner-pill"
                          >
                            EXT
                          </span>
                        ) : null}
                        {e.owner &&
                          e.owner.length > 0 &&
                          OwnerExist([e.owner]) ? null : (
                          <span
                            onClick={() => {
                              toggleEXTnNOCards(e._id, "NO", false, "pill");
                            }}
                            className="custom-badge no-owner-pill red"
                          >
                            NO
                          </span>
                        )}
                        {e.tags && e.tags.length > 0
                          ? e.tags.map((r, index) => (
                            <span
                              onClick={() => toggleTagCards(r)}
                              key={index}
                              className="custom-badge green tags"
                            >
                              {r}
                            </span>
                          ))
                          : null}
                      </div>

                      <a
                        // onClick={() => setLink(e, filteredPFLData, index)}
                        className="card-edit"
                      >
                        {e.linkName}
                      </a>

                      <OverlayTrigger
                        trigger="click"
                        placement={
                          list[list.length - 1] === "Links" ? "left" : "right"
                        }
                        overlay={popover}
                      >
                        <a
                          className="card-link"
                          variant="success"
                          style={{ display: "block" }}
                        >
                          <i
                            onClick={() => cFPeopleHandel(e, "Links")}
                            className="icon fa fa-plus-circle"
                          ></i>
                        </a>
                      </OverlayTrigger>
                    </div>
                  );
                })
                : null}
            </div>
          </div>
          <div
            className="card2"
            style={
              lFromState.Links ? { display: "block" } : { display: "none" }
            }
          >
            <form className="form-new-card" role="form" onSubmit={linkSubmit}>
              <div className="form-group">
                <input
                  type="text"
                  ref={lref}
                  name="card_name"
                  required
                  placeholder={`Add ${GetAliasesName(teams, "Links").toLowerCase()} name`}
                  className="form-control"
                  value={link}
                  onChange={handleLinkChange}
                  autoComplete="off"
                />
              </div>
              <div className="form-group" style={{ marginTop: "10px" }}>
                <button type="submit" className="btn btn-mytpt add-quick">
                  Save
                </button>
                <a
                  style={{cursor: "pointer"}}
                  type="button"
                  onClick={() => {
                    setLinks({ linkName: link });
                    setLinkName("");
                    lFromState.Links = false;
                  }}
                  className="btn btn-link underline open-tool"
                >
                  Edit details
                </a>
                <a
                  style={{cursor: "pointer"}}
                  onClick={() => linksFrom()}
                  className="btn btn-link underline close-add-card"
                >
                  Close
                </a>
              </div>
            </form>
          </div>
        </div>
        <a
          className="btn-list add-card"
          onClick={() => linksFrom()}
          data-list_id="links"
          style={!lFromState.Links ? { display: "block" } : { display: "none" }}
        >
          + Add new {/*link */} {GetAliasesName(teams, "Links").toLowerCase()}
        </a>
      </div>
    </div>
  )
}
