import React, { useEffect, useState } from 'react'
import Tippy from "@tippyjs/react";
import './HomePage.css'
import "tippy.js/dist/tippy.css"; // optional

const panelStyles = {
  heading: {
    fontFamily: "Roboto, sans-serif",
    fontSize: "18px",
    fontWeight: 800,
    lineHeight: "21.09px",
    textAlign: "left",
  },
};

export default function HomePage({ removeFocused, setFocusedMode, showComaprePopup, setCompareMode, showAnalytics, setAnalyticsComponent, removeTags, showPurposeTree, setPurposeTreeMode, setShowHomePage, setWorkflowComponent, openAccessManagement, openTeam, openEditTemplate, showWorkflow, showCampfire, curUserData }) {

  // console.log({ removeFocused, setFocusedMode, setCompareMode, setAnalyticsComponent, removeTags, setPurposeTreeMode, setShowHomePage, curUserData });
  const totalItems = 7;
  const [isCollapsed, setIsCollapsed] = useState(
    Object.fromEntries(Array.from({ length: totalItems }, (_, i) => [i + 1, false]))
  );

  const handleToggle = (index) => {
    if (index === 0) {
      // Expand all
      setIsCollapsed(Object.fromEntries(Array.from({ length: totalItems }, (_, i) => [i + 1, false])));
    } else if (index === -1) {
      // Collapse all
      setIsCollapsed(Object.fromEntries(Array.from({ length: totalItems }, (_, i) => [i + 1, true])));
    } else {
      // Toggle only the selected index
      setIsCollapsed((prevState) => ({
        ...prevState,
        [index]: !prevState[index],
      }));
    }
  };

  useEffect(() => {
    document.body.classList.remove("campfire-page");
    document.body.classList.remove("workflow-page");
    document.body.classList.remove("survey-page");
    document.body.classList.remove("purpose-tree");
    document.body.classList.remove("compare-mode");
    document.body.classList.add("home-page");
  });

  return (
    <div className="home-page container-fluid">
      <div className="row home-header">
        <div className="col-md-12">
          <div className="tracking-main">
            <div className={`tracking-part collapsed`}>
              <h2>🧰 Toolbox <Tippy content={<span>{Object.values(isCollapsed).every(i => i) ? "Expand All" : "Collapse All"}</span>} placement="right" theme="custom">
                <div className="icon-wrapper" data-expanded="false"
                  onClick={() => handleToggle(Object.values(isCollapsed).every(i => i) ? 0 : -1)}
                >
                  <img src={Object.values(isCollapsed).every(i => i) ? "/images/expand.svg" : "/images/collapse.svg"} alt="toggle icon" />
                </div>
              </Tippy></h2>
              <p>Welcome to teamdecoder! Discover a powerful set of professional tools crafted for team architects like you to help teams thrive. Need to return to this page? Just click on the toolbox icon anytime!</p>
            </div>
          </div>
        </div>
      </div>

      <div className="row home-body">
        <div className="col-md-12">
          <div className="panel-group" id="accordion">
            <div className="panel panel-default">
              <div className="panel-heading">
                <h4 className="panel-title" style={panelStyles.heading}>
                  <a /* data-toggle="collapse" href="#collapse1" */ className={`${isCollapsed[1] ? "collapsed" : ""}`} onClick={() => handleToggle(1)}>
                    <i className={`chevron-icon fa fa-chevron-${isCollapsed[1] ? "right" : "down"}`}></i> 01 Dashboard <small>Create, assign and manage roles in the teamdecoder framework</small></a>
                </h4>
              </div>
              <div id="collapse1" className={`panel-collapse collapse ${!isCollapsed[1] ? "in" : ""}`}>
                <div className="panel-body">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Feature</th>
                          <th>Description</th>
                          <th>Info</th>
                          <th>Go to</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="td-feature">Lists</td>
                          <td className="td-description">The framework has 7 lists you can adjust to suit your needs.</td>
                          <td className="td-info"><img src="/images/icon-info.png" onClick={() => window.open("https://teamdecoder.tawk.help/article/what-do-the-7-lists-stand-for", "_blank")} /></td>
                          <td><a href="#" onClick={() => setShowHomePage(false)}>Dashboard <img src="/images/icon-external-link.png" /></a> <a href="#" onClick={() => window.open("https://teamdecoder.tawk.help/article/personalization", "_blank")}>Personalization <img src="/images/icon-external-link.png" /></a></td>
                        </tr>
                        <tr>
                          <td className="td-feature">Focus Mode</td>
                          <td className="td-description">Put a person or a group in focus to assign roles</td>
                          <td className="td-info"><img src="/images/icon-info.png" onClick={() => window.open("https://teamdecoder.tawk.help/article/what-is-focus-mode", "_blank")} /></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td className="td-feature">Groups</td>
                          <td className="td-description">Green lists are groups—check them out in the overview modes</td>
                          <td className="td-info"><img src="/images/icon-info.png" onClick={() => window.open("https://teamdecoder.tawk.help/article/circles-projects", "_blank")} /></td>
                          <td><a href="#"
                            onClick={() => {
                              removeFocused(); // Exit focus mode
                              setCompareMode({
                                active: false,
                                startComparing: false,
                                compareTypes: [],
                                compareBetween: [],
                              });
                              setAnalyticsComponent(false);
                              removeTags(); // Remove tags
                              setPurposeTreeMode(false);
                              setShowHomePage(false);
                              setFocusedMode("Circle");
                              setWorkflowComponent(false);
                            }}
                          >Circles <img src="/images/icon-external-link.png" /></a> <a href="#"
                            onClick={() => {
                              removeFocused(); // Exit focus mode
                              setCompareMode({
                                active: false,
                                startComparing: false,
                                compareTypes: [],
                                compareBetween: [],
                              });
                              setAnalyticsComponent(false);
                              removeTags(); // Remove tags
                              setPurposeTreeMode(false);
                              setShowHomePage(false);
                              setFocusedMode("Project");
                              setWorkflowComponent(false);
                            }}
                          >Projects <img src="/images/icon-external-link.png" /></a></td>
                        </tr>
                        <tr>
                          <td className="td-feature">Meetings</td>
                          <td className="td-description">Gain an overview of all groups' meetings</td>
                          <td className="td-info"><img src="/images/icon-info.png" onClick={() => window.open("https://teamdecoder.tawk.help/article/meetings", "_blank")} /></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td className="td-feature">Compare</td>
                          <td className="td-description">Put people or roles next to each other and drag and drop work</td>
                          <td className="td-info"><img src="/images/icon-info.png" onClick={() => window.open("https://teamdecoder.tawk.help/article/compare-mode", "_blank")} /></td>
                          <td><a href="#"
                            onClick={() => {
                              showComaprePopup();
                            }}
                          >Compare mode <img src="/images/icon-external-link.png" /></a></td>
                        </tr>
                        <tr>
                          <td className="td-feature">Teams</td>
                          <td className="td-description">Create and manage more than one team</td>
                          <td className="td-info"><img src="/images/icon-info.png" onClick={() => window.open("https://teamdecoder.tawk.help/article/teams", "_blank")} /></td>
                          <td>{curUserData && curUserData.role !== "Co-Admin" && <a href="#" onClick={openTeam}>Create / Edit teams <img src="/images/icon-external-link.png" /></a>}</td>
                        </tr>
                        <tr>
                          <td className="td-feature">Templates</td>
                          <td className="td-description">Don't start from scratch—find some inspiration!</td>
                          <td className="td-info"><img src="/images/icon-info.png" onClick={() => window.open("https://teamdecoder.tawk.help/article/working-with-templates", "_blank")} /></td>
                          <td>{curUserData && curUserData.role !== "Co-Admin" && <a href="#" onClick={openEditTemplate}>Download templates <img src="/images/icon-external-link.png" /></a>}</td>
                        </tr>
                        <tr>
                          <td className="td-feature">Tags & Pills</td>
                          <td className="td-description">Order your entries by using filters</td>
                          <td className="td-info"><img src="/images/icon-info.png" onClick={() => window.open("https://teamdecoder.tawk.help/article/tags", "_blank")} /></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td className="td-feature">Fading roles</td>
                          <td className="td-description">Mark assigned roles as fading-on or fading-out</td>
                          <td className="td-info"><img src="/images/icon-info.png" onClick={() => window.open("https://teamdecoder.tawk.help/article/fading-in-out", "_blank")} /></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td className="td-feature">Team Transfer</td>
                          <td className="td-description">Send the team-board to your client</td>
                          <td className="td-info"><img src="/images/icon-info.png" onClick={() => window.open("https://teamdecoder.tawk.help/article/team-transfers", "_blank")} /></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td className="td-feature">Co-Admins</td>
                          <td className="td-description">Invite other people to manage the teams with you</td>
                          <td className="td-info"><img src="/images/icon-info.png" onClick={() => window.open("https://teamdecoder.tawk.help/article/admins-co-admins", "_blank")} /></td>
                          <td>{curUserData && curUserData.role !== "Co-Admin" && <a href="#" onClick={openAccessManagement}>Access Management <img src="/images/icon-external-link.png" /></a>}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="action-buttons">
                    <button type="button" className="primary"
                      onClick={() => {
                        removeFocused(); // Exit focus mode
                        setCompareMode({
                          active: false,
                          startComparing: false,
                          compareTypes: [],
                          compareBetween: [],
                        });
                        setAnalyticsComponent(false);
                        removeTags(); // Remove tags
                        setPurposeTreeMode(false);
                        setShowHomePage(false);
                        setFocusedMode("");
                        setWorkflowComponent(false);
                      }}
                    >Go to dashboard</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="panel panel-default">
              <div className="panel-heading">
                <h4 className="panel-title" style={panelStyles.heading}>
                  <a /* data-toggle="collapse" href="#collapse2" */ className={`${isCollapsed[2] ? "collapsed" : ""}`} onClick={() => handleToggle(2)}>
                    <i className={`chevron-icon fa fa-chevron-${isCollapsed[2] ? "right" : "down"}`}></i> 02 Workload Planning<small>Plan roles with FTE and manage utilization and prioritization</small></a>
                </h4>
              </div>
              <div id="collapse2" className={`panel-collapse collapse ${!isCollapsed[2] ? "in" : ""}`}>
                <div className="panel-body">
                  <div className="action-buttons">
                    <button type="button" className="secondary" onClick={() => window.open("https://teamdecoder.tawk.help/article/workloadplanning", "_blank")}>More Info</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="panel panel-default">
              <div className="panel-heading">
                <h4 className="panel-title" style={panelStyles.heading}>
                  <a /* data-toggle="collapse" href="#collapse3" */ className={`${isCollapsed[3] ? "collapsed" : ""}`} onClick={() => handleToggle(3)}>
                    <i className={`chevron-icon fa fa-chevron-${isCollapsed[3] ? "right" : "down"}`}></i> 03 Workflows<small>Arrange roles in the optimal order and design ideal workflow scenarios.</small></a>
                </h4>
              </div>
              <div id="collapse3" className={`panel-collapse collapse ${!isCollapsed[3] ? "in" : ""}`}>
                <div className="panel-body">
                  <div className="action-buttons">
                    <button type="button" className="primary"
                      onClick={() => {
                        showWorkflow();
                      }}
                    >Go to workflows</button>
                    <button type="button" className="secondary" onClick={() => window.open("https://teamdecoder.tawk.help/article/workflows", "_blank")}>How to</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="panel panel-default">
              <div className="panel-heading">
                <h4 className="panel-title" style={panelStyles.heading}>
                  <a /* data-toggle="collapse" href="#collapse4" */ className={`${isCollapsed[4] ? "collapsed" : ""}`} onClick={() => handleToggle(4)}>
                    <i className={`chevron-icon fa fa-chevron-${isCollapsed[4] ? "right" : "down"}`}></i> 04 Purpose Tree<small>Align company purpose with purposes of each group and role</small></a>
                </h4>
              </div>
              <div id="collapse4" className={`panel-collapse collapse ${!isCollapsed[4] ? "in" : ""}`}>
                <div className="panel-body">
                  <div className="action-buttons">
                    <button type="button" className="primary"
                      onClick={() => {
                        showPurposeTree();
                      }}
                    >Go to purpose tree</button>
                    <button type="button" className="secondary" onClick={() => window.open("https://teamdecoder.tawk.help/article/purpose-tree", "_blank")}>How to</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="panel panel-default">
              <div className="panel-heading">
                <h4 className="panel-title" style={panelStyles.heading}>
                  <a /* data-toggle="collapse" href="#collapse5" */ className={`${isCollapsed[5] ? "collapsed" : ""}`} onClick={() => handleToggle(5)}>
                    <i className={`chevron-icon fa fa-chevron-${isCollapsed[5] ? "right" : "down"}`}></i> 05 Reports<small>Invite all team members to experience clarity (What are my roles?) and transparency (Who does what?)</small></a>
                </h4>
              </div>
              <div id="collapse5" className={`panel-collapse collapse ${!isCollapsed[5] ? "in" : ""}`}>
                <div className="panel-body">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Feature</th>
                          <th>Description</th>
                          <th>Info</th>
                          <th>Go to</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="td-feature">Link</td>
                          <td className="td-description">Share this link so team members can request their personal report.</td>
                          <td className="td-info"><img src="/images/icon-info.png" onClick={() => window.open("https://teamdecoder.tawk.help/article/reports", "_blank")} /></td>
                          <td><a href="#" onClick={() => window.open("https://app.teamdecoder.com/request-report", "_blank")}>Request Report Page <img src="/images/icon-external-link.png" /></a></td>
                        </tr>
                        <tr>
                          <td className="td-feature">Admin Access</td>
                          <td className="td-description">Admins can access all reports from the dashboard.</td>
                          <td className="td-info"><img src="/images/icon-info.png" onClick={() => window.open("https://teamdecoder.tawk.help/article/reports", "_blank")} /></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td className="td-feature">Feedback</td>
                          <td className="td-description">Team members can leave feedback to all items inside their report.</td>
                          <td className="td-info"><img src="/images/icon-info.png" onClick={() => window.open("https://teamdecoder.tawk.help/article/feedback", "_blank")} /></td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="action-buttons">
                    <button type="button" className="primary" onClick={() => window.open("https://app.teamdecoder.com/request-report", "_blank")}>Share this link</button>
                    <button type="button" className="secondary" onClick={() => window.open("https://teamdecoder.tawk.help/article/reports", "_blank")}>How to</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="panel panel-default">
              <div className="panel-heading">
                <h4 className="panel-title" style={panelStyles.heading}>
                  <a /* data-toggle="collapse" href="#collapse6" */ className={`${isCollapsed[6] ? "collapsed" : ""}`} onClick={() => handleToggle(6)}>
                    <i className={`chevron-icon fa fa-chevron-${isCollapsed[6] ? "right" : "down"}`}></i> 06 Surveys & Scores<small>Working with teamdecoder improves a team’s wellbeing, resilience and performance. We backed this on an entire set of research. Ask your team to check how well it works!</small></a>
                </h4>
              </div>
              <div id="collapse6" className={`panel-collapse collapse ${!isCollapsed[6] ? "in" : ""}`}>
                <div className="panel-body">
                  <div className="action-buttons">
                    <button type="button" className="primary"
                      onClick={() => {
                        showAnalytics();
                      }}
                    >Go to surveys</button>
                    <button type="button" className="secondary" onClick={() => window.open("https://teamdecoder.tawk.help/article/surveys", "_blank")}>How to</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="panel panel-default">
              <div className="panel-heading">
                <h4 className="panel-title" style={panelStyles.heading}>
                  <a /* data-toggle="collapse" href="#collapse7" */ className={`${isCollapsed[7] ? "collapsed" : ""}`} onClick={() => handleToggle(7)}>
                    <i className={`chevron-icon fa fa-chevron-${isCollapsed[7] ? "right" : "down"}`}></i> 07 Campfire<small>We live in a world of constant change - teamdecoder supports teams in integrating change into their daily work, Campfire is a regular guided process to assess and think forward.</small></a>
                </h4>
              </div>
              <div id="collapse7" className={`panel-collapse collapse ${!isCollapsed[7] ? "in" : ""}`}>
                <div className="panel-body">
                  <div className="action-buttons">
                    <button type="button" className="primary"
                      onClick={() => {
                        showCampfire();
                      }}
                    >Go to campfire</button>
                    <button type="button" className="secondary" onClick={() => window.open("https://teamdecoder.tawk.help/article/campfire-meetings", "_blank")}>Go to agenda</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row home-footer">
        <div className="col-md-12">
          <div className="tracking-main">
            <div className="tracking-part">
              <h2><img src="/images/icon-restart.png" /> Restart</h2>
              <p>Use the learnings from surveys and campfire to constantly improve roles, groups, workloads and purposes in the dashboard.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
