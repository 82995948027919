import axios from 'axios'
import {
    CIRCLE_CREATE_SUCCESS, CIRCLE_CREATE_FAIL, CIRCLE_GET_SUCCESS, CIRCLE_DELETE_SUCCESS,
    CIRCLE_UPDATE_SUCCESS, CIRCLE_UPDATE_FAIL, CIRCLE_DELETE_FAIL, CIRCLE_GET_FAIL,
    CIRCLE_DROP_UPDATE_SUCCESS, CIRCLE_DROP_UPDATE_FAIL
} from '../types/circleType'

import {
    MEMBER_GET_SUCCESS
} from '../types/memberType'

import { SERVER_URI } from '../../config/keys'

import { isCardExist } from '../../HelperFunctions/CardExist'
import { DispatchBlocker } from '../DispatchBlocker/DispatchBlocker'

// const token = localStorage.getItem("authToken");

export const userAuth = (token) => {
    axios.interceptors.request.use(
        config => {
            config.headers.authorization = `Bearer ${token}`;
            return config;
        },
        error => {
            return Promise.reject(error)
        }
    )
}

export const createCircle = (datas) => {
    return async (dispatch, getState) => {
        let proceed = DispatchBlocker(getState);
        if (!proceed) return;
        // Accessing the current state
        const currentState = getState();
        if (isCardExist(currentState.circles.circles, datas.circleName, 'Circle')) {
            dispatch({
                type: CIRCLE_CREATE_FAIL,
                payload: {
                    circleError: `Circle with this name already exist!`
                }
            })
            return;
        }

        // const url = 'http://localhost:8080'
        let token = localStorage.getItem('authToken')
        try {
            userAuth(token)
            // console.log(datas)
            const response = await axios.post(`${SERVER_URI}/user/circle/create`, datas)
            // console.log(response.data.members)

            dispatch({
                type: CIRCLE_CREATE_SUCCESS,
                payload: {
                    circleSuccessMessage: response.data.message,
                    cuCircleId: response.data.data._id,
                    circles: response.data.circles,
                }
            })

            dispatch({
                type: MEMBER_GET_SUCCESS,
                payload: {
                    members: response.data.members
                }
            })
        } catch (error) {

            let data = error.response.data.message
            dispatch({
                type: CIRCLE_CREATE_FAIL,
                payload: {
                    circleError: data
                }
            })
        }
    }
}

export const getCircle = (datas) => {
    return async (dispatch) => {
        // const url = 'http://localhost:8080'
        const config = {
            headers: {
                'Content-Type': 'application/josn',
                'adminId': datas.userId,
                'teamId': datas.teamId,
                'curcircle': datas.curcircle,
                'circlesData': datas.circlesData,
                'url': `${SERVER_URI}/user/circle/get`
            }
        }
        // console.log(config)
        try {
            const token = localStorage.getItem("authToken");
            // console.log(token)
            userAuth(token)
            const response = await axios.get(`${SERVER_URI}/user/circle/get`, config)
            // console.log(response.data)
            // localStorage.setItem('authToken', response.data.data.token)

            dispatch({
                type: CIRCLE_GET_SUCCESS,
                payload: {
                    circleSuccessMessage: response.data.message,
                    circles: response.data.data,
                    dCircles: response.data.fData
                }
            })

        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.message) {
                let data = error.response.data.message
                dispatch({
                    type: CIRCLE_GET_FAIL,
                    payload: {
                        circleError: data
                    }
                })
            } else if (error.message) {
                dispatch({
                    type: CIRCLE_GET_FAIL,
                    payload: {
                        circleError: error.message
                    }
                })
            }
        }
    }
}

export const updateCircle = (datas) => {
    return async (dispatch, getState) => {
        let proceed = DispatchBlocker(getState);
        if (!proceed) return;
        // Accessing the current state
        const currentState = getState();
        if (isCardExist(currentState.circles.circles, datas.circleName, 'Circle', datas._id)) {
            dispatch({
                type: CIRCLE_CREATE_FAIL,
                payload: {
                    circleError: `Circle with this name already exist!`
                }
            })
            return;
        }

        // const url = 'http://localhost:8080'
        let token = localStorage.getItem('authToken')
        try {
            // console.log(datas)
            userAuth(token)
            const response = await axios.put(`${SERVER_URI}/user/circle/update`, datas)

            dispatch({
                type: CIRCLE_UPDATE_SUCCESS,
                payload: {
                    circles: response.data.circles,
                    circleSuccessMessage: response.data.message,
                }
            })

        } catch (error) {
            // let data = error.response.data.message
            // console.log(data)
            dispatch({
                type: CIRCLE_UPDATE_FAIL,
                payload: {
                    circleError: error
                }
            })

        }
    }
}

export const dropUpdateCircle = (datas) => {
    return async (dispatch, getState) => {
        let proceed = DispatchBlocker(getState);
        if (!proceed) return;

        let token = localStorage.getItem('authToken')
        try {
            // console.log(datas)
            userAuth(token)
            const response = await axios.put(`${SERVER_URI}/user/circle/update`, datas)

            dispatch({
                type: CIRCLE_DROP_UPDATE_SUCCESS,
                payload: {
                    circleSuccessMessage: response.data.message,
                    circles: response.data.circles,
                }
            })

        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.message) {
                let data = error.response.data.message
                dispatch({
                    type: CIRCLE_DROP_UPDATE_FAIL,
                    payload: {
                        circleError: data
                    }
                })
            } else if (error.message) {
                dispatch({
                    type: CIRCLE_DROP_UPDATE_FAIL,
                    payload: {
                        circleError: error.message
                    }
                })
            }
        }
    }
}

export const deleteCircle = (datas) => {
    return async (dispatch, getState) => {
        let proceed = DispatchBlocker(getState);
        if (!proceed) return;
        // const url = 'http://localhost:8080'
        let token = localStorage.getItem('authToken')
        let circleSort = localStorage.getItem('circleSort')
        const config = {
            headers: {
                'Content-Type': 'application/josn',
                'adminId': datas.adminId,
                'teamId': datas.teamId,
                'url': `${SERVER_URI}/user/circle/delete`,
                'circleId': datas.circleId,
                'circleSort': circleSort,
                'softDelete': datas.softDelete
            }
        }
        try {
            // console.log(datas)
            userAuth(token)
            const response = await axios.delete(`${SERVER_URI}/user/circle/delete`, config)

            dispatch({
                type: CIRCLE_DELETE_SUCCESS,
                payload: {
                    circleSuccessMessage: response.data.message,
                    circles: response.data.circles,
                }
            })

        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.message) {
                let data = error.response.data.message
                dispatch({
                    type: CIRCLE_DELETE_FAIL,
                    payload: {
                        circleError: data
                    }
                })
            } else if (error.message) {
                dispatch({
                    type: CIRCLE_DELETE_FAIL,
                    payload: {
                        circleError: error.message
                    }
                })
            }
        }
    }
}