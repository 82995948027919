import React, { useEffect, useRef } from 'react'
import TextareaAutosize from "react-textarea-autosize";
import useRestoreScroll from '../../../HelperFunctions/useRestoreScroll';
export default function FCProjectsList({ tagFilteredMegedData, handleAliesChange, GetAliasesName, teams, CcorfoProjects, getSortIcon, isInfos, setProjects, pCardHideOnchange, note, handleSave, noteSaveData, cFPronDrop, cFPronDragOver, isTag, isEXT, isNO, TagWiseSorting, tagFilteredObj, extNoFilteredObj, cFPronDrag, ScrollIntoView, setProject, toggleTagCards, PfToCAndP, cFprojectSub, fcPrref, cfProjectsSearch, setCfProjectsSearch, filteredCFPrData, IsAlreadyAssociated, cFPeopleHandel, cFPeopleIdHandel, lFromState, projectSubmit, prref, project, handleProjectChange, setProjectName, projectsFrom }) {

    const FCProjectsListRef = useRef(null);
    useRestoreScroll(FCProjectsListRef, "Project", filteredCFPrData);

    return (
        <div
            className={
                Object.keys(tagFilteredMegedData).length > 0 &&
                    tagFilteredMegedData.project &&
                    tagFilteredMegedData.project.length === 0
                    ? "board-col hide"
                    : "board-col"
            }
            data-board_id="projects"
        >
            <div className="list list-bg-red" data-list_id="projects">
                <div className="dropdown">
                    <h4 className="list-title">
                        <img alt="" src="images/icon-project.png" /> {/* {GetAliasesName(teams, "Projects")} (
            <span>{}</span>){" "} */}
                        <span contentEditable={true}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault(); // Prevent new line
                                }
                            }} data-name="projects" onBlur={(e) => handleAliesChange(e)} suppressContentEditableWarning={true}>{GetAliasesName(teams, "Projects")}</span>
                        <span>{` (${CcorfoProjects.length})`}</span>{" "}
                        <a className="list-menu refresh-list hide" style={{cursor: "pointer"}}>
                            <i className="fas fa-sync-alt"></i>
                        </a>{" "}
                        <a
                            className="list-menu dropdown-toggle"
                            data-toggle="dropdown"
                            style={{cursor: "pointer"}}
                        >
                            {getSortIcon("Projects")}
                            <i className="fas fa-ellipsis-v"></i>
                        </a>
                        <ul className="dropdown-menu dropdown-menu-right" role="menu">
                            <li role="presentation">
                                <a
                                    onClick={() => isInfos("Projects")}
                                    role="menuitem"
                                    className="mytpt-list-info"
                                >
                                    Info
                                </a>
                            </li>
                            <li role="presentation">
                                <a
                                    role="menuitem"
                                    data-toggle="modal"
                                    data-target="#projectsModal"
                                    onClick={() => setProjects()}
                                >
                                    Add new {GetAliasesName(teams, "Projects").toLowerCase()}
                                </a>
                            </li>
                            <li role="presentation">
                                <a
                                    role="menuitem"
                                    onClick={() => pCardHideOnchange("Projects")}
                                    className="hide_list"
                                >
                                    Hide list
                                </a>
                            </li>
                            <li role="presentation" className="hide">
                                <a role="menuitem" className="show-external">
                                    Show external people
                                </a>
                            </li>
                            <li role="presentation" className="hide">
                                <a role="menuitem" className="show-noowners">
                                    Show items without owners
                                </a>
                            </li>
                        </ul>
                    </h4>
                </div>

                <div className="card-list scrollbar"
                    ref={FCProjectsListRef}
                    onScroll={(e) => localStorage.setItem('scrollTopProject', e.target.scrollTop)}>
                    <div
                        className="form-group notes"
                        style={note.Projects ? { display: "block" } : { display: "none" }}
                    >
                        <TextareaAutosize
                            className="notes"
                            name="Projects"
                            // defaultValue=''
                            // value={noteSaveData.Projects}
                            //   onChange={(e) => handleSave(e)}
                            onBlur={(e) => handleSave(e)}
                            placeholder="Notes"
                            // maxLength="100"
                            defaultValue={noteSaveData.Projects}
                            minRows={3} // Set the minimum number of rows
                        />
                    </div>
                    {/* <h6 style={{"borderBottom": "1px solid #000"}}>Core members</h6> */}
                    <div className="panel panel-default">
                        <div
                            onDrop={() => cFPronDrop("cFPrCore")}
                            onDragOver={(event) => cFPronDragOver(event)}
                            id="core"
                            className="panel-body box-container"
                        >
                            {/* Core members listed here*/}
                            {CcorfoProjects && CcorfoProjects.length > 0
                                ? CcorfoProjects.map((e, index) => {
                                    if (isTag || isEXT || isNO) {
                                        let Proceed = TagWiseSorting(
                                            e,
                                            isTag,
                                            isEXT,
                                            isNO,
                                            tagFilteredObj,
                                            extNoFilteredObj,
                                            "project"
                                        );
                                        if (!Proceed) return;
                                    }
                                    return (
                                        <div
                                            // onDragStart={(e) => projectsDragStart(e, index)}
                                            // onDragEnter={(e) => projectsDragEnter(e, index)}
                                            // onDragEnd={projectsDrop}
                                            onDrag={() => cFPronDrag(e, "cFPrCore")}
                                            draggable
                                            key={index}
                                            className="card "
                                            data-list_id="projects"
                                            style={{ minHeight: "60px" }}
                                            onClick={(event) => {
                                                ScrollIntoView();
                                                if (
                                                    event.target.classList.contains("card") ||
                                                    event.target.classList.contains("card-edit")
                                                ) {
                                                    setProject(e, CcorfoProjects, index);
                                                }
                                            }}
                                        >
                                            <div className="static-relations">
                                                {e.tags && e.tags.length > 0
                                                    ? e.tags.map((r, index) => (
                                                        <span
                                                            onClick={() => toggleTagCards(r)}
                                                            key={index}
                                                            className="custom-badge green tags"
                                                        >
                                                            {r}
                                                        </span>
                                                    ))
                                                    : null}
                                            </div>
                                            <a
                                                // onClick={() => setProject(e, CcorfoProjects, index)}
                                                className="card-edit"
                                            >
                                                {e.projectName}
                                            </a>
                                            <a className="card-focus">
                                                <img
                                                    onClick={() => PfToCAndP("Projects", e)}
                                                    src="./images/newicons/focus.svg"
                                                />
                                            </a>
                                            <a
                                                className="card-unlink"
                                                style={{ display: "block", color: "red" }}
                                            >
                                                <i
                                                    onClick={() => cFprojectSub(e, "core")}
                                                    className="icon far fa-times-circle"
                                                ></i>
                                            </a>
                                        </div>
                                    );
                                })
                                : null}
                        </div>
                    </div>

                    <span className="clearable2">
                        <input
                            type="text"
                            ref={fcPrref}
                            style={{ color: "black" }}
                            value={cfProjectsSearch}
                            onChange={(e) => setCfProjectsSearch(e.target.value)}
                            className="form-control search_list2 transparent"
                            name="search_list"
                            placeholder="Search to select more"
                            autoComplete="off"
                        />
                        {cfProjectsSearch.length > 0 ? (
                            <i
                                className="clearable__clear"
                                onClick={() => setCfProjectsSearch("")}
                                style={{ display: "inline", color: "black" }}
                            >
                                ×
                            </i>
                        ) : null}
                    </span>

                    <div className="panel panel-default">
                        <div
                            onDrop={() => cFPronDrop("cFPrDefult")}
                            onDragOver={(event) => cFPronDragOver(event)}
                            id="notmatched"
                            className="panel-body box-container"
                        >
                            {filteredCFPrData && filteredCFPrData.length > 0
                                ? filteredCFPrData.map((e, index) => {
                                    if (isTag || isEXT || isNO) {
                                        let Proceed = TagWiseSorting(
                                            e,
                                            isTag,
                                            isEXT,
                                            isNO,
                                            tagFilteredObj,
                                            extNoFilteredObj,
                                            "project"
                                        );
                                        if (!Proceed) return;
                                    }
                                    let isAlreadyAssociated = IsAlreadyAssociated(e);
                                    return (
                                        <div
                                            // onDragStart={(e) => projectsDragStart(e, index)}
                                            // onDragEnter={(e) => projectsDragEnter(e, index)}
                                            // onDragEnd={projectsDrop}
                                            onDrag={() => cFPronDrag(e, "cFPrDefult")}
                                            draggable={!isAlreadyAssociated}
                                            key={index}
                                            className={`card notmatched ${localStorage.getItem("latest-created") === e._id
                                                ? " latest-created "
                                                : ""
                                                }`}
                                            data-list_id="projects"
                                            onClick={(event) => {
                                                if (
                                                    event.target.classList.contains("card") ||
                                                    event.target.classList.contains("card-edit")
                                                ) {
                                                    setProject(e, filteredCFPrData, index);
                                                }
                                            }}
                                        >
                                            <div className="static-relations">
                                                {e.tags && e.tags.length > 0
                                                    ? e.tags.map((r, index) => (
                                                        <span
                                                            onClick={() => toggleTagCards(r)}
                                                            key={index}
                                                            className="custom-badge green tags"
                                                        >
                                                            {r}
                                                        </span>
                                                    ))
                                                    : null}
                                            </div>
                                            <a
                                                // onClick={() => setProject(e, filteredCFPrData, index)}
                                                className="card-edit"
                                            >
                                                {e.projectName}
                                            </a>
                                            {!isAlreadyAssociated ? (
                                                <a
                                                    className="card-link"
                                                    variant="success"
                                                    style={{ display: "block" }}
                                                >
                                                    <i
                                                        onClick={() => {
                                                            cFPeopleHandel(e, "Projects");
                                                            cFPeopleIdHandel("Core");
                                                        }}
                                                        className="icon fa fa-plus-circle"
                                                    ></i>
                                                </a>
                                            ) : null}
                                        </div>
                                    );
                                })
                                : null}
                        </div>
                    </div>

                    <div
                        className="card2"
                        style={
                            lFromState.Projects ? { display: "block" } : { display: "none" }
                        }
                    >
                        <form
                            className="form-new-card"
                            role="form"
                            onSubmit={projectSubmit}
                        >
                            <div className="form-group">
                                <input
                                    type="text"
                                    ref={prref}
                                    name="card_name"
                                    value={project}
                                    onChange={handleProjectChange}
                                    required
                                    placeholder={`+ Add ${GetAliasesName(teams, "Projects").toLowerCase()} name`}
                                    className="form-control"
                                    autoComplete="off"
                                />
                            </div>
                            <div className="form-group" style={{ marginTop: "10px" }}>
                                <button type="submit" className="btn btn-mytpt add-quick">
                                    Save
                                </button>
                                <a
                                    style={{cursor: "pointer"}}
                                    type="button"
                                    onClick={() => {
                                        setProjects({ projectName: project });
                                        setProjectName("");
                                        lFromState.Projects = false;
                                    }}
                                    className="btn btn-link underline open-tool"
                                >
                                    Edit details
                                </a>
                                <a
                                    style={{cursor: "pointer"}}
                                    onClick={() => projectsFrom()}
                                    className="btn btn-link underline close-add-card"
                                >
                                    Close
                                </a>
                            </div>
                        </form>
                    </div>
                </div>
                <a
                    className="btn-list add-card"
                    onClick={() => projectsFrom()}
                    data-list_id="projects"
                    style={
                        !lFromState.Projects ? { display: "block" } : { display: "none" }
                    }
                >
                    + Add new {/* project */} {GetAliasesName(teams, "Projects").toLowerCase()}
                </a>
            </div>
        </div>
    )
}
